import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow, Link } from 'react-md'

import { ZipCode } from 'components/address/components/zip-code'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { PartnerAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'
import { DataTable } from 'components/data-table'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSearch } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Abrangência'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pesquisa de Abrangência' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <PartnerAutoComplete name='partner' label='Parceiro *' desktopSize={6} tabletSize={8} />
                <ZipCode name='zipCode' label='Cep' maxLength={8} desktopSize={2} tabletSize={3} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => setFilter(form.getValues()))} />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <DataTable
          filter={filter}
          header={[
            { label: 'Cep Inicial' },
            { label: 'Cep Final' },
            { label: 'Cód. Ibge do Estado' },
            { label: 'Cód. Ibge da Cidade' },
            { label: 'Prazo' },
            { label: 'Tarifa' },
          ]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell>{data.zipCodeInitial}</TableCell>
                <TableCell>{data.zipCodeFinal}</TableCell>
                <TableCell>
                  <Link href={`https://www.ibge.gov.br/cidades-e-estados/${data.state}.html`} target='_blank' preventMaliciousTarget={false}>
                    {data.ibgeState}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link href={`https://www.ibge.gov.br/cidades-e-estados.html?c=${data.ibgeCity}`} target='_blank' preventMaliciousTarget={false}>
                    {data.ibgeCity}
                  </Link>
                </TableCell>
                <TableCell>{data.deadline}</TableCell>
                <TableCell>{data.fareCode}</TableCell>
              </TableRow>
            ))}
        </DataTable>
      </Cell>
    </Grid>
  )
}

export default Component
