import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/pickup-route'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  list: (): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}`)),
  autocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
}

export default Api
