import { toast } from 'react-toastify'

import { handleValidate } from 'components/form/form-submit'
import { ValidationMessage } from 'types/core/api'
import clientApi from 'services/sale/client'
import { FormList } from 'types/core/form'

export const handleValidateForms = async (
  issuerForm: FormList,
  addressForm: FormList,
  bankDataForm: FormList,
  takerForm: FormList,
  takerAddressForm: FormList,
  otherRulesForm: FormList,
): Promise<boolean> => {
  let success = true

  if (!(await handleValidate(issuerForm.form, issuerForm.validationSchema))) success = false
  if (!(await handleValidate(addressForm.form, addressForm.validationSchema))) success = false
  if (!(await handleValidate(bankDataForm.form, bankDataForm.validationSchema))) success = false
  if (!(await handleValidate(takerForm.form, takerForm.validationSchema))) success = false
  if (!(await handleValidate(takerAddressForm.form, takerAddressForm.validationSchema))) success = false
  if (!(await handleValidate(otherRulesForm.form, otherRulesForm.validationSchema))) success = false

  if (!success) toast.warn(ValidationMessage)

  return success
}

export const handleSave = async (values: any) => {
  let res = await clientApi.create(values)

  return res?.data
}
