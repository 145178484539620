import api from 'services/delivery/delivery-packing-list'

export const handleSearch = async (request: any) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/delivery/delivery-packing-list/${id}/edit`)
}

export const handleDelete = async (id: string) => {
  let res = await api.delete(id)

  return res?.data?.success
}
