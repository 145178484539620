import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/icms'
const Api = {
  get: (): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(url)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
}

export default Api
