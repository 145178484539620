import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from '@react-md/utils'
import { Card, CardActions, CardContent, FontIcon } from 'react-md'

import { handleSubmit, Input, SelectBoolean, Select, InputDecimal } from 'components/form'
import { InvoicingPeriodOptions } from 'types/sale/invoicing-period'
import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { CompanySelect } from 'components/select'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Novo Grupo Empresarial'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Grupo Empresarial' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <CompanySelect name='companyId' label='Empresa *' desktopSize={4} tabletSize={8} />
                <Input name='name' label='Nome *' desktopSize={4} tabletSize={4} />
                <SelectBoolean name='groupInvoicing' label='Agrupar Faturamento *' desktopSize={4} tabletSize={4} />
                <Select name='invoicingPeriod' label='Ciclo de Faturamento *' options={InvoicingPeriodOptions} desktopSize={4} tabletSize={4} />
                <InputDecimal name='minimumInvoicing' label='Faturamento Mínimo *' desktopSize={4} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave(form.getValues())) form.reset(defaultValues, { dirty: false })
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
