import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { getPickupRequestStatusDescription, PickupRequestStatusOptions } from 'types/pickup/pickup-request-status'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch, ConfirmDialog } from 'components/core'
import { handleSubmit, InputDatePicker, Select, SelectBoolean } from 'components/form'
import { DataTable, DeleteButton, EditButton } from 'components/data-table'
import { getFullAddressDescription } from 'components/address/types'
import { ClientAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'

import { handleSearch, handleEdit, handleDelete } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [deleteId, setDeleteId] = useState<string>()
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Pesquisa de Solicitação de Coleta'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa de Solicitação de Coleta' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente' desktopSize={3} />
                  <InputDatePicker name='initialDate' label='Data Inicial' desktopSize={2} tabletSize={4} />
                  <InputDatePicker name='finalDate' label='Data Final' desktopSize={2} tabletSize={4} />
                  <Select name='status' label='Status' options={PickupRequestStatusOptions} desktopSize={2} tabletSize={4} />
                  <SelectBoolean
                    name='notRouted'
                    label='Roteamento'
                    labelTrue='Apenas Solicitações Não Roteadas'
                    labelFalse='Apenas Solicitações Roteadas'
                    desktopSize={3}
                    tabletSize={4}
                  />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[
              { label: 'Cliente' },
              { order: 'Status', label: 'Status' },
              { order: 'Id', label: 'Número' },
              { label: 'Romaneio' },
              { order: 'Date', label: 'Data' },
              { label: 'Endereço' },
              { label: '' },
            ]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            update={toogleUpdate}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.clientFantasyName}</TableCell>
                  <TableCell>{getPickupRequestStatusDescription(data.status)}</TableCell>
                  <TableCell>{data.requestNumber}</TableCell>
                  <TableCell>{data.packingListNumber}</TableCell>
                  <TableCell>{format(parseISO(data.date), "dd'/'MM'/'yyyy")}</TableCell>
                  <TableCell>{getFullAddressDescription(data.address)}</TableCell>
                  <TableCell align='right'>
                    <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                    <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>
      <ConfirmDialog
        text='Deseja realmente excluir a solicitação de coleta? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          setDeleteId(undefined)
          if (deleteId) if (await handleDelete(deleteId)) setToogleUpdate(!toogleUpdate)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
