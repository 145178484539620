import React from 'react'
import { Card, CardContent, Table, TableBody, TableCell, TableHeader, TableRow } from 'react-md'

import { DeleteButton, EditButton } from 'components/data-table'
import { CalloutType } from 'components/core/callout'
import { Callout } from 'components/core'

interface ComponentProps {
  addresses: Array<any> | null
  showEdit: (address: any) => void
  showDelete: (address: any) => void
}

const Component: React.FC<ComponentProps> = ({ addresses, showEdit, showDelete }) => {
  return (
    <>
      {addresses?.length === 0 && <Callout text={'Nenhum endereço de coleta localizado'} type={CalloutType.Info} />}
      {addresses && addresses.length > 0 && (
        <Card fullWidth>
          <CardContent>
            <Table fullWidth>
              <TableHeader>
                <TableRow>
                  <TableCell>Rota de Coleta</TableCell>
                  <TableCell>Método de Coleta Automático</TableCell>
                  <TableCell>Horário de Coleta Inicial</TableCell>
                  <TableCell>Horário de Coleta Final</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {addresses.map((addressData: any, index) => (
                  <TableRow key={index}>
                    <TableCell>{addressData.route.label}</TableCell>
                    <TableCell>{addressData.automaticPickupMethod ? 'Sim' : 'Não'}</TableCell>
                    <TableCell>{addressData.pickupStartTime.substring(0, 5)}</TableCell>
                    <TableCell>{addressData.pickupFinalTime.substring(0, 5)}</TableCell>
                    <TableCell align='right'>
                      <EditButton id={`edit-${index}`} action={() => showEdit(addresses.find(x => x.id === addressData.id))} />
                      <DeleteButton id={`delete-${index}`} action={() => showDelete(addresses.find(x => x.id === addressData.id))} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default Component
