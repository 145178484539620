import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
}

export const detailDefaultValues = {
  phone1: null,
  phone2: null,
  email: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().max(60, 'O nome do contato deve ter no máximo 60 caracteres'),
})

export const detailValidationSchema = Yup.object().shape({
  phone1: Yup.string().nullable().required().max(11, 'O telefone 1 deve ter no máximo 11 caracteres'),
  phone2: Yup.string().nullable().max(11, 'O telefone 2 deve ter no máximo 60 caracteres'),
  email: Yup.string().nullable().max(60, 'O e-mail deve ter no máximo 60 caracteres'),
})
