import React, { useContext, useState } from 'react'
import { Chip, FormMessage, Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'
import { usePromiseTracker } from 'react-promise-tracker'

import { DepartmentEnum, DepartmentOption, DepartmentOptions } from 'types/sale/department'
import { ContactContext } from 'contexts/client/contact/contact-context'
import { handleSubmit, Input, InputPhone } from 'components/form'
import { Cell, Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  clientId: string
  contactId: string | undefined
  onHide: () => void
}

const Component: React.FC<ComponentProps> = ({ clientId, contactId, onHide }) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useForm({ defaultValues: defaultValues })
  const email = form.watch('email')
  const context = useContext(ContactContext)
  const [departments, setDepartments] = useState<Array<string>>([])

  const addOrRemoveDepartment = (department: string | null) => {
    if (department) {
      if (departments.includes(department)) {
        setDepartments(departments.filter(x => x != department))
      } else {
        setDepartments([...departments, department])
      }
    }
  }

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    setDepartments([])
  }

  return (
    <Modal
      title={'Adicionar Contato'}
      visible={true}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ clientId, contactId, ...form.getValues(), departments })) {
            await context.loadContacts()
            clear()
            onHide()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        onHide()
      }}>
      <Grid>
        <FormContext {...form}>
          <InputPhone name='phone1' label='Telefone 1 *' desktopSize={6} tabletSize={4} />
          <InputPhone name='phone2' label='Telefone 2' desktopSize={6} tabletSize={4} />
          <Input name='email' label='E-mail' maxLength={60} desktopSize={12} tabletSize={8} />
          <Cell>
            {DepartmentOptions.map((data: DepartmentOption, index) => (
              <Chip
                key={index}
                disabled={promiseInProgress}
                selected={departments.includes(data.value ?? '')}
                selectedThemed
                onClick={() => addOrRemoveDepartment(data.value)}
                style={{ marginRight: 3 }}>
                {data.label}
              </Chip>
            ))}
            {departments.some((x: any) => x === DepartmentEnum.LogisticsAndShipping) && email && (
              <FormMessage style={{ marginTop: 15 }} messageStyle={{ fontStyle: 'italic' }} id={'add-detail-logistics-and-shipping'}>
                * Logística e Expedição: O e-mail informado receberá as notificações em caso de pedidos inválidos cadastrados na plataforma e
                proximidade de coleta
              </FormMessage>
            )}
          </Cell>
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
