import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, FileInput, Chip, FontIcon, Text } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { ButtonEdit, ButtonSuccess, Cell, PageTitle } from 'components/core'
import ButtonError from 'components/core/button-error'

import { defaultValues, validationSchema } from './config'
import { handleGetCsv, handleImport } from './actions'
import { handleSubmit, Select } from 'components/form'
import { ServiceOptions } from 'types/quote/service'
import { ClientAutoComplete } from 'components/auto-complete'

const Component: React.FC = () => {
  const [fileName, setFileName] = useState<string | null>()
  const [file, setFile] = useState<string | ArrayBuffer | null>()
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Importação Csv'

    return () => {}
  }, [])

  const getBase64 = (e: any) => {
    var file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      if (reader && reader.result)
        setFile(btoa(encodeURIComponent(reader.result.toString()).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)))))
    }
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Importação Csv' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Cliente' desktopSize={3} tabletSize={4} />
                <Select name='service' label='Serviço *' options={ServiceOptions} desktopSize={3} tabletSize={4} />
              </Grid>
              <Grid>
                <Cell>
                  <Chip>{fileName ? `Arquivo: ${fileName}` : 'Nenhum arquivo selecionado'}</Chip>
                </Cell>
                <Cell>
                  <FileInput
                    id='import-input'
                    theme={'secondary'}
                    themeType={'contained'}
                    buttonType={'text'}
                    accept={'.csv'}
                    icon={<FontIcon>description</FontIcon>}
                    disabled={fileName ? true : false}
                    onChange={event => {
                      if (event?.currentTarget?.files?.[0]) {
                        setFileName(event.currentTarget.files[0].name)
                        getBase64(event)
                      }
                    }}>
                    {'Selecionar Arquivo'}
                  </FileInput>
                  <ButtonSuccess
                    text='Importar'
                    disabled={fileName ? false : true}
                    icon={<FontIcon>check</FontIcon>}
                    onClick={async () =>
                      await handleSubmit(form, validationSchema, async () => {
                        if (await handleImport({ ...form.getValues(), fileName, file })) {
                          form.reset(defaultValues, { dirty: false })
                          setFileName(null)
                          setFile(null)
                        }
                      })
                    }
                  />
                  <ButtonError
                    text='Apagar'
                    disabled={fileName ? false : true}
                    icon={<FontIcon>cancel</FontIcon>}
                    onClick={async () => {
                      setFileName(null)
                      setFile(null)
                    }}
                  />
                  <ButtonEdit
                    text='Download do Modelo'
                    icon={<FontIcon>download</FontIcon>}
                    onClick={async () => await handleGetCsv('Pedidos.csv')}
                  />
                </Cell>
                <Cell>
                  <Text type='caption' component='h1'>
                    <i>Para realizar a importação informe um arquivo com até no máximo 1mb de tamanho</i>
                  </Text>
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
