import Yup from 'utils/yup'

export const defaultValues = {
  initialCreationDate: null,
  finalCreationDate: null,
  number: null,
  finished: null,
}

export const validationSchema = Yup.object().shape({
  initialCreationDate: Yup.date().nullable(),
  finalCreationDate: Yup.date().nullable(),
  number: Yup.number().nullable(),
  finished: Yup.boolean().nullable(),
})
