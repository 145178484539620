import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Configuration } from 'react-md'

import Login from 'pages/auth/login'
import Send from 'pages/auth/forgot-password/send'
import UpdatePassword from 'pages/auth/forgot-password/update-password'
import SendConfirmEmail from 'pages/auth/confirm-email/send'
import ConfirmEmail from 'pages/auth/confirm-email/confirm'

const AuthRoutes: React.FC = () => {
  return (
    <Configuration>
      <Redirect to='/' />
      <Route path='/' exact component={Login} />
      <Route path='/forgot-password/send' exact component={Send} />
      <Route path='/forgot-password/update-password' exact component={UpdatePassword} />
      <Route path='/send-confirm-email' exact component={SendConfirmEmail} />
      <Route path='/confirm-email' exact component={ConfirmEmail} />
    </Configuration>
  )
}

export default AuthRoutes
