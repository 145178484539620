import Yup from 'utils/yup'

export const defaultValues = {
  companyId: null,
  name: null,
  groupInvoicing: null,
  invoicingPeriod: null,
  minimumInvoicing: null,
  blocked: null,
}

export const validationSchema = Yup.object().shape({
  companyId: Yup.string().nullable().required(),
  name: Yup.string().nullable().required().min(3, 'O nome deve ter no mínimo 3 caracteres').max(60, 'O nome deve ter no máximo 60 caracteres'),
  groupInvoicing: Yup.boolean().nullable().required(),
  invoicingPeriod: Yup.string().nullable().required(),
  minimumInvoicing: Yup.number().nullable().required().min(1, 'Faturamento mínimo inválido'),
  blocked: Yup.boolean().nullable().required(),
})
