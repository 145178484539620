import api from 'services/quote/client-coverage'

export const handleSave = async (values: any) => {
  let res = await api.addPartnerLinkAsync(values)

  return res?.data
}

export const handleSearch = async (request: any) => {
  let res = await api.searchPartnerLink(request)

  return res?.data ?? []
}

export const handleRemove = async (id: string, partnerId: string) => {
  let res = await api.removePartnerLink(id, partnerId)

  return res?.data?.success
}
