import Yup from 'utils/yup'

export const defaultValues = {
  action: null,
  deadline: null,
}

export const validationSchema = Yup.object().shape({
  action: Yup.string().nullable().required(),
  deadline: Yup.number().nullable().required().max(255, 'O prazo deve ser de no máximo 255 dias'),
})
