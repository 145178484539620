import React from 'react'
import { Card, CardContent, FontIcon, Text, List, ListItem, CSSTransitionProvidedProps } from 'react-md'

import { getServiceDescription } from 'types/quote/service'
import { Cell } from 'components/core'

interface ComponentProps {
  results: Array<any>
  transitionProps: CSSTransitionProvidedProps<HTMLDivElement>
}

const Component: React.FC<ComponentProps> = ({ results, transitionProps }) => {
  return (
    <>
      {results && results.length > 0 && (
        <>
          <Cell>
            <Text type='headline-6' margin='none'>
              Resultado
            </Text>
          </Cell>
          {results.map((data: any, index) => (
            <Cell desktopSize={4} key={index}>
              <div {...transitionProps}>
                <Card fullWidth>
                  <CardContent>
                    <List>
                      <ListItem leftAddon={<FontIcon>verified</FontIcon>}>Serviço: {getServiceDescription(data.service)}</ListItem>
                      <ListItem leftAddon={<FontIcon>calendar_month</FontIcon>}>Prazo de Entrega: {data.deliveryTime}</ListItem>
                      <ListItem leftAddon={<FontIcon>attach_money</FontIcon>}>Valor: {data.value}</ListItem>
                    </List>
                  </CardContent>
                </Card>
              </div>
            </Cell>
          ))}
        </>
      )}
    </>
  )
}

export default Component
