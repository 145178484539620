import { trackPromise } from 'react-promise-tracker'
import { AxiosRequestConfig } from 'axios'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/quote'
const Api = {
  quoteClient: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/client`, request)),
  quoteFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/fm`, request)),
}

export default Api
