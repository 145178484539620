export const getOriginDescription = (status: string): string => {
  return OriginOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface OriginList {
  value: OriginEnum | null
  label: string
}

export enum OriginEnum {
  FmTransportesApi = 'FmTransportesApi',
  Manual = 'Manual',
  Csv = 'Csv',
  Xml = 'Xml',
  Enel = 'Enel',
  IntelipostApi = 'IntelipostApi',
  Bling = 'Bling',
  BlingDeclaration = 'BlingDeclaration',
  Tiny = 'Tiny',
  TinyDeclaration = 'TinyDeclaration',
  VtexLog = 'VtexLog',
}

export const OriginOptions: Array<OriginList> = [
  { value: null, label: '' },
  { value: OriginEnum.Bling, label: 'Bling' },
  { value: OriginEnum.BlingDeclaration, label: 'Bling (Declaração)' },
  { value: OriginEnum.Csv, label: 'Csv' },
  { value: OriginEnum.Enel, label: 'Enel' },
  { value: OriginEnum.FmTransportesApi, label: 'Fm Transportes (Api)' },
  { value: OriginEnum.IntelipostApi, label: 'Intelipost (Api)' },
  { value: OriginEnum.Manual, label: 'Manual' },
  { value: OriginEnum.Tiny, label: 'Tiny' },
  { value: OriginEnum.TinyDeclaration, label: 'Tiny (Declaração)' },
  { value: OriginEnum.VtexLog, label: 'VtexLog' },
  { value: OriginEnum.Xml, label: 'Xml' },
]
