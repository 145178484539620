import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow } from 'react-md'

import { ConfirmDialog, Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { DataTable, EditButton, DeleteButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'
import { handleSubmit, Input } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSearch, handleEdit, handleDelete } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [deleteId, setDeleteId] = useState<string>()
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Pesquisa de Grupo Empresarial'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa de Grupo Empresarial' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <Input name='name' label='Nome' />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[{ order: 'Name', label: 'Nome' }, { label: '' }]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            update={toogleUpdate}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.name}</TableCell>
                  <TableCell align='right'>
                    <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                    <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>
      <ConfirmDialog
        text='Deseja realmente excluir o grupo empresarial? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          setDeleteId(undefined)
          if (deleteId) if (await handleDelete(deleteId)) setToogleUpdate(!toogleUpdate)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
