import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent } from 'react-md'

import { Cell, PageTitle, ButtonSuccess, Callout } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { CalloutType } from 'components/core/callout'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSendTest } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Enviar Teste'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Enviar Teste' />
        <Card fullWidth>
          <CardContent>
            <Callout
              text='O método de teste realiza o envio de uma requisição na url cadastrada com as informações de entrega de um pedido com alguns eventos preenchidos para que seja possível validar a integração, no ambiente de produção recomandamos que a requisição seja recebida e respondida na sequência, será considerada falha uma requisição com o código HTTP diferente de 200 ou que leve mais que 10s para ser respondida, os eventos podem chegar aos endpoints em ordem diferente daquela do disparo e mais de uma vez, devido às novas tentativas de envio, recomendamos testá-lo com o RequestBin, esta ferramenta gera uma URL temporária onde você pode inspecionar as requisições realizadas.'
              type={CalloutType.Info}
            />
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Remetente *' desktopSize={6} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Enviar Teste'
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSendTest(form.getValues())) form.reset(defaultValues, { dirty: false })
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
