import api from 'services/order/cubage'

export const handleSearch = async (tag: string) => {
  let res = await api.getByTagFm(tag)

  return res?.data?.data ?? []
}

export const handleSave = async (request: any) => {
  let res = await api.cubageManual(request)

  return res?.data ?? []
}
