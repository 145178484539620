export const getPickupRequestStatusDescription = (status: string): string => {
  return PickupRequestStatusOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface PickupRequestStatusList {
  value: PickupRequestStatusEnum | null
  label: string
}

export enum PickupRequestStatusEnum {
  Opened = 'Opened',
  Running = 'Running',
  VehicleWithoutCubage = 'VehicleWithoutCubage',
  BrokenDownVehicle = 'BrokenDownVehicle',
  TimeExceeded = 'TimeExceeded',
  NotVisited = 'NotVisited',
  WaitMoreThan10min = 'WaitMoreThan10min',
  OrderNotAvailable = 'OrderNotAvailable',
  PickupPerformed = 'PickupPerformed',
  Canceled = 'Canceled',
}

export const PickupRequestStatusOptions: Array<PickupRequestStatusList> = [
  { value: null, label: '' },
  { value: PickupRequestStatusEnum.Canceled, label: 'Cancelado' },
  { value: PickupRequestStatusEnum.PickupPerformed, label: 'Coleta Relizada' },
  { value: PickupRequestStatusEnum.Opened, label: 'Em Aberto' },
  { value: PickupRequestStatusEnum.Running, label: 'Em Execução' },
  { value: PickupRequestStatusEnum.OrderNotAvailable, label: 'Encomenda não Disponível' },
  { value: PickupRequestStatusEnum.WaitMoreThan10min, label: 'Espera Superior a 10min' },
  { value: PickupRequestStatusEnum.TimeExceeded, label: 'Horário Excedido' },
  { value: PickupRequestStatusEnum.NotVisited, label: 'Não Visitado' },
  { value: PickupRequestStatusEnum.BrokenDownVehicle, label: 'Veiculo Avariado' },
  { value: PickupRequestStatusEnum.VehicleWithoutCubage, label: 'Veiculo Sem Cubagem' },
]

export const PickupRequestStatusConcludeOptions: Array<PickupRequestStatusList> = [
  { value: null, label: '' },
  { value: PickupRequestStatusEnum.Canceled, label: 'Cancelado' },
  { value: PickupRequestStatusEnum.PickupPerformed, label: 'Coleta Relizada' },
  { value: PickupRequestStatusEnum.OrderNotAvailable, label: 'Encomenda não Disponível' },
  { value: PickupRequestStatusEnum.WaitMoreThan10min, label: 'Espera Superior a 10min' },
  { value: PickupRequestStatusEnum.TimeExceeded, label: 'Horário Excedido' },
  { value: PickupRequestStatusEnum.NotVisited, label: 'Não Visitado' },
  { value: PickupRequestStatusEnum.BrokenDownVehicle, label: 'Veiculo Avariado' },
  { value: PickupRequestStatusEnum.VehicleWithoutCubage, label: 'Veiculo Sem Cubagem' },
]
