import api from 'services/delivery/delivery-packing-list'

export const handleGetOrders = async (id: string) => {
  let res = await api.getOrders(id)

  return res?.data?.data
}

export const handleRemoveOrder = async (packingListId: string, itemId: string) => {
  let res = await api.removeOrder(packingListId, itemId)

  return res?.data?.success
}
