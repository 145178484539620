import Yup from 'utils/yup'

export const defaultValues = {
  state: null,
  ac: null,
  al: null,
  am: null,
  ap: null,
  ba: null,
  ce: null,
  df: null,
  es: null,
  go: null,
  ma: null,
  mg: null,
  ms: null,
  mt: null,
  pa: null,
  pb: null,
  pe: null,
  pi: null,
  pr: null,
  rj: null,
  rn: null,
  ro: null,
  rr: null,
  rs: null,
  sc: null,
  se: null,
  sp: null,
  to: null,
}

export const validationSchema = Yup.object().shape({
  state: Yup.string().nullable(),
  ac: Yup.number().required(),
  al: Yup.number().required(),
  am: Yup.number().required(),
  ap: Yup.number().required(),
  ba: Yup.number().required(),
  ce: Yup.number().required(),
  df: Yup.number().required(),
  es: Yup.number().required(),
  go: Yup.number().required(),
  ma: Yup.number().required(),
  mg: Yup.number().required(),
  ms: Yup.number().required(),
  mt: Yup.number().required(),
  pa: Yup.number().required(),
  pb: Yup.number().required(),
  pe: Yup.number().required(),
  pi: Yup.number().required(),
  pr: Yup.number().required(),
  rj: Yup.number().required(),
  rn: Yup.number().required(),
  ro: Yup.number().required(),
  rr: Yup.number().required(),
  rs: Yup.number().required(),
  sc: Yup.number().required(),
  se: Yup.number().required(),
  sp: Yup.number().required(),
  to: Yup.number().required(),
})
