import React, { useEffect, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  ExpansionList,
  ExpansionPanel,
  FontIcon,
  FormMessage,
  Grid,
  List,
  ListItem,
  Text,
  usePanels,
  useToggle,
} from 'react-md'

import { Actions, ButtonEdit, ButtonLog, ButtonSuccess, Callout, Cell, ConfirmDialog } from 'components/core'
import { DepartmentEnum, DepartmentOption, DepartmentOptions } from 'types/sale/department'
import { ContactContext } from 'contexts/client/contact/contact-context'
import { CalloutType } from 'components/core/callout'

import { handleGetContacts, handleRemoveContact } from './actions'

import AddContact from './components/add-contact'
import AddContactDetail from './components/add-contact-detail'
import EditContact from './components/edit-contact'

interface ComponentProps {
  clientId: string
}

const Component: React.FC<ComponentProps> = ({ clientId }) => {
  const [contact, setContact] = useState<any | null>(null)
  const [contacts, setContacts] = useState<Array<any> | null>(null)
  const [contactId, setContactId] = useState<string>()
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleAddModal, enableAddModal, disableAddModal] = useToggle(false)
  const [visibleAddDetailModal, enableAddDetailModal, disableAddDetailModal] = useToggle(false)
  const [visibleEditModal, enableEditModal, disableEditModal] = useToggle(false)
  const [visibleDeleteModal, enableDeleteModal, disableDeleteModal] = useToggle(false)
  const [panels, onKeyDown] = usePanels({
    count: contacts && contacts?.length > 0 ? contacts.length : 1,
    idPrefix: 'configuring-panels',
    multiple: true,
  })

  useEffect(() => {
    loadContacts()

    return () => {}
  }, [clientId])

  useEffect(() => {
    if (contactId) setContact(contacts?.find(x => x.id === contactId))

    return () => {}
  }, [contactId, contacts])

  const loadContacts = async () => setContacts(await handleGetContacts(clientId))

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <Grid>
            <Cell>
              <Text type='headline-6' margin='none'>
                Contatos
              </Text>
            </Cell>
            <Cell>
              {contacts?.length === 0 && <Callout text={'Nenhum contato localizado'} type={CalloutType.Info} />}
              {contacts &&
                contacts.length > 0 &&
                contacts.map((contactData: any, index) => (
                  <ExpansionList key={index} onKeyDown={onKeyDown}>
                    <ExpansionPanel {...panels[index]} header={contactData.name}>
                      <Grid>
                        <Cell>
                          {contactData.contacts.length === 0 && <Callout text={'Nenhum contato localizado'} type={CalloutType.Info} />}
                          {contactData.contacts.map((detailData: any, detailIndex: number) => (
                            <List key={detailIndex} dense>
                              <ListItem leftAddon={<FontIcon>phone</FontIcon>}>Telefone 1: {detailData.phone1}</ListItem>
                              {
                                <ListItem leftAddon={<FontIcon>phone</FontIcon>}>
                                  Telefone 2: {detailData.phone2 ? detailData.phone2 : 'Não Informado'}
                                </ListItem>
                              }
                              {
                                <ListItem leftAddon={<FontIcon>email</FontIcon>}>
                                  E-mail: {detailData.email ? detailData.email : 'Não Informado'}
                                </ListItem>
                              }
                              <ListItem>
                                {DepartmentOptions.map((optionData: DepartmentOption, optionIndex) => (
                                  <Chip
                                    key={optionIndex}
                                    selected={detailData.departments.some((x: any) => x.department === optionData.value)}
                                    selectedThemed
                                    style={{ marginRight: 3 }}>
                                    {optionData.label}
                                  </Chip>
                                ))}
                                {detailData.departments.some((x: any) => x.department === DepartmentEnum.LogisticsAndShipping) &&
                                  detailData.email && (
                                    <FormMessage
                                      style={{ marginTop: 15 }}
                                      messageStyle={{ fontStyle: 'italic' }}
                                      id={'search-logistics-and-shipping'}>
                                      * Logística e Expedição: O e-mail informado receberá a notificação em caso de pedidos inválidos cadastrados na
                                      plataforma
                                    </FormMessage>
                                  )}
                              </ListItem>
                              <ListItem>
                                <Divider />
                              </ListItem>
                            </List>
                          ))}
                        </Cell>
                        <Cell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                          <ButtonEdit
                            text='Ações'
                            icon={<FontIcon>settings</FontIcon>}
                            onClick={async () => {
                              enableActions()
                              setContactId(contactData.id)
                            }}
                          />
                          <ButtonLog entity='ClientContact' id={contactData.id} />
                        </Cell>
                      </Grid>
                    </ExpansionPanel>
                  </ExpansionList>
                ))}
            </Cell>
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonSuccess text='Novo Contato' icon={<FontIcon>add_call</FontIcon>} onClick={async () => enableAddModal()} />
        </CardActions>
      </Card>

      <ContactContext.Provider value={{ loadContacts, contact }}>
        <AddContact clientId={clientId} visibleModal={visibleAddModal} disableModal={disableAddModal} />

        {visibleAddDetailModal && (
          <AddContactDetail
            clientId={clientId}
            contactId={contactId}
            onHide={() => {
              setContactId(undefined)
              disableAddDetailModal()
            }}
          />
        )}

        {visibleEditModal && (
          <EditContact
            clientId={clientId}
            contactData={contact}
            onHide={async () => {
              setContactId(undefined)
              disableEditModal()
            }}
          />
        )}
      </ContactContext.Provider>

      {visibleDeleteModal && (
        <ConfirmDialog
          text='Deseja realmente excluir o contato? Essa ação não poderá ser desfeita'
          visible={true}
          confirmAction={async () => {
            if (contactId)
              if (await await handleRemoveContact(clientId, contactId)) {
                setContactId(undefined)
                disableDeleteModal()
                await loadContacts()
              }
          }}
          cancelAction={async () => {
            setContactId(undefined)
            disableDeleteModal()
          }}
        />
      )}

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>add</FontIcon>} onClick={async () => enableAddDetailModal()}>
          Adicionar
        </ListItem>
        <ListItem leftAddon={<FontIcon>edit</FontIcon>} onClick={async () => enableEditModal()}>
          Editar
        </ListItem>
        <ListItem leftAddon={<FontIcon>close</FontIcon>} onClick={async () => enableDeleteModal()}>
          Remover
        </ListItem>
      </Actions>
    </>
  )
}

export default Component
