import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { handleSubmit, InputDecimal, InputNumber } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  visibleModal: boolean
  addVolume: (volume: any) => void
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ visibleModal, addVolume, disableModal }) => {
  const form = useForm({ defaultValues })

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
  }

  return (
    <Modal
      title={'Adicionar Volume'}
      visible={visibleModal}
      width={600}
      confirmTitle={'Adicionar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          addVolume(form.getValues())
          clear()
          disableModal()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <FormContext {...form}>
        <Grid>
          <InputNumber name='quantity' label='Quantidade *' desktopSize={6} tabletSize={4} maxLength={2} />
          <InputDecimal name='realWeight' label='Peso Real (kg) *' desktopSize={6} tabletSize={4} maxLength={5} />
        </Grid>
        <Grid>
          <InputNumber name='length' label='Comprimento (cm) *' desktopSize={4} tabletSize={4} maxLength={3} />
          <InputNumber name='height' label='Altura (cm) *' desktopSize={4} tabletSize={4} maxLength={3} />
          <InputNumber name='width' label='Largura (cm) *' desktopSize={4} tabletSize={4} maxLength={3} />
        </Grid>
      </FormContext>
    </Modal>
  )
}

export default Component
