export const getTypeDeliveryDescription = (status: string): string => {
  return TypeDeliveryOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface TypeDeliveryList {
  value: TypeDeliveryEnum | null
  label: string
}

export enum TypeDeliveryEnum {
  Normal = 'Normal',
  Rma = 'Rma',
}

export const TypeDeliveryOptions: Array<TypeDeliveryList> = [
  { value: null, label: '' },
  { value: TypeDeliveryEnum.Normal, label: 'Normal' },
  { value: TypeDeliveryEnum.Rma, label: 'Rma' },
]
