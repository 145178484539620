import api from 'services/order/order'

export const handleSearch = async (request: any) => {
  let res = await api.searchFm(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/order/${id}/edit`)
}
