import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, Chip, FontIcon, FormMessage, List, ListItem, useToggle } from 'react-md'

import { DepartmentEnum, DepartmentOption, DepartmentOptions } from 'types/sale/department'
import { Actions, ButtonEdit, ButtonLog, Callout, ConfirmDialog } from 'components/core'
import { CalloutType } from 'components/core/callout'

interface ComponentProps {
  contactList: Array<any>
  editContact: (id: string) => void
  removeContact: (id: string) => void
}

const Component: React.FC<ComponentProps> = ({ contactList, editContact, removeContact }) => {
  const [contacts, setContacts] = useState<Array<any>>([])
  const [contactId, setContactId] = useState<string>()
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleDeleteModal, enableDeleteModal, disableDeleteModal] = useToggle(false)

  useEffect(() => {
    setContacts(contactList)

    return () => {}
  }, [contactList])

  return (
    <>
      {contacts.length === 0 && <Callout text={'Nenhum contato informado'} type={CalloutType.Info} />}
      {contacts.length > 0 &&
        contacts.map((data: any, index) => (
          <Card fullWidth key={index} style={{ marginBottom: 5 }}>
            <CardContent>
              <List dense>
                <ListItem leftAddon={<FontIcon>phone</FontIcon>}>Telefone 1: {data.phone1}</ListItem>
                <ListItem leftAddon={<FontIcon>phone</FontIcon>}>Telefone 2: {data.phone2}</ListItem>
                <ListItem leftAddon={<FontIcon>email</FontIcon>}>E-mail: {data.email}</ListItem>
                <ListItem>
                  {DepartmentOptions.map((optionData: DepartmentOption, index) => (
                    <Chip
                      key={index}
                      selected={data.departments.some((x: any) => x.department === optionData.value)}
                      selectedThemed
                      style={{ marginRight: 3 }}>
                      {optionData.label}
                    </Chip>
                  ))}
                </ListItem>
                <ListItem>
                  {data.departments.some((x: any) => x.department === DepartmentEnum.LogisticsAndShipping) && data.email && (
                    <FormMessage messageStyle={{ fontStyle: 'italic' }} id={'add-detail-logistics-and-shipping'}>
                      * Logística e Expedição: O e-mail informado receberá as notificações em caso de pedidos inválidos cadastrados na plataforma e
                      proximidade de coleta
                    </FormMessage>
                  )}
                </ListItem>
              </List>
            </CardContent>
            <CardActions>
              <ButtonEdit
                text='Ações'
                icon={<FontIcon>settings</FontIcon>}
                onClick={async () => {
                  enableActions()
                  setContactId(data.id)
                }}
              />
              <ButtonLog entity='ClientContactDetail' id={data.id} />
            </CardActions>
          </Card>
        ))}

      {visibleDeleteModal && (
        <ConfirmDialog
          text='Deseja realmente excluir o contato? Essa ação não poderá ser desfeita'
          visible={true}
          confirmAction={async () => {
            if (contactId) {
              removeContact(contactId)
              disableDeleteModal()
            }
          }}
          cancelAction={async () => {
            setContactId(undefined)
            disableDeleteModal()
          }}
        />
      )}

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>edit</FontIcon>} onClick={async () => editContact(contactId!)}>
          Editar
        </ListItem>
        <ListItem leftAddon={<FontIcon>close</FontIcon>} onClick={async () => enableDeleteModal()}>
          Remover
        </ListItem>
      </Actions>
    </>
  )
}

export default Component
