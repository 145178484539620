import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyDX5FhK0VwSFUGbgl8h_ibNhhVNZqsYKTU',
  authDomain: 'alfatracking-b327d.firebaseapp.com',
  projectId: 'alfatracking-b327d',
  storageBucket: 'alfatracking-b327d.appspot.com',
  messagingSenderId: '752593685336',
  appId: '1:752593685336:web:16c5189a4dc3daeb8db32d',
}

export const firebaseApp = initializeApp(firebaseConfig)

export const firebaseUser = {
  email: 'alfatracking@hotmail.com',
  password: 'b9630f7ca45b4388a569678451dbd45b',
}
