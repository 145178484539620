import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/pickup-address'
const Api = {
  listFm: (clientId: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/list/${clientId}`)),
  listClient: (clientId: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/list/${clientId}/client`)),
}

export default Api
