import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'

import { Input, InputDocument, InputPhone } from 'components/form'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Destinatário
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <FormContext {...form}>
            <Input name='name' label='Nome *' desktopSize={12} tabletSize={8} />
            <InputDocument name='document' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
            <Input name='ie' label='Inscrição Estadual' desktopSize={3} tabletSize={4} />
            <Input name='email' label='Email' desktopSize={3} tabletSize={4} />
            <InputPhone name='phone' label='Telefone' desktopSize={3} tabletSize={4} />
          </FormContext>
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
