import React, { useContext, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Grid } from 'react-md'

import { ContactContext } from 'contexts/client/contact/contact-context'
import { handleSubmit, Input } from 'components/form'
import { Cell, Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'
import List from './components/list'
import Add from './components/add'

interface ComponentProps {
  clientId: string
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ clientId, visibleModal, disableModal }) => {
  const form = useForm({ defaultValues })
  const context = useContext(ContactContext)
  const [contacts, setContacts] = useState<Array<any>>([])

  const addContact = (contact: any) => setContacts([...contacts, contact])

  const removeContact = (detailIndex: number) => setContacts(contacts.filter((_, index) => index != detailIndex))

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    setContacts([])
  }

  return (
    <Modal
      title={'Adicionar Contato'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (contacts.length === 0) {
            toast.warn('Nenhum telefone de contato informado')
          } else {
            if (await handleSave({ clientId, ...form.getValues(), contacts })) {
              await context.loadContacts()
              clear()
              disableModal()
            }
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <Input name='name' label='Nome *' desktopSize={12} tabletSize={12} />
        </FormContext>
        <Cell>
          <Add addContact={addContact} />
        </Cell>
        <Cell>
          <List contactList={contacts} removeContact={removeContact} />
        </Cell>
      </Grid>
    </Modal>
  )
}

export default Component
