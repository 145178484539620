import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { TextField, FormMessage } from 'react-md'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

export const InputTime = ({ name, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const form = useFormContext()
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })

    return () => {}
  }, [form.register])

  const formatValue = () => {
    if (formValue?.length > 5) return formValue ? sanitizeValue(String(formValue)) : ''
    else return formValue ? String(formValue) : ''
  }

  const sanitizeValue = (value: string) => value?.substring(0, 5)

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        type={'time'}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled}
        value={formatValue()}
        onChange={event => {
          if (form.errors[name]) form.clearError(name)
          if (event.target.value) form.setValue(name, event.target.value)
          else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
