import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/pickup-request'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getPackingListSequence: (packingListId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.get(`${url}/${packingListId}/packing-list-sequence`)),
  createFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/fm`, request)),
  createClient: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/client`, request)),
  searchFm: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/fm`, request)),
  searchClient: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/client`, request)),
  searchByPackingListId: (request: DataTableRequest): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/search-by-packing-list-id`, request)),
  addInPackingList: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/add-in-packing-list`, request)),
  concludeFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/conclude/fm`, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
}

export default Api
