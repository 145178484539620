import { useEffect, useState } from 'react'
import { TextField, FormMessage } from 'react-md'
import { useFormContext } from 'react-hook-form'

import { FieldProps } from 'components/form/types'
import Cell from 'components/core/cell'

interface ZipCodeProps extends FieldProps {
  clear?: () => void
}

export const ZipCode = ({ name, desktopSize, tabletSize, disabled, clear, ...rest }: ZipCodeProps) => {
  const form = useFormContext()
  const [text, setText] = useState<string>('')
  const ZIPCODE_MAXLENGTH = 8

  useEffect(() => {
    form.register({ name })

    return () => {}
  }, [form.register])

  useEffect(() => {
    let formValue = String(form.getValues()[name] ?? '')
    if (formValue) {
      let value = ''
      if (formValue.length < ZIPCODE_MAXLENGTH && ZIPCODE_MAXLENGTH - formValue.length > 0) {
        value = `${'0'.repeat(ZIPCODE_MAXLENGTH - formValue.length)}${formValue}`
      } else {
        value = formValue
      }

      setText(
        formatCepMask(value)
          .replace(/\D/g, '')
          .replace(/^(\d{5})(\d{3})+?$/, '$1-$2'),
      )
    } else {
      setText('')
    }

    return () => {}
  }, [form.getValues()[name]])

  useEffect(() => {
    if (!text || text?.length === 0) form.setValue(name, null)
    if (text?.length === ZIPCODE_MAXLENGTH) form.setValue(name, parseInt(text.replace(/\D/g, '')))

    return () => {}
  }, [text])

  const formatCepMask = (value: string): string => `0000000${value ?? ''}`.substring(7, `0000000${value ?? ''}`.length)

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled}
        value={formatCepMask(text)
          .replace(/\D/g, '')
          .replace(/^(\d{5})(\d{3})+?$/, '$1-$2')}
        onKeyDown={event => {
          if (event.key === 'Backspace' || event.key === 'Delete') {
            form.setValue(name, null)
            setText('')
            if (clear) clear()
          }
        }}
        onChange={selected => {
          form.clearError(name)
          if (selected.target.value != text && clear) clear()
          setText(selected.target.value)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
