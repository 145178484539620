import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/webhook'
const Api = {
  resend: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/resend`, request)),
  sendTest: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/send-test`, request)),
}

export default Api
