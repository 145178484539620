import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getIntegrationClientMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.order.client) {
    menu['/integration/client'] = getMenu('/integration/client', null, 'Integrações', 'api', null)

    menu['/integration/webhook/client'] = getMenu('/integration/webhook/client', '/integration/client', 'Webhook', 'webhook', null)
    menu['/integration/webhook/send-test/client'] = getMenu(
      '/integration/webhook/send-test/client',
      '/integration/webhook/client',
      'Enviar Teste',
      'send_and_archive',
      '/integration/webhook/send-test/client',
    )
    menu['/integration/webhook/resend/client'] = getMenu(
      '/integration/webhook/resend/client',
      '/integration/webhook/client',
      'Reenviar Eventos',
      'send',
      '/integration/webhook/resend/client',
    )
  }
}
