import api from 'services/sale/client'

export const handleGetContacts = async (clientId: any) => {
  let res = await api.getContacts(clientId)

  return res?.data?.data ?? []
}

export const handleRemoveContact = async (clientId: any, id: string) => {
  let res = await api.removeContact(clientId, id)

  return res?.data?.data ?? []
}
