import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getPickupClientMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.order.client) {
    menu['/pickup/client'] = getMenu('/pickup/client', null, 'Coleta', 'forklift', null)

    menu['/pickup/pickup-request/client'] = getMenu('/pickup/pickup-request/client', '/pickup/client', 'Solicitação', 'front_hand', null)
    menu['/pickup/pickup-request/new/client'] = getMenu(
      '/pickup/pickup-request/new/client',
      '/pickup/pickup-request/client',
      'Nova',
      'add',
      '/pickup/pickup-request/new/client',
    )
    menu['/pickup/pickup-request/search/client'] = getMenu(
      '/pickup/pickup-request/search/client',
      '/pickup/pickup-request/client',
      'Pesquisa',
      'search',
      '/pickup/pickup-request/search/client',
    )
  }
}
