import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardContent, TabsManager, Tabs, TabPanels, TabPanel } from 'react-md'

import { addressDefaultValues } from 'components/address/config'
import { getPickupRequestStatusDescription } from 'types/pickup/pickup-request-status'
import { DateLabel, DateTimeLabel, NumberLabel, TextLabel, TimeLabel } from 'components/label'
import { Cell, PageTitle, Callout } from 'components/core'
import { CalloutType } from 'components/core/callout'
import Address from 'components/address'

import { handleGet } from './actions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const addressForm = useForm({ defaultValues: addressDefaultValues })
  const [activeTab, setActiveTab] = useState<number>(0)
  const [pickupRequest, setPickupRequest] = useState<any>()

  useEffect(() => {
    document.title = 'Edição de Solicitação de Coleta'

    const load = async () => {
      const res = await handleGet(id)
      if (res) {
        addressForm.reset(res.address)
        setPickupRequest(res)
      }
    }

    load()

    return () => {}
  }, [id])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Solicitação de Coleta' />
        <TabsManager
          tabs={['Solicitação', 'Conclusão']}
          activeIndex={activeTab}
          onActiveIndexChange={activeIndexNumber => setActiveTab(activeIndexNumber)}
          tabsId='packing-list-tabs'>
          <Tabs />
          <TabPanels persistent={true}>
            <TabPanel>
              <Card fullWidth>
                <CardContent>
                  <Grid>
                    <NumberLabel
                      name='requestNumber'
                      label='Número da Solicitação'
                      value={pickupRequest?.requestNumber}
                      desktopSize={2}
                      tabletSize={3}
                    />
                    <NumberLabel name='packingListNumber' label='Romaneio' value={pickupRequest?.packingListNumber} desktopSize={2} tabletSize={3} />
                    <TextLabel
                      name='status'
                      label='Status'
                      value={getPickupRequestStatusDescription(pickupRequest?.status)}
                      desktopSize={2}
                      tabletSize={3}
                    />
                    <DateLabel name='date' label='Data' value={pickupRequest?.date} desktopSize={2} tabletSize={2} />
                    <TimeLabel name='pickupStartTime' label='Horário Inicial' value={pickupRequest?.pickupStartTime} desktopSize={2} tabletSize={3} />
                    <TimeLabel name='pickupFinalTime' label='Horário Final' value={pickupRequest?.pickupFinalTime} desktopSize={2} tabletSize={3} />
                  </Grid>
                  <Address form={addressForm} disabled />
                </CardContent>                
              </Card>
            </TabPanel>
            <TabPanel>
              {!pickupRequest?.dateCheckin && !pickupRequest?.conclude?.date ? (
                <Callout text={'Solicitação de coleta não concluída'} type={CalloutType.Info} />
              ) : (
                <Card fullWidth>
                  <CardContent>
                    <Grid>
                      <DateTimeLabel name='dateCheckin' label='Data do Checkin' value={pickupRequest?.dateCheckin} desktopSize={2} tabletSize={4} />
                      {pickupRequest?.conclude && (
                        <>
                          <DateLabel name='date' label='Data da Coleta' value={pickupRequest?.conclude?.date} desktopSize={2} tabletSize={4} />
                          <TextLabel
                            name='status'
                            label='Status'
                            value={getPickupRequestStatusDescription(pickupRequest?.conclude?.status)}
                            desktopSize={2}
                            tabletSize={4}
                          />
                          <NumberLabel
                            name='volumeQuantity'
                            label='Qtd. de Volumes'
                            value={pickupRequest?.conclude?.volumeQuantity}
                            desktopSize={2}
                            tabletSize={4}
                          />
                          <TextLabel
                            name='responsible'
                            label='Responsável'
                            value={pickupRequest?.conclude?.responsible}
                            desktopSize={4}
                            tabletSize={8}
                          />
                          <TextLabel
                            name='observation'
                            label='Observação'
                            value={pickupRequest?.conclude?.observation}
                            desktopSize={12}
                            tabletSize={8}
                          />
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </TabPanel>
          </TabPanels>
        </TabsManager>
      </Cell>
    </Grid>
  )
}

export default Component
