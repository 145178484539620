import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent } from 'react-md'

import { handleSubmit, Input } from 'components/form'
import { ButtonSearch } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleAddOrder } from './actions'

let successAudio = new Audio(require('sounds/success.mp3').default)
let errorAudio = new Audio(require('sounds/error.mp3').default)

interface ComponentProps {
  packingListId: string
  search: (packingListId: string) => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ packingListId, search }) => {
  const form = useForm({ defaultValues })

  const clear = () => form.reset(defaultValues, { dirty: false })

  const addOrder = async () => {
    await handleSubmit(form, validationSchema, async () => {
      let values = form.getValues()
      if (values.trackingCode) {
        let res = await handleAddOrder({ packingListId, trackingCode: values.trackingCode })
        if (res) {
          await successAudio.play()
          await search(packingListId)
        } else {
          await errorAudio.play()
        }
        clear()
      }
    })
  }

  return (
    <Card fullWidth>
      <CardContent>
        <FormContext {...form}>
          <Grid>
            <Input
              name='trackingCode'
              label='Tracking do pedido'
              onKeyDown={async (e: any) => {
                if (e.keyCode == 13) await addOrder()
              }}
            />
          </Grid>
        </FormContext>
      </CardContent>
      <CardActions>
        <ButtonSearch text='Adicionar' onClick={async () => await addOrder()} />
      </CardActions>
    </Card>
  )
}

export default Component
