import React, { useEffect, useState } from 'react'
import { Card, CardContent, Grid, TableCell, TableRow } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { DataTable, AddButton, DeleteButton } from 'components/data-table'
import { PartnerAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'
import { Cell, ConfirmDialog } from 'components/core'
import { handleSubmit } from 'components/form'

import { handleRemove, handleSearch, handleSave } from './actions'
import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  clientCoverageId: string
}

const Component: React.FC<ComponentProps> = ({ clientCoverageId }) => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [deleteId, setDeleteId] = useState<string>()
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)

  useEffect(() => {
    if (clientCoverageId) setFilter({ id: clientCoverageId })

    return () => {}
  }, [clientCoverageId])

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <FormContext {...form}>
            <Grid>
              <PartnerAutoComplete name='partner' label='Parceiro *' desktopSize={6} tabletSize={7} />
              <Cell desktopSize={2} tabletSize={1}>
                <br />
                <AddButton
                  id='add-partner-link'
                  tooltip='Adicionar Vínculo'
                  action={async () =>
                    await handleSubmit(form, validationSchema, async () => {
                      let res = await handleSave({ clientCoverageId: clientCoverageId, ...form.getValues() })
                      if (res?.success) {
                        setToogleUpdate(!toogleUpdate)
                        form.reset(defaultValues, { dirty: false })
                      }
                    })
                  }
                />
              </Cell>
            </Grid>
          </FormContext>
        </CardContent>
      </Card>

      <div style={{ marginTop: 10 }}>
        <DataTable
          filter={filter}
          header={[{ label: 'Parceiro' }, { label: '' }]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          update={toogleUpdate}
          total={result ? result.total : 0}
          showEmptyMessageAlert={false}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell grow>{data.partnerName}</TableCell>
                <TableCell>
                  <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                </TableCell>
              </TableRow>
            ))}
        </DataTable>
      </div>

      <ConfirmDialog
        text='Deseja realmente excluir o vínculo com o parceiro? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          setDeleteId(undefined)
          if (deleteId) if (await handleRemove(clientCoverageId, deleteId)) setToogleUpdate(!toogleUpdate)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
