import Yup from 'utils/yup'

export const defaultValues = {
  url: null,
  user: null,
  password: null,
}

export const validationSchema = Yup.object().shape({
  url: Yup.string().nullable().required(),
  user: Yup.string().nullable().required(),
  password: Yup.string().nullable().required(),
})
