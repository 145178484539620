import React, { useEffect, useState } from 'react'
import { Card, CardContent, Chip, FontIcon, Grid, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { getPickupRequestStatusDescription } from 'types/pickup/pickup-request-status'
import { CarrierAutoComplete } from 'components/auto-complete'
import { formatCepMask } from 'components/address/types'
import { Callout, Cell, Modal } from 'components/core'
import { CalloutType } from 'components/core/callout'
import { useAuth } from 'contexts/auth/auth-context'
import { handleSubmit } from 'components/form'
import { Theme } from 'contexts/auth/types'

import { defaultValues, validationSchema } from './config'
import { handleList, handleSave } from './actions'

interface ComponentProps {
  packingListId: string
  disableModal: () => void
  loadRequest: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ packingListId, disableModal, loadRequest }) => {
  const form = useForm({ defaultValues })
  const [addresses, setAddresses] = useState<Array<any>>([])
  const { theme } = useAuth()

  useEffect(() => {
    const search = async () => {
      if (packingListId) {
        let res = await handleList(packingListId)
        if (res) {
          form.reset({ carrier: res.carrier }, { dirty: false })

          let orderedAddresses = res.addresses.filter((x: any) => x.sequence > 0).sort((a: any, b: any) => a.sequence - b.sequence)

          orderedAddresses.push(...res.addresses.filter((x: any) => !x.sequence))

          setAddresses(orderedAddresses)
        }
      }
    }

    search()

    return () => {}
  }, [packingListId])

  const UpItem = (itemId: string) => {
    let sequencedAddresses: Array<any> = [...addresses]
    let currentItemIndex = sequencedAddresses.findIndex(x => x.itemId == itemId)
    let currentItem = sequencedAddresses[currentItemIndex]

    if (currentItem.sequence === 1) return

    if (currentItem.sequence > 0) {
      sequencedAddresses[currentItemIndex].sequence -= 1
      sequencedAddresses[currentItemIndex - 1].sequence += 1
    } else {
      let lastSequence = sequencedAddresses
        .filter(x => x.sequence > 0)
        .sort((a: any, b: any) => a.sequence - b.sequence)
        .pop()

      if (!lastSequence) sequencedAddresses[currentItemIndex].sequence = 1
      else sequencedAddresses[currentItemIndex].sequence = lastSequence.sequence + 1
    }

    let orderedAddresses = sequencedAddresses.filter((x: any) => x.sequence > 0).sort((a: any, b: any) => a.sequence - b.sequence)

    orderedAddresses.push(...sequencedAddresses.filter((x: any) => !x.sequence))

    setAddresses(() => orderedAddresses)
  }

  const DownItem = (itemId: string) => {
    let sequencedAddresses: Array<any> = [...addresses]
    let currentItemIndex = sequencedAddresses.findIndex(x => x.itemId == itemId)
    let currentItem = sequencedAddresses[currentItemIndex]

    if (currentItem.sequence === sequencedAddresses.length) return

    if (currentItem.sequence > 0) {
      if (sequencedAddresses[currentItemIndex + 1].sequence > 0) {
        sequencedAddresses[currentItemIndex].sequence += 1
        sequencedAddresses[currentItemIndex + 1].sequence -= 1
      }
    } else {
      let lastSequence = sequencedAddresses
        .filter(x => x.sequence > 0)
        .sort((a: any, b: any) => a.sequence - b.sequence)
        .pop()

      if (!lastSequence) sequencedAddresses[currentItemIndex].sequence = 1
      else sequencedAddresses[currentItemIndex].sequence = lastSequence.sequence + 1
    }

    let orderedAddresses = sequencedAddresses.filter((x: any) => x.sequence > 0).sort((a: any, b: any) => a.sequence - b.sequence)

    orderedAddresses.push(...sequencedAddresses.filter((x: any) => !x.sequence))

    setAddresses(() => orderedAddresses)
  }

  const getColor = () => {
    return theme === Theme.Dark ? { backgroundColor: '#b71c1c' } : { backgroundColor: '#ef9a9a' }
  }

  return (
    <Modal
      title={'Roteirizar Romaneio'}
      visible={true}
      width={1000}
      confirmTitle={'Salvar'}
      confirmAction={async () =>
        await handleSubmit(form, validationSchema, async () => {
          if (
            await handleSave({
              packingListId: packingListId,
              ...form.getValues(),
              itens: addresses.map((x: any) => {
                return { packingListItemId: x.itemId, sequence: x.sequence }
              }),
            })
          ) {
            form.reset(defaultValues, { dirty: false })
            disableModal()
            loadRequest()
          }
        })
      }
      closeTitle={'Fechar'}
      closeAction={async () => disableModal()}>
      <FormContext {...form}>
        <Grid>
          <CarrierAutoComplete name='carrier' label='Portador *' />
        </Grid>
      </FormContext>
      <Grid>
        <Cell>
          {addresses?.length === 0 && <Callout text={'Nenhuma solicitação de coleta localizada'} type={CalloutType.Info} />}
          {addresses && addresses.length > 0 && (
            <Card fullWidth>
              <CardContent>
                <TableContainer>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Cep</TableCell>
                        <TableCell>Horário de Coleta</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Ordem</TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {addresses.map((addressData: any, index: number) => (
                        <TableRow key={index} style={addressData.sequence ? {} : getColor()}>
                          <TableCell>{addressData.clientFantasyName}</TableCell>
                          <TableCell>{formatCepMask(addressData.zipCode)}</TableCell>
                          <TableCell>
                            {addressData.pickupStartTime.substring(0, 5)} - {addressData.pickupFinalTime.substring(0, 5)}
                          </TableCell>
                          <TableCell>{getPickupRequestStatusDescription(addressData.status)}</TableCell>
                          <TableCell>
                            <Chip
                              leftIcon={<FontIcon onClick={() => UpItem(addressData.itemId)}>expand_less</FontIcon>}
                              rightIcon={<FontIcon onClick={() => DownItem(addressData.itemId)}>expand_more</FontIcon>}
                              theme='outline'>
                              {addressData.sequence ?? '-'}
                            </Chip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
        </Cell>
      </Grid>
    </Modal>
  )
}

export default Component
