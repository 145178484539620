import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, FontIcon, useToggle, TabsManager, Tabs, TabPanels, TabPanel, ListItem } from 'react-md'

import { Cell, PageTitle, ButtonLog, ButtonSuccess, ButtonEdit, Actions } from 'components/core'
import { handleSubmit, Input } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleDownloadCsv, handleGet, handleSave } from './actions'

import DeadlineUpdate from './components/deadline-update'
import PartnerLink from './components/partner-link'
import Migrate from './components/migrate'
import Import from './components/import'
import Client from './components/client'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [loadPartnerLinks, setLoadPartnerLinks] = useState<boolean>(false)
  const [loadClients, setLoadClients] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleCoverageModal, enableCoverageModal, disableCoverageModal] = useToggle(false)
  const [visibleMigrateModal, enableMigrateModal, disableMigrateModal] = useToggle(false)
  const [visibleDeadlineUpdateModal, enableDeadlineUpdateModal, disableDeadlineUpdateModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Abrangência'

    const load = async () => {
      const res = await handleGet(id)
      if (res) form.reset(res)
    }

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadPartnerLinks(true)
    else if (activeTab === 2) setLoadClients(true)

    return () => {}
  }, [activeTab])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Abrangência' />
          <TabsManager onActiveIndexChange={val => setActiveTab(val)} tabs={['Abrangência', 'Vínculos', 'Clientes']} tabsId='coverage-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <Card fullWidth>
                  <CardContent>
                    <FormContext {...form}>
                      <Grid>
                        <Input name='name' label='Nome *' />
                      </Grid>
                    </FormContext>
                  </CardContent>
                  <CardActions>
                    <ButtonSuccess
                      text='Salvar'
                      icon={<FontIcon>done</FontIcon>}
                      disabled={!form.formState.dirty}
                      onClick={async () =>
                        await handleSubmit(form, validationSchema, async () => {
                          if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                        })
                      }
                    />
                    <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                    <ButtonLog entity='ClientCoverage' id={id} />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel>{loadPartnerLinks && <PartnerLink clientCoverageId={id} />}</TabPanel>
              <TabPanel>{loadClients && <Client clientCoverageId={id} />}</TabPanel>
            </TabPanels>
          </TabsManager>
        </Cell>
      </Grid>

      <Import clientCoverageId={id} visibleModal={visibleCoverageModal} disableModal={disableCoverageModal} />

      <Migrate clientCoverageId={id} visibleModal={visibleMigrateModal} disableModal={disableMigrateModal} />

      <DeadlineUpdate clientCoverageId={id} visibleModal={visibleDeadlineUpdateModal} disableModal={disableDeadlineUpdateModal} />

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>upload</FontIcon>} onClick={async () => enableCoverageModal()}>
          Importar
        </ListItem>
        <ListItem leftAddon={<FontIcon>sync</FontIcon>} onClick={async () => enableMigrateModal()}>
          Migrar Clientes
        </ListItem>
        <ListItem leftAddon={<FontIcon>edit_calendar</FontIcon>} onClick={async () => enableDeadlineUpdateModal()}>
          Atualizar Prazos
        </ListItem>
        <ListItem leftAddon={<FontIcon>download</FontIcon>} onClick={async () => handleDownloadCsv({ clientCoverageId: id }, 'Abrangência.csv')}>
          Download
        </ListItem>
      </Actions>
    </>
  )
}

export default Component
