import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid } from '@react-md/utils'
import { Card, CardContent, TabPanel, TabPanels, TabsManager } from 'react-md'

import { Cell, PageTitle } from 'components/core'
import { addressDefaultValues, addressValidationSchema } from 'components/address/config'

import { handleGet, handleSave, handleValidateForms } from './actions'
import { issuerDefaultValues, issuerValidationSchema } from './components/general-information/issuer/config'
import { bankDataDefaultValues, bankDataValidationSchema } from './components/bank-data/config'
import { takerDefaultValues, takerValidationSchema } from './components/billing/taker/config'
import { otherRulesDefaultValues, otherRulesValidationSchema } from './components/other-rules/config'

import Issuer from './components/general-information/issuer'
import Address from './components/general-information/address'
import PickupAddress from './components/pickup-address'
import BankData from './components/bank-data'
import Taker from './components/billing/taker'
import TakerAddress from './components/billing/address'
import Integrations from './components/integrations'
import ContractedServices from './components/contracted-services'
import OtherRules from './components/other-rules'
import Contacts from './components/contacts'
import ClientMenu from './components/client-menu'
import SaveButton from './components/save-button'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const issuerForm = useForm({ defaultValues: issuerDefaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })
  const bankDataForm = useForm({ defaultValues: bankDataDefaultValues })
  const takerForm = useForm({ defaultValues: takerDefaultValues })
  const takerAddressForm = useForm({ defaultValues: addressDefaultValues })
  const otherRulesForm = useForm({ defaultValues: otherRulesDefaultValues })
  const [loadPickupAddresses, setLoadPickupAddresses] = useState<boolean>(false)
  const [loadIntegrations, setLoadIntegrations] = useState<boolean>(false)
  const [loadContractedServices, setLoadContractedServices] = useState<boolean>(false)
  const [loadContacts, setLoadContacts] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)

  useEffect(() => {
    document.title = 'Edição de Cliente'

    const load = async () => {
      const res = await handleGet(id)
      if (res) {
        issuerForm.reset(res)
        addressForm.reset(res.address)
        bankDataForm.reset(res.bankData)
        takerForm.reset(res.invoicing)
        takerAddressForm.reset(res.invoicing.takerAddress)
        otherRulesForm.reset(res.otherRules)
      }
    }

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 3) setLoadPickupAddresses(true)
    else if (activeTab === 4) setLoadIntegrations(true)
    else if (activeTab === 5) setLoadContractedServices(true)
    else if (activeTab === 7) setLoadContacts(true)

    return () => {}
  }, [activeTab])

  const formIsDirty = (): boolean => {
    return (
      !issuerForm.formState.dirty &&
      !addressForm.formState.dirty &&
      !bankDataForm.formState.dirty &&
      !takerForm.formState.dirty &&
      !takerAddressForm.formState.dirty &&
      !otherRulesForm.formState.dirty
    )
  }

  const formsIsValid = async (): Promise<boolean> => {
    return await handleValidateForms(
      { form: issuerForm, validationSchema: issuerValidationSchema },
      { form: addressForm, validationSchema: addressValidationSchema },
      { form: bankDataForm, validationSchema: bankDataValidationSchema },
      { form: takerForm, validationSchema: takerValidationSchema },
      { form: takerAddressForm, validationSchema: addressValidationSchema },
      { form: otherRulesForm, validationSchema: otherRulesValidationSchema },
    )
  }

  const getClientObj = () => {
    return {
      id,
      ...issuerForm.getValues(),
      address: addressForm.getValues(),
      bankData: bankDataForm.getValues(),
      invoicing: {
        ...takerForm.getValues(),
        takerAddress: takerAddressForm.getValues(),
      },
      otherRules: otherRulesForm.getValues(),
    }
  }

  const resetForms = () => {
    issuerForm.reset(issuerForm.getValues(), { dirty: false })
    addressForm.reset(addressForm.getValues(), { dirty: false })
    bankDataForm.reset(bankDataForm.getValues(), { dirty: false })
    takerForm.reset(takerForm.getValues(), { dirty: false })
    takerAddressForm.reset(takerAddressForm.getValues(), { dirty: false })
    otherRulesForm.reset(otherRulesForm.getValues(), { dirty: false })
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição Cliente' />
      </Cell>
      <Cell>
        <div
          style={{
            display: 'flex',
            width: '100',
          }}>
          <Card style={{ marginRight: 10 }}>
            <CardContent>
              <ClientMenu setTabIndex={setActiveTab} />
            </CardContent>
          </Card>
          <Card fullWidth>
            <CardContent>
              <TabsManager activeIndex={activeTab} tabs={[]} onActiveIndexChange={() => {}} tabsId={'tab'}>
                <TabPanels persistent={true}>
                  <TabPanel>
                    <SaveButton
                      id={id}
                      formIsDirty={formIsDirty}
                      formsIsValid={formsIsValid}
                      resetForms={resetForms}
                      getClientObj={getClientObj}
                      handleSave={handleSave}>
                      <Issuer form={issuerForm} />
                      <Address form={addressForm} />
                    </SaveButton>
                  </TabPanel>
                  <TabPanel>
                    <SaveButton
                      id={id}
                      formIsDirty={formIsDirty}
                      formsIsValid={formsIsValid}
                      resetForms={resetForms}
                      getClientObj={getClientObj}
                      handleSave={handleSave}>
                      <BankData form={bankDataForm} />
                    </SaveButton>
                  </TabPanel>
                  <TabPanel>
                    <SaveButton
                      id={id}
                      formIsDirty={formIsDirty}
                      formsIsValid={formsIsValid}
                      resetForms={resetForms}
                      getClientObj={getClientObj}
                      handleSave={handleSave}>
                      <Taker form={takerForm} />
                      <TakerAddress form={takerAddressForm} />
                    </SaveButton>
                  </TabPanel>
                  <TabPanel>{loadPickupAddresses && <PickupAddress clientId={id} />}</TabPanel>
                  <TabPanel>{loadIntegrations && <Integrations clientId={id} />}</TabPanel>
                  <TabPanel>{loadContractedServices && <ContractedServices clientId={id} />}</TabPanel>
                  <TabPanel>
                    <SaveButton
                      id={id}
                      formIsDirty={formIsDirty}
                      formsIsValid={formsIsValid}
                      resetForms={resetForms}
                      getClientObj={getClientObj}
                      handleSave={handleSave}>
                      <OtherRules form={otherRulesForm} />
                    </SaveButton>
                  </TabPanel>
                  <TabPanel>{loadContacts && <Contacts clientId={id} />}</TabPanel>
                </TabPanels>
              </TabsManager>
            </CardContent>
          </Card>
        </div>
      </Cell>
    </Grid>
  )
}

export default Component
