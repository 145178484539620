import Yup from 'utils/yup'

export const defaultValues = {
  coverage: {
    id: null,
  },
  zipCode: null,
}

export const validationSchema = Yup.object().shape({
  coverage: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  zipCode: Yup.number().nullable(),
})
