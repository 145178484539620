import { useEffect } from 'react'
import { TextField, FormMessage } from 'react-md'
import { useFormContext } from 'react-hook-form'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

export const InputNumber = ({ name, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const form = useFormContext()
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })

    return () => {}
  }, [form.register])

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled}
        value={formValue ? String(formValue) : ''}
        onChange={event => {
          if (form.errors[name]) form.clearError(name)
          if (event.target.value) form.setValue(name, parseInt(event.target.value.replace(/\D/g, '')))
          else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
