import React from 'react'
import { Button, ButtonThemeType, FontIcon, TextIconSpacing } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import { useAuth } from 'contexts/auth/auth-context'
import { Theme } from 'contexts/auth/types'

interface Props {
  text?: string
  type?: ButtonThemeType
  disabled?: boolean
  onClick?: () => Promise<void>
  style?: React.CSSProperties
  [x: string]: any
}

const ButtonClearSearch: React.FC<Props> = ({ text, type, disabled, onClick, style, ...rest }) => {
  const { promiseInProgress } = usePromiseTracker()
  const { theme } = useAuth()

  return (
    <Button
      themeType={type ?? 'contained'}
      theme={disabled || promiseInProgress ? 'disabled' : 'clear'}
      disabled={disabled || promiseInProgress}
      onClick={() => (onClick ? onClick() : {})}
      style={
        disabled || promiseInProgress
          ? { ...style }
          : theme === Theme.Dark
          ? { ...style, margin: 2, backgroundColor: '#616161', color: '#FFFFFF' }
          : { ...style, margin: 2, backgroundColor: '#e0e0e0', color: '#212121' }
      }
      {...rest}>
      <TextIconSpacing icon={<FontIcon>search_off</FontIcon>}>{text ?? 'Limpar Filtros'}</TextIconSpacing>
    </Button>
  )
}

export default ButtonClearSearch
