import { useAuth } from 'contexts/auth/auth-context'
import React, { useEffect, useState, createContext } from 'react'
import { onSnapshot, collection, getFirestore, QuerySnapshot, DocumentData, Unsubscribe, query, where, Timestamp } from 'firebase/firestore'
import { Auth, getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { addHours, format } from 'date-fns'

import { firebaseApp, firebaseUser } from 'utils/firebase'

import { NotificationContextData } from './types'

const NotificationContext = createContext({} as NotificationContextData)

export const NotificationProvider: React.FC = ({ children }) => {
  const { user } = useAuth()
  const [count, setCount] = useState<number | null>(null)
  const [notifications, setNotifications] = useState<Array<any> | null>(null)
  const db = getFirestore(firebaseApp)

  useEffect(() => {
    let unsubscribe: Unsubscribe | null

    const auth: Auth = getAuth()

    const load = async () => {
      try {
        if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
          await signInWithEmailAndPassword(auth, firebaseUser.email, firebaseUser.password)
          if (user?.id) {
            let documentsQuery = query(collection(db, 'user', user?.id, 'notification'), where('ExpiresAt', '>=', Timestamp.now()))

            unsubscribe = onSnapshot(
              documentsQuery,
              (documents: QuerySnapshot<DocumentData>) => {
                let notifications: Array<any> = []
                documents.forEach(document => {
                  let notification = document.data()
                  notifications.push({
                    date: format(addHours(new Date(1970, 0, 1, 0, 0, notification.Date.seconds), -3), "dd'/'MM'/'yyyy' 'HH:mm:ss"),
                    message: notification.Message,
                  })
                })
                setNotifications(notifications)
                setCount(notifications.length)
              },
              err => console.log('onSnapshot', err),
            )
          }
        } else {
          console.info('Firebase desativado no ambiente local')
        }
      } catch (err) {
        console.log('auth', err)
      }
    }

    load()

    return () => {
      if (unsubscribe) unsubscribe()
      signOut(auth)
        .then()
        .catch(err => console.log('signOut', err))
    }
  }, [user?.id])

  return <NotificationContext.Provider value={{ count, notifications }}>{children}</NotificationContext.Provider>
}

export const useNotification = () => React.useContext(NotificationContext)
