export const getFiscalDocumentDescription = (status: string): string => {
  return FiscalDocumentOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface FiscalDocumentList {
  value: FiscalDocumentEnum | null
  label: string
}

export enum FiscalDocumentEnum {
  Declaration = 'Declaration',
  FiscalNote = 'FiscalNote',
}

export const FiscalDocumentOptions: Array<FiscalDocumentList> = [
  { value: null, label: '' },
  { value: FiscalDocumentEnum.Declaration, label: 'Declaração' },
  { value: FiscalDocumentEnum.FiscalNote, label: 'Nota Fiscal' },
]
