import React, { useEffect, useState } from 'react'
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow } from 'react-md'

import { DeleteButton, EditButton } from 'components/data-table'
import { Callout, ConfirmDialog } from 'components/core'
import { getStatusDescription } from 'types/order/status'
import { CalloutType } from 'components/core/callout'
import { EmptyResultMessage } from 'types/core/api'

import { handleGetOrders, handleRemoveOrder } from './actions'

import AddOrder from './components/add-order'

interface ComponentProps {
  packingListId: string
}

const Component: React.FC<ComponentProps> = ({ packingListId }) => {
  const [result, setResult] = useState<any>()
  const [deleteId, setDeleteId] = useState<string>()

  useEffect(() => {
    if (packingListId) search(packingListId)

    return () => {}
  }, [packingListId])

  const search = async (packingListId: string) => setResult(await handleGetOrders(packingListId))

  return (
    <>
      <AddOrder packingListId={packingListId} search={search} />
      <br />
      {result && result.length > 0 ? (
        <Card fullWidth>
          <CardContent>
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Núm. Pedido</TableCell>
                    <TableCell>Núm. NF</TableCell>
                    <TableCell>Destinatário</TableCell>
                    <TableCell>Tracking</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result?.map((data: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{data.status ? getStatusDescription(data.status) : '-'}</TableCell>
                      <TableCell>{data.orderNumber}</TableCell>
                      <TableCell>{data.fiscalNoteNumber}</TableCell>
                      <TableCell>{data.recipientName}</TableCell>
                      <TableCell>{data.trackingCode}</TableCell>
                      <TableCell align='right'>
                        <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.itemId)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      ) : (
        <Callout text={EmptyResultMessage} type={CalloutType.Info} />
      )}

      <ConfirmDialog
        text='Deseja realmente excluir o pedido do romaneio de entrega? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          setDeleteId(undefined)
          if (deleteId) if (await handleRemoveOrder(packingListId, deleteId)) await search(packingListId)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
