import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getQuoteFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.quote.clientCoverage || permissions?.quote.gris || permissions?.quote.icms || permissions?.quote.clientFare) {
    menu['/quote'] = getMenu('/quote', null, 'Cotação', 'paid', null)

    if (permissions?.quote.clientCoverage) {
      menu['/quote/client-coverage'] = getMenu('/quote/client-coverage', '/quote', 'Abrangência', 'travel_explore', null)
      menu['/quote/client-coverage/new'] = getMenu(
        '/quote/client-coverage/new',
        '/quote/client-coverage',
        'Nova',
        'add',
        '/quote/client-coverage/new',
      )
      menu['/quote/client-coverage/search'] = getMenu(
        '/quote/client-coverage/search',
        '/quote/client-coverage',
        'Pesquisa',
        'search',
        '/quote/client-coverage/search',
      )
      menu['/quote/client-coverage/search-coverage'] = getMenu(
        '/quote/client-coverage/search-coverage',
        '/quote/client-coverage',
        'Pesquisa Detalhada',
        'search',
        '/quote/client-coverage/search-coverage',
      )
    }

    if (permissions?.quote.gris) {
      menu['/quote/gris'] = getMenu('/quote/gris', '/quote', 'Gris', 'request_quote', null)
      menu['/quote/gris/new'] = getMenu('/quote/gris/new', '/quote/gris', 'Novo', 'add', '/quote/gris/new')
      menu['/quote/gris/search'] = getMenu('/quote/gris/search', '/quote/gris', 'Pesquisa', 'search', '/quote/gris/search')
    }

    if (permissions?.quote.icms) {
      menu['/quote/icms'] = getMenu('/quote/icms', '/quote', 'Icms', 'calculate', '/quote/icms')
    }

    if (permissions?.quote.iss) {
      menu['/quote/iss'] = getMenu('/quote/iss', '/quote', 'Iss', 'calculate', null)
      menu['/quote/iss/import'] = getMenu('/quote/iss/import', '/quote/iss', 'Importar', 'add', '/quote/iss/import')
      menu['/quote/iss/search'] = getMenu('/quote/iss/search', '/quote/iss', 'Pesquisa', 'search', '/quote/iss/search')
    }

    if (permissions?.quote.quote.fm) {
      menu['/quote/fm'] = getMenu('/quote/fm', '/quote', 'Realizar Cotação', 'paid', '/quote/fm')
    }

    if (permissions?.quote.clientFare) {
      menu['/quote/client-fare'] = getMenu('/quote/client-fare', '/quote', 'Tarifa', 'request_quote', null)
      menu['/quote/client-fare/new'] = getMenu('/quote/client-fare/new', '/quote/client-fare', 'Nova', 'add', '/quote/client-fare/new')
      menu['/quote/client-fare/search'] = getMenu(
        '/quote/client-fare/search',
        '/quote/client-fare',
        'Pesquisa',
        'search',
        '/quote/client-fare/search',
      )
      menu['/quote/client-fare/search-fare'] = getMenu(
        '/quote/client-fare/search-fare',
        '/quote/client-fare',
        'Pesquisa Detalhada',
        'search',
        '/quote/client-fare/search-fare',
      )
    }
  }
}
