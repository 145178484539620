import Yup from 'utils/yup'

export const defaultValues = {
  zipCode: null,
  city: null,
}

export const validationSchema = Yup.object().shape({
  zipCode: Yup.number().nullable(),
  city: Yup.string().nullable().max(60, 'O nome deve ter no máximo 60 caracteres'),
})
