import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow, Text } from 'react-md'
import { format, parseISO } from 'date-fns'

import { handleSubmit, Input, InputDocument, Select } from 'components/form'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { DataTable, EditButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'
import { getStatusDescription } from 'types/order/status'

import { SearchClientFieldEnum, SearchClientFieldOptions } from './types'
import { defaultValues, validationSchema } from './config'
import { handleEdit, handleSearch } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const field = form.watch('field')

  useEffect(() => {
    document.title = 'Pesquisa de Pedido'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pesquisa de Pedido' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Select name='field' label='Filtro' options={SearchClientFieldOptions} desktopSize={2} tabletSize={4} />
                {field == SearchClientFieldEnum.RecipientName && <Input name='recipientName' label='Destinatário' desktopSize={3} tabletSize={4} />}
                {field == SearchClientFieldEnum.RecipientDocument && (
                  <InputDocument name='recipientDocument' label='Cpf/Cnpj' maxLength={18} desktopSize={3} tabletSize={4} />
                )}
                {field == SearchClientFieldEnum.OrderNumber && <Input name='orderNumber' label='Núm. Pedido' desktopSize={3} tabletSize={4} />}
                {field == SearchClientFieldEnum.FiscalNoteNumber && <Input name='fiscalNoteNumber' label='Núm. NF' desktopSize={3} tabletSize={4} />}
                {field == SearchClientFieldEnum.TrackingCode && <Input name='trackingCode' label='Tracking' desktopSize={3} tabletSize={4} />}
                <Cell>
                  <Text type='caption' component='h1'>
                    <i>Pesquisa limitada aos últimos 12 meses</i>
                  </Text>
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => setFilter(form.getValues()))} />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <DataTable
          filter={filter}
          header={[
            { label: 'Data de Cadastro' },
            { label: 'Remetente' },
            { label: 'Destinatário' },
            { label: 'Núm. Pedido' },
            { label: 'Núm. NF' },
            { label: 'Tracking' },
            { label: 'Data Status' },
            { label: 'Status' },
            { label: '' },
          ]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell>{format(parseISO(data.createdAt), "dd'/'MM'/'yyyy")}</TableCell>
                <TableCell>{data.clientFantasyName}</TableCell>
                <TableCell>{data.recipientName}</TableCell>
                <TableCell>{data.orderNumber}</TableCell>
                <TableCell>{data.fiscalNoteNumber}</TableCell>
                <TableCell>{data.trackingCode}</TableCell>
                <TableCell>{format(parseISO(data.lastTrackingDate), "dd'/'MM'/'yyyy' 'HH:mm:ss")}</TableCell>
                <TableCell>{getStatusDescription(data.lastTrackingStatus)}</TableCell>
                <TableCell align='right'>
                  <EditButton id={`edit-${index}`} action={() => handleEdit(data.volumeId)} />
                </TableCell>
              </TableRow>
            ))}
        </DataTable>
      </Cell>
    </Grid>
  )
}

export default Component
