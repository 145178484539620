import Yup from 'utils/yup'

export const defaultValues = {
  clientCoverage: {
    id: null,
  },
  clientFare: {
    id: null,
  },
}

export const validationSchema = Yup.object().shape({
  clientCoverage: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  clientFare: Yup.object({
    id: Yup.string().nullable().required(),
  }),
})
