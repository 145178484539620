import { toast } from 'react-toastify'

import { handleValidate } from 'components/form/form-submit'
import { ValidationMessage } from 'types/core/api'
import { FormList } from 'types/core/form'
import api from 'services/sale/client'

export const handleValidateForms = async (
  issuerForm: FormList,
  addressForm: FormList,
  bankDataForm: FormList,
  takerForm: FormList,
  takerAddressForm: FormList,
  otherRulesForm: FormList,
): Promise<boolean> => {
  let success = true

  if (!(await handleValidate(issuerForm.form, issuerForm.validationSchema))) success = false
  if (!(await handleValidate(addressForm.form, addressForm.validationSchema))) success = false
  if (!(await handleValidate(bankDataForm.form, bankDataForm.validationSchema))) success = false
  if (!(await handleValidate(takerForm.form, takerForm.validationSchema))) success = false
  if (!(await handleValidate(takerAddressForm.form, takerAddressForm.validationSchema))) success = false
  if (!(await handleValidate(otherRulesForm.form, otherRulesForm.validationSchema))) success = false

  if (!success) toast.warn(ValidationMessage)

  return success
}

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}
