import api from 'services/quote/client-fare'

import downloadFile from 'utils/download-file'

export const handleImport = async (clientFareId: string, fileName: string, file: any): Promise<Array<string> | null> => {
  let res = await api.uploadFare({ clientFareId, fileName, file })

  return res?.data?.success
}

export const handleGetFareCsv = async (fileName: string) => {
  let res = await api.getCsv()

  downloadFile(fileName, res?.data)
}
