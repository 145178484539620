import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getExpeditionFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.expedition?.cubageManual || permissions?.expedition.validateCubage) {
    menu['/expedition'] = getMenu('/expedition', null, 'Expedição', 'trolley', null)

    if (permissions?.expedition?.cubageManual || permissions?.expedition.validateCubage) {
      menu['/expedition/order/cubage'] = getMenu('/expedition/order/cubage', '/expedition', 'Cubagem', 'square_foot', null)

      if (permissions?.expedition.cubageManual)
        menu['/expedition/cubage/manual'] = getMenu(
          '/expedition/cubage/manual',
          '/expedition/order/cubage',
          'Manual',
          'pinch',
          '/expedition/cubage/manual',
        )

      if (permissions?.expedition.validateCubage)
        menu['/expedition/cubage/validate'] = getMenu(
          '/expedition/cubage/validate',
          '/expedition/order/cubage',
          'Validação',
          'price_check',
          '/expedition/cubage/validate',
        )
    }
  }
}
