import api from 'services/pickup/pickup-request'

export const handleSearch = async (request: any) => {
  let res = await api.searchFm(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/pickup/pickup-request/${id}/edit`)
}

export const handleDelete = async (id: string) => {
  let res = await api.delete(id)

  return res?.data?.success
}
