import React, { useEffect, useState } from 'react'
import { Card, CardContent, TableContainer, Table, TableBody } from 'react-md'
import { toast } from 'react-toastify'

import { CalloutType } from 'components/core/callout'
import { ApiResponse } from 'types/core/api'
import { Callout } from 'components/core'

import { DataTableOrder, DataTableResult, DataTableRequest, Header } from './types'

import DataTablePagination from './components/data-table-pagination'
import DataTableHeader from './components/data-table-header'

interface Props {
  filter?: any
  header: Array<Header>
  search: any
  setResult: any
  result: DataTableResult
  update?: boolean
  total: number
  emptyMessage?: string
  showEmptyMessageAlert?: boolean
  wrapEmptyMessageWithCard?: boolean
  wrapTableWithCard?: boolean
  children: any
}

interface wrapperCardProps {
  wrapCard?: boolean
  children: any
}

const WrapperCardComponent: React.FC<wrapperCardProps> = ({ wrapCard, children }) => {
  return wrapCard ? (
    <Card fullWidth>
      <CardContent>{children}</CardContent>
    </Card>
  ) : (
    <>{children}</>
  )
}

const DataTable: React.FC<Props> = ({
  filter,
  header,
  search,
  result,
  setResult,
  update,
  total,
  emptyMessage = 'Nenhuma informação localizada',
  showEmptyMessageAlert = true,
  wrapEmptyMessageWithCard = false,
  wrapTableWithCard = true,
  children,
}) => {
  const [request, setRequest] = useState<DataTableRequest>()
  const [initialState, setInitialState] = useState<boolean>(true)
  const [alertIsActive, setAlertIsActive] = useState<boolean>(false)

  useEffect(() => {
    if (filter) {
      let order: DataTableOrder | null = null
      if (header)
        header.forEach(item => {
          if (!order && item.order) order = { field: item.order, desc: false }
        })
      setRequest({
        filter: filter ? filter : {},
        order: order ?? { field: 'id', desc: false },
        pagination: { page: 0, length: 10 },
      })
    }

    return () => {}
  }, [filter])

  useEffect(() => {
    const load = async () => {
      if (filter && request) {
        const res = await search(request)
        setInitialState(false)
        setResult(res.data)
      }
    }

    load()

    return () => {}
  }, [request, update])

  useEffect(() => {
    if (showEmptyMessageAlert && !alertIsActive && result && result.total === 0 && !result.results) setAlertIsActive(true)

    return () => {}
  }, [result])

  useEffect(() => {
    if (alertIsActive)
      toast.info(emptyMessage, {
        onClose: () => setAlertIsActive(false),
      })

    return () => {}
  }, [alertIsActive])

  return (
    <>
      {!initialState && (
        <>
          {result && result.total === 0 && !result.results && (
            <WrapperCardComponent wrapCard={wrapEmptyMessageWithCard}>
              <Callout text={emptyMessage} type={CalloutType.Info} />
            </WrapperCardComponent>
          )}
          {result && result.total > 0 && (
            <WrapperCardComponent wrapCard={wrapTableWithCard}>
              <TableContainer>
                <Table fullWidth>
                  <DataTableHeader request={request} setRequest={setRequest} header={header} />
                  <TableBody>{children}</TableBody>
                </Table>
              </TableContainer>
              <DataTablePagination request={request} setRequest={setRequest} total={total} />
            </WrapperCardComponent>
          )}
        </>
      )}
    </>
  )
}

export default DataTable
