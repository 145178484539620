import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  document: null,
  latitude: null,
  longitude: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().max(60, 'O nome do recebedor deve ter no máximo 60 caracteres'),
  document: Yup.string().nullable().max(18, 'O documento do recebedor deve ter no máximo 18 caracteres'),
  latitude: Yup.string().nullable().max(20, 'A latitude de entrega deve ter no máximo 20 caracteres'),
  longitude: Yup.string().nullable().max(20, 'A longitude de entrega deve ter no máximo 20 caracteres'),
})
