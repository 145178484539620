import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { handleSubmit, Input, InputDatePicker, InputDocument, Select } from 'components/form'
import { PartnerIntegrationOptions } from 'types/order/partner-integration'
import { getStatusDescription, StatusOptions } from 'types/order/status'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { DataTable, EditButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'

import { defaultValues, validationSchema } from './config'
import { handleEdit, handleSearch } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Pedido'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pesquisa de Pedido' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Cliente' desktopSize={3} />
                <Input name='recipientName' label='Destinatário' desktopSize={3} tabletSize={4} />
                <InputDocument name='recipientDocument' label='Cpf/Cnpj' maxLength={18} desktopSize={2} tabletSize={4} />
                <Input name='orderNumber' label='Núm. Pedido' desktopSize={2} tabletSize={4} />
                <Input name='fiscalNoteNumber' label='Núm. NF' desktopSize={2} tabletSize={4} />
                <InputDatePicker name='initialCreationDate' label='Data de Criação Inicial' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalCreationDate' label='Data de Criação Final' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='initialReceiptDate' label='Data de Recebimento Inicial' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalReceiptDate' label='Data de Recebimento Final' desktopSize={3} tabletSize={4} />
                <Select name='status' label='Status' options={StatusOptions} desktopSize={3} tabletSize={4} />
                <Input name='trackingCode' label='Tracking' desktopSize={3} tabletSize={4} />
                <Select name='partner' label='Parceiro' options={PartnerIntegrationOptions} desktopSize={3} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => setFilter(form.getValues()))} />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <DataTable
          filter={filter}
          header={[
            { label: 'Data de Cadastro' },
            { label: 'Cliente' },
            { label: 'Destinatário' },
            { label: 'Núm. Pedido' },
            { label: 'Núm. NF' },
            { label: 'Tracking' },
            { label: 'Status' },
            { label: '' },
          ]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell>{format(parseISO(data.createdAt), "dd'/'MM'/'yyyy")}</TableCell>
                <TableCell>{data.clientFantasyName}</TableCell>
                <TableCell>{data.recipientName}</TableCell>
                <TableCell>{data.orderNumber}</TableCell>
                <TableCell>{data.fiscalNoteNumber}</TableCell>
                <TableCell>{data.trackingCode}</TableCell>
                <TableCell>{getStatusDescription(data.status)}</TableCell>
                <TableCell align='right'>
                  <EditButton id={`edit-${index}`} action={() => handleEdit(data.volumeId)} />
                </TableCell>
              </TableRow>
            ))}
        </DataTable>
      </Cell>
    </Grid>
  )
}

export default Component
