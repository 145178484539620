import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, Grid, Text, useToggle } from 'react-md'

import { ButtonSuccess, Cell, ConfirmDialog } from 'components/core'

import { handleListAddresses, handleRemoveAddress } from './actions'
import EditPickupAddress from './components/edit-address'
import AddPickupAddress from './components/add-address'
import List from './components/list'

interface ComponentProps {
  clientId: string
}

const Component: React.FC<ComponentProps> = ({ clientId }) => {
  const [address, setAddress] = useState<any | null>(null)
  const [addresses, setAddresses] = useState<Array<any> | null>(null)
  const [visibleAddModal, enableAddModal, disableAddModal] = useToggle(false)
  const [visibleEditModal, enableEditModal, disableEditModal] = useToggle(false)
  const [visibleDeleteModal, enableDeleteModal, disableDeleteModal] = useToggle(false)

  useEffect(() => {
    loadAddresses()

    return () => {}
  }, [clientId])

  const loadAddresses = async () => setAddresses(await handleListAddresses(clientId))

  const showEdit = (address: any) => {
    enableEditModal()
    setAddress(address)
  }

  const showDelete = (address: any) => {
    enableDeleteModal()
    setAddress(address)
  }

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <Grid>
            <Cell>
              <Text type='headline-6' margin='none'>
                Endereços de Coleta
              </Text>
            </Cell>
            <Cell>
              <List addresses={addresses} showEdit={showEdit} showDelete={showDelete} />
            </Cell>
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonSuccess text='Adicionar Endereço de Coleta' icon={<FontIcon>add</FontIcon>} onClick={async () => enableAddModal()} />
        </CardActions>
      </Card>

      <AddPickupAddress
        clientId={clientId}
        visibleModal={visibleAddModal}
        onHide={async (refresh: boolean) => {
          if (refresh) await loadAddresses()
          disableAddModal()
        }}
      />

      <EditPickupAddress
        address={address}
        clientId={clientId}
        visibleModal={visibleEditModal}
        onHide={async (refresh: boolean) => {
          if (refresh) await loadAddresses()
          setAddress(null)
          disableEditModal()
        }}
      />

      <ConfirmDialog
        text='Deseja realmente excluir o endereço? Essa ação não poderá ser desfeita'
        visible={visibleDeleteModal}
        confirmAction={async () => {
          if (address.id)
            if (await await handleRemoveAddress(clientId, address.id)) {
              setAddress(null)
              await loadAddresses()
              disableDeleteModal()
            }
        }}
        cancelAction={async () => {
          setAddress(null)
          disableDeleteModal()
        }}
      />
    </>
  )
}

export default Component
