import Yup from 'utils/yup'

export const defaultValues = {
  quantity: null,
  length: null,
  height: null,
  width: null,
  realWeight: null,
}

export const validationSchema = Yup.object().shape({
  quantity: Yup.number().nullable().required(),
  length: Yup.number().nullable().required(),
  height: Yup.number().nullable().required(),
  width: Yup.number().nullable().required(),
  realWeight: Yup.number().nullable().required(),
})
