import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import Audit from 'pages/audit/search'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return <>{menuPermission?.audit && <Route path='/audit/:filter' exact component={Audit} />}</>
}

export default Routes
