import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/client'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getContacts: (clientId: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${clientId}/contacts`)),
  autocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  addContact: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/contact`, request)),
  addContactDetail: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/contact/detail`, request)),
  addDepartmentToContactDetail: (request: any): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/contact/detail/department`, request)),
  addPickupAddress: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/pickup-address`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  updateContact: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/contact`, request)),
  updateContactDetail: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/contact/detail`, request)),
  updatePickupAddress: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/pickup-address`, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
  removeContact: (clientId: string, id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${clientId}/contact/${id}`)),
  removeContactDetail: (clientId: string, contactId: string, id: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${clientId}/contact/${contactId}/detail/${id}`)),
  removeDepartmentFromContactDetail: (clientId: string, contactId: string, detailId: string, id: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${clientId}/contact/${contactId}/detail/${detailId}/department/${id}`)),
  removePickupAddress: (clientId: string, id: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${clientId}/pickup-address/${id}`)),
}

export default Api
