import React from 'react'
import { FontIcon, Tooltipped, Tree, useTreeItemExpansion, useTreeItemSelection } from 'react-md'

interface ComponentProps {
  setTabIndex: (value: React.SetStateAction<number>) => void
}

const Component: React.FC<ComponentProps> = ({ setTabIndex }) => {
  const selection = useTreeItemSelection([], false)
  const expansion = useTreeItemExpansion([])

  return (
    <Tree
      id='client-menu-tree'
      data={{
        'general-info': {
          itemId: 'general-info',
          parentId: null,
          leftAddon: (
            <Tooltipped id={'general-info-tooltip'} tooltip={'Informações Gerais'} position={'right'}>
              <FontIcon onClick={() => setTabIndex(0)}>info</FontIcon>
            </Tooltipped>
          ),
        },
        'bank-data': {
          itemId: 'bank-data',
          parentId: null,
          leftAddon: (
            <Tooltipped id={'bank-data-tooltip'} tooltip={'Dados Bancários'} position={'right'}>
              <FontIcon onClick={() => setTabIndex(1)}>account_balance</FontIcon>
            </Tooltipped>
          ),
        },
        billing: {
          itemId: 'billing',
          parentId: null,
          leftAddon: (
            <Tooltipped id={'billing-tooltip'} tooltip={'Faturamento'} position={'right'}>
              <FontIcon onClick={() => setTabIndex(2)}>monetization_on</FontIcon>
            </Tooltipped>
          ),
        },
        'other-rules': {
          itemId: 'other-rules',
          parentId: null,
          leftAddon: (
            <Tooltipped id={'other-rules-tooltip'} tooltip={'Outras Regras'} position={'right'}>
              <FontIcon onClick={() => setTabIndex(3)}>rule</FontIcon>
            </Tooltipped>
          ),
        },
      }}
      aria-label='Tree'
      {...selection}
      {...expansion}
    />
  )
}

export default Component
