import React, { useEffect } from 'react'
import Bounce from 'react-reveal/Bounce'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardContent, FontIcon } from 'react-md'

import { history } from 'utils/history'
import { Cell, ButtonCustom, Callout } from 'components/core'
import { handleSubmit, Input } from 'components/form'
import Logo from 'components/core/logo'

import { defaultValues, validationSchema } from './config'
import { handleConfirmEmail } from './actions'
import { CalloutType } from 'components/core/callout'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Confirmação de E-mail'

    return () => {}
  }, [])

  return (
    <Bounce bottom>
      <Grid>
        <Cell tabletSize={6} tabletStart={2} desktopSize={4} desktopStart={5}>
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Logo />
                <Callout
                  text='Para concluir a confirmação de e-mail informe os campos abaixo e clique em confirmar e-mail, após a alteração realize o login'
                  type={CalloutType.Info}
                />
                <Input name='email' label='Email *' rightChildren={<FontIcon>person</FontIcon>} />
                <Input name='login' label='Usuário *' rightChildren={<FontIcon>account_box</FontIcon>} />
                <Input name='code' label='Código *' rightChildren={<FontIcon>qr_code</FontIcon>} />
                <ButtonCustom
                  text='Confirmar Email'
                  icon={<FontIcon>done</FontIcon>}
                  disabled={!form.formState.dirty}
                  whiteThemeBackgroundColor={'#4db6ac'}
                  darkThemeBackgroundColor={'#00695c'}
                  onClick={async () =>
                    await handleSubmit(form, validationSchema, async () => {
                      await handleConfirmEmail(form.getValues())
                      form.reset(defaultValues, { dirty: false })
                    })
                  }
                  style={{ width: '100%' }}
                />
                <ButtonCustom
                  text='Voltar'
                  icon={<FontIcon>arrow_back</FontIcon>}
                  whiteThemeBackgroundColor={'#e0e0e0'}
                  darkThemeBackgroundColor={'#424242'}
                  onClick={async () => history.push('/send-confirm-email')}
                  style={{ width: '100%' }}
                />
              </FormContext>
            </CardContent>
          </Card>
        </Cell>
      </Grid>
    </Bounce>
  )
}

export default Component
