import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, Chip, FontIcon, FormMessage, List, ListItem } from 'react-md'

import { DepartmentEnum, getDepartmentDescription } from 'types/sale/department'
import ButtonError from 'components/core/button-error'
import { CalloutType } from 'components/core/callout'
import { Callout } from 'components/core'

interface ComponentProps {
  contactList: Array<any>
  removeContact: (index: number) => void
}

const Component: React.FC<ComponentProps> = ({ contactList, removeContact }) => {
  const [contacts, setContacts] = useState<Array<any>>([])

  useEffect(() => {
    setContacts(contactList)

    return () => {}
  }, [contactList])

  return (
    <>
      {contacts.length === 0 && <Callout text={'Nenhum contato informado'} type={CalloutType.Info} />}
      {contacts.length > 0 &&
        contacts.map((data: any, index: number) => (
          <Card fullWidth key={index} style={{ marginBottom: 5 }}>
            <CardContent>
              <List dense>
                <ListItem leftAddon={<FontIcon>phone</FontIcon>}>Telefone 1: {data.phone1}</ListItem>
                <ListItem leftAddon={<FontIcon>phone</FontIcon>}>Telefone 2: {data.phone2}</ListItem>
                <ListItem leftAddon={<FontIcon>email</FontIcon>}>E-mail: {data.email}</ListItem>
                <ListItem>
                  {data.departments.map((departmentData: string, departmentNumber: number) => (
                    <Chip noninteractable theme='outline' key={departmentNumber} style={{ marginRight: 3 }}>
                      {getDepartmentDescription(departmentData)}
                    </Chip>
                  ))}
                </ListItem>
                <ListItem>
                  {data.departments.some((x: any) => x === DepartmentEnum.LogisticsAndShipping) && data.email && (
                    <FormMessage messageStyle={{ fontStyle: 'italic' }} id={'add-detail-logistics-and-shipping'}>
                      * Logística e Expedição: O e-mail informado receberá as notificações em caso de pedidos inválidos cadastrados na plataforma e
                      proximidade de coleta
                    </FormMessage>
                  )}
                </ListItem>
              </List>
            </CardContent>
            <CardActions>
              <ButtonError text='Remover' icon={<FontIcon>close</FontIcon>} onClick={async () => removeContact(index)} />
            </CardActions>
          </Card>
        ))}
    </>
  )
}

export default Component
