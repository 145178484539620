import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/client/integration'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  list: (clientId: string): Promise<any> => trackPromise<ApiResponse>(api.get(`/v1/client/${clientId}/integration`)),
  add: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  remove: (clientId: string, id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`/v1/client/${clientId}/integration/${id}`)),
}

export default Api
