import React from 'react'
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'react-md'

interface PanelInfoProps {
  data: any
  sectionName: string
}

const Component: React.FC<PanelInfoProps> = ({ data, sectionName }) => {
  return (
    <Card fullWidth style={{ marginBottom: 5 }}>
      <CardHeader>
        <Text type='subtitle-1' margin='none'>
          {sectionName}
        </Text>
      </CardHeader>
      <CardContent>
        {(!data || data.length === 0) && (
          <Table fullWidth dense>
            <TableBody>
              <TableRow>
                <TableCell>Nenhuma informação localizada</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {data && data.length > 0 && (
          <Table fullWidth dense>
            <TableHeader>
              <TableRow>
                <TableCell>Campo</TableCell>
                <TableCell>Valor</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((value: any, index: any) => (
                <TableRow key={index}>
                  <TableCell>{value.name}</TableCell>
                  <TableCell>{value.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}

export default Component
