import api from 'services/system/carrier'

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}
