import api from 'services/order/order-import-validation'

export const handleSearch = async (values: any) => {
  let res = await api.searchImportValidationFm(values.client.id)

  return res?.data?.data
}

export const handleDiscard = async (values: any) => {
  let res = await api.discardImportOrderFm(values)

  return res?.data?.success
}
