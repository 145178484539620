import React, { useEffect } from 'react'
import { Grid, Card, CardContent } from 'react-md'

import { Cell, PageTitle, Callout } from 'components/core'
import { CalloutType } from 'components/core/callout'
import { TextLabel } from 'components/label'

const Component: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const code = queryParams.get('code')

  useEffect(() => {
    document.title = 'Geração de Token - Integração Bling'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Geração de Token - Integração Bling' />
        <Card fullWidth>
          <CardContent>
            {code ? (
              <>
                <Callout
                  text='O código abaixo tem duração de 1 minuto, ele deve ser inserido no campo Token no cadastro de integração dentro do cadastro de cliente, após a validação a api Bling irá retornar um código com duração maior que é o que será gravado no cadastro do cliente, por esse motivo o código provisório será descartado.'
                  type={CalloutType.Info}
                />
                <Grid>
                  <TextLabel name='code' label='Código' value={code} desktopSize={6} />
                </Grid>
              </>
            ) : (
              <Callout text='Código inválido.' type={CalloutType.Error} />
            )}
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
