import React from 'react'
import { Grid } from 'react-md'

import { useAuth } from 'contexts/auth/auth-context'
import { Theme } from 'contexts/auth/types'

import LogoWhite from 'assets/logo-menu-white.png'
import Logo from 'assets/logo-menu.png'

import Cell from './cell'

const LogoMenu: React.FC = () => {
  const { theme } = useAuth()

  return (
    <Grid>
      <Cell>
        <img
          src={theme === Theme.Dark ? LogoWhite : Logo}
          alt='logo'
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '90%' }}
        />
      </Cell>
    </Grid>
  )
}

export default React.memo(LogoMenu)
