import React from 'react'
import { Button, ButtonThemeType, FontIcon, TextIconSpacing, Tooltipped } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import { useAuth } from 'contexts/auth/auth-context'
import { Theme } from 'contexts/auth/types'

interface Props {
  entity: string
  id: string
  type?: ButtonThemeType
  rounded?: boolean
}

const ButtonLog: React.FC<Props> = ({ entity, id, type, rounded }) => {
  const { promiseInProgress } = usePromiseTracker()
  const { theme } = useAuth()

  const url = btoa('{"aggregateName":"' + entity + '","id":"' + id + '"}')

  return (
    <>
      {!rounded && (
        <Button
          themeType={type ?? 'flat'}
          theme={promiseInProgress ? 'disabled' : 'clear'}
          disabled={promiseInProgress}
          onClick={() => window.open(`/audit/${url}`)}
          style={
            promiseInProgress
              ? {}
              : theme === Theme.Dark
              ? { margin: 2, backgroundColor: '#1565C0', color: '#FFFFFF' }
              : { margin: 2, backgroundColor: '#64B5F6', color: '#212121' }
          }>
          <TextIconSpacing icon={<FontIcon>history</FontIcon>}>Log</TextIconSpacing>
        </Button>
      )}

      {rounded && (
        <Tooltipped id={id} tooltip='Log' position={'above'}>
          <Button
            themeType={type ?? 'flat'}
            buttonType='icon'
            theme={promiseInProgress ? 'disabled' : 'clear'}
            disabled={promiseInProgress}
            onClick={() => window.open(`/audit/${url}`)}
            style={
              promiseInProgress
                ? {}
                : theme === Theme.Dark
                ? { backgroundColor: '#1565C0', color: '#FFFFFF' }
                : { backgroundColor: '#64B5F6', color: '#212121' }
            }>
            <FontIcon>history</FontIcon>
          </Button>
        </Tooltipped>
      )}
    </>
  )
}

export default ButtonLog
