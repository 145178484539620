export interface ActivityOptionsList {
  value: ActivityEnum | null
  label: string
}

export enum ActivityEnum {
  NonContributor = 'NonContributor',
  Industry = 'Industry',
  Commerce = 'Commerce',
  Autonomous = 'Autonomous',
  Service = 'Service',
  RuralProducer = 'RuralProducer',
  Comunication = 'Comunication',
  Energy = 'Energy',
}

export const ActivityOptions: Array<ActivityOptionsList> = [
  { value: null, label: '' },
  { value: ActivityEnum.NonContributor, label: 'Não Contribuinte' },
  { value: ActivityEnum.Autonomous, label: 'Autônomo' },
  { value: ActivityEnum.Commerce, label: 'Comércio' },
  { value: ActivityEnum.Comunication, label: 'Comunicação' },
  { value: ActivityEnum.Energy, label: 'Energia' },
  { value: ActivityEnum.Industry, label: 'Indústria' },
  { value: ActivityEnum.RuralProducer, label: 'Produtor Rural' },
  { value: ActivityEnum.Service, label: 'Serviço' },
]
