import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, useCrossFade, List, ListItem, FontIcon, Text } from 'react-md'

import { getPartnerIntegrationDescription } from 'types/quote/partner-integration'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { handleSubmit, Input } from 'components/form'
import { formatCurrency } from 'types/core/currency'

import { defaultValues, validationSchema } from './config'
import { handleSearch } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [result, setResult] = useState<any | null>(null)
  const [, transitionProps] = useCrossFade({ appear: false })

  useEffect(() => {
    document.title = 'Validação de Cubagem'

    return () => {}
  }, [])

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    setResult(null)
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Validação de Cubagem' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Input name='trackingCode' label='Tracking' desktopSize={6} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  let values = form.getValues()
                  if (values.trackingCode) setResult(await handleSearch(values.trackingCode))
                })
              }
            />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => clear()} />
          </CardActions>
        </Card>
      </Cell>

      {result?.data && (
        <>
          <Cell>
            <Text type='headline-6' margin='none'>
              Parceiros
            </Text>
          </Cell>
          {result?.data.map((data: any, index: number) => (
            <Cell desktopSize={4} key={index}>
              <div {...transitionProps}>
                <Card fullWidth>
                  <CardContent>
                    <List>
                      <ListItem leftAddon={<FontIcon>verified</FontIcon>}>Parceiro: {getPartnerIntegrationDescription(data.partner)}</ListItem>

                      {!data.validations && (
                        <>
                          <ListItem leftAddon={<FontIcon>calendar_month</FontIcon>}>Prazo de Entrega: {data.deadline}</ListItem>
                          <ListItem leftAddon={<FontIcon>attach_money</FontIcon>}>Valor: {formatCurrency(data.value)}</ListItem>
                        </>
                      )}
                      {data.validations && (
                        <>
                          <ListItem leftAddon={<FontIcon>rule</FontIcon>}>{data.validations}</ListItem>
                        </>
                      )}
                    </List>
                  </CardContent>
                </Card>
              </div>
            </Cell>
          ))}
        </>
      )}
    </Grid>
  )
}

export default Component
