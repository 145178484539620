import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow, FontIcon } from 'react-md'

import { getPickupRequestStatusDescription, PickupRequestStatusOptions } from 'types/pickup/pickup-request-status'
import { handleSubmit, InputDatePicker, Select } from 'components/form'
import { getFullAddressDescription } from 'components/address/types'
import { Cell, PageTitle, ButtonSearch, ButtonSuccess, ButtonEdit, ButtonClearSearch } from 'components/core'
import { DataTable, EditButton } from 'components/data-table'
import { ClientAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'

import { defaultValues, validationSchema } from './config'
import { handleSearch, handleEdit } from './actions'
import { format, parseISO } from 'date-fns'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Solicitação de Coleta'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pesquisa de Solicitação de Coleta' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Remetente' desktopSize={3} />
                <InputDatePicker name='initialDate' label='Data Inicial' desktopSize={2} tabletSize={4} />
                <InputDatePicker name='finalDate' label='Data Final' desktopSize={2} tabletSize={4} />
                <Select name='status' label='Status' options={PickupRequestStatusOptions} desktopSize={2} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <DataTable
          filter={filter}
          header={[{ order: 'Status', label: 'Status' }, { order: 'Date', label: 'Data' }, { label: 'Endereço' }, { label: '' }]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell>{getPickupRequestStatusDescription(data.status)}</TableCell>
                <TableCell>{format(parseISO(data.date), "dd'/'MM'/'yyyy")}</TableCell>
                <TableCell>{getFullAddressDescription(data.address)}</TableCell>
                <TableCell align='right'>
                  <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                </TableCell>
              </TableRow>
            ))}
        </DataTable>
      </Cell>
    </Grid>
  )
}

export default Component
