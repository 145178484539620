import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import ExpeditionCubageManual from 'pages/expedition/cubage/manual'
import ValidateCubageManual from 'pages/expedition/cubage/validate'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.expedition.cubageManual && (
        <>
          <Route path='/expedition/cubage/manual' exact component={ExpeditionCubageManual} />
        </>
      )}

      {menuPermission?.expedition.validateCubage && (
        <>
          <Route path='/expedition/cubage/validate' exact component={ValidateCubageManual} />
        </>
      )}
    </>
  )
}

export default Routes
