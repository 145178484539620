export const getFullAddressDescription = (address: any): string => {
  let fullAddress = address.street
  if (address.number) fullAddress += `, ${address.number}`
  if (address.complement) fullAddress += `, ${address.complement}`
  if (address.neighborhood) fullAddress += `, ${address.neighborhood}`
  if (address.city) fullAddress += ` - ${address.city}`
  if (address.state) fullAddress += `/${address.state}`
  if (address.zipCode) fullAddress += ` - ${formatCepMask(address.zipCode)}`
  return fullAddress
}

export const getShortAddressDescription = (address: any): string => {
  let fullAddress = address.street
  if (address.number) fullAddress += `, ${address.number}`
  if (address.complement) fullAddress += `, ${address.complement}`
  if (address.neighborhood) fullAddress += `, ${address.neighborhood}`
  if (address.city) fullAddress += ` - ${address.city}`

  return fullAddress
}

export const formatCepMask = (value: string): string =>
  `${value ?? ''}`
    .replace(/\D/g, '')
    .padStart(8, '0')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')

export const getEnumDescription = (stateNumber: number): string => {
  return StateNumberOptions.filter(x => x.value === stateNumber)[0]?.label
}

export interface StateOption {
  value: StateEnum | null
  label: string
}

interface StateNumberOption {
  value: StateNumberEnum | null
  label: string
}

enum StateNumberEnum {
  None = 0,
  AC = 12,
  AL = 27,
  AP = 16,
  AM = 13,
  BA = 29,
  CE = 23,
  DF = 53,
  ES = 32,
  GO = 52,
  MA = 21,
  MT = 51,
  MS = 50,
  MG = 31,
  PR = 41,
  PB = 25,
  PA = 15,
  PE = 26,
  PI = 22,
  RJ = 33,
  RN = 24,
  RS = 43,
  RO = 11,
  RR = 14,
  SC = 42,
  SE = 28,
  SP = 35,
  TO = 17,
}

const StateNumberOptions: Array<StateNumberOption> = [
  { value: null, label: '' },
  { value: StateNumberEnum.AC, label: 'AC' },
  { value: StateNumberEnum.AL, label: 'AL' },
  { value: StateNumberEnum.AP, label: 'AP' },
  { value: StateNumberEnum.AM, label: 'AM' },
  { value: StateNumberEnum.BA, label: 'BA' },
  { value: StateNumberEnum.CE, label: 'CE' },
  { value: StateNumberEnum.DF, label: 'DF' },
  { value: StateNumberEnum.ES, label: 'ES' },
  { value: StateNumberEnum.GO, label: 'GO' },
  { value: StateNumberEnum.MA, label: 'MA' },
  { value: StateNumberEnum.MT, label: 'MT' },
  { value: StateNumberEnum.MS, label: 'MS' },
  { value: StateNumberEnum.MG, label: 'MG' },
  { value: StateNumberEnum.PR, label: 'PR' },
  { value: StateNumberEnum.PB, label: 'PB' },
  { value: StateNumberEnum.PA, label: 'PA' },
  { value: StateNumberEnum.PE, label: 'PE' },
  { value: StateNumberEnum.PI, label: 'PI' },
  { value: StateNumberEnum.RJ, label: 'RJ' },
  { value: StateNumberEnum.RN, label: 'RN' },
  { value: StateNumberEnum.RS, label: 'RS' },
  { value: StateNumberEnum.RO, label: 'RO' },
  { value: StateNumberEnum.RR, label: 'RR' },
  { value: StateNumberEnum.SC, label: 'SC' },
  { value: StateNumberEnum.SE, label: 'SE' },
  { value: StateNumberEnum.SP, label: 'SP' },
  { value: StateNumberEnum.TO, label: 'TO' },
]

export enum StateEnum {
  AC = 'AC',
  AL = 'AL',
  AP = 'AP',
  AM = 'AM',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MT = 'MT',
  MS = 'MS',
  MG = 'MG',
  PR = 'PR',
  PB = 'PB',
  PA = 'PA',
  PE = 'PE',
  PI = 'PI',
  RJ = 'RJ',
  RN = 'RN',
  RS = 'RS',
  RO = 'RO',
  RR = 'RR',
  SC = 'SC',
  SE = 'SE',
  SP = 'SP',
  TO = 'TO',
}

export const StateOptions: Array<StateOption> = [
  { value: null, label: '' },
  { value: StateEnum.AC, label: 'AC' },
  { value: StateEnum.AL, label: 'AL' },
  { value: StateEnum.AP, label: 'AP' },
  { value: StateEnum.AM, label: 'AM' },
  { value: StateEnum.BA, label: 'BA' },
  { value: StateEnum.CE, label: 'CE' },
  { value: StateEnum.DF, label: 'DF' },
  { value: StateEnum.ES, label: 'ES' },
  { value: StateEnum.GO, label: 'GO' },
  { value: StateEnum.MA, label: 'MA' },
  { value: StateEnum.MT, label: 'MT' },
  { value: StateEnum.MS, label: 'MS' },
  { value: StateEnum.MG, label: 'MG' },
  { value: StateEnum.PR, label: 'PR' },
  { value: StateEnum.PB, label: 'PB' },
  { value: StateEnum.PA, label: 'PA' },
  { value: StateEnum.PE, label: 'PE' },
  { value: StateEnum.PI, label: 'PI' },
  { value: StateEnum.RJ, label: 'RJ' },
  { value: StateEnum.RN, label: 'RN' },
  { value: StateEnum.RS, label: 'RS' },
  { value: StateEnum.RO, label: 'RO' },
  { value: StateEnum.RR, label: 'RR' },
  { value: StateEnum.SC, label: 'SC' },
  { value: StateEnum.SE, label: 'SE' },
  { value: StateEnum.SP, label: 'SP' },
  { value: StateEnum.TO, label: 'TO' },
]
