import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  integration: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().nullable().max(60, 'O nome deve ter no máximo 60 caracteres'),
  integration: Yup.string().nullable().required(),
})
