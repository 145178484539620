import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { ClientCoverageAutoComplete, ClientFareAutoComplete } from 'components/auto-complete'
import { getServiceDescription } from 'types/quote/service'
import { handleSubmit } from 'components/form'
import { TextLabel } from 'components/label'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleGet, handleSave } from './actions'

interface ComponentProps {
  clientId: string
  serviceId: string | undefined
  visibleModal: boolean
  disableModal: () => void
  loadServices: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ clientId, serviceId, visibleModal, disableModal, loadServices }) => {
  const form = useForm({ defaultValues })
  const [service, setService] = useState<any>()

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
  }

  useEffect(() => {
    const load = async () => {
      if (serviceId) {
        const res = await handleGet(serviceId)
        if (res) {
          form.reset(res)
          setService(res)
        }
      }
    }

    load()

    return () => {}
  }, [serviceId])

  return (
    <Modal
      title={'Editar Serviço'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ clientId, serviceId, ...form.getValues() })) {
            await loadServices()
            clear()
            disableModal()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <TextLabel name='service' label='Serviço *' value={getServiceDescription(service?.service)} desktopSize={4} tabletSize={2} />
          <ClientFareAutoComplete name='clientFare' label='Tarifa *' desktopSize={4} tabletSize={3} />
          <ClientCoverageAutoComplete name='clientCoverage' label='Abrangência *' desktopSize={4} tabletSize={3} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
