import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from 'react-md'

import { getPickupRequestStatusDescription } from 'types/pickup/pickup-request-status'
import { getFullAddressDescription } from 'components/address/types'
import { DataTableResult } from 'components/data-table/types'
import { DataTable, EditButton } from 'components/data-table'

import { handleEdit, handleSearch } from './actions'

interface ComponentProps {
  packingListId: string
}

const Component: React.FC<ComponentProps> = ({ packingListId }) => {
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    if (packingListId) setFilter({ packingListId })

    return () => {}
  }, [packingListId])

  return (
    <DataTable
      filter={filter}
      header={[
        { label: 'Status' },
        { label: 'Ordem' },
        { label: 'Cliente' },
        { label: 'Endereço' },
        { label: 'Horário Inicial' },
        { label: 'Horário Final' },
        { label: '' },
      ]}
      result={result}
      setResult={setResult}
      search={handleSearch}
      total={result ? result.total : 0}>
      {result &&
        result.results &&
        result.results.map((data: any, index) => (
          <TableRow key={index}>
            <TableCell>{getPickupRequestStatusDescription(data.status)}</TableCell>
            <TableCell>{data.sequence}</TableCell>
            <TableCell>{data.clientFantasyName}</TableCell>
            <TableCell>{getFullAddressDescription(data.address)}</TableCell>
            <TableCell>{String(data.pickupStartTime).substring(0, 5)}</TableCell>
            <TableCell>{String(data.pickupFinalTime).substring(0, 5)}</TableCell>
            <TableCell align='right'>
              <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
            </TableCell>
          </TableRow>
        ))}
    </DataTable>
  )
}

export default Component
