import api from 'services/pickup/pickup-request'

export const handleSearch = async (request: any) => {
  let res = await api.searchByPackingListId(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/pickup/pickup-request/${id}/edit`)
}
