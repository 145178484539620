import Yup from 'utils/yup'

export const defaultValues = {
  ftpProtocol: null,
  ftpPort: null,
  url: null,
  ftpNotfisFolder: null,
  ftpOcorenFolder: null,
  user: null,
  password: null,
}

export const validationSchema = Yup.object().shape({
  ftpProtocol: Yup.string().nullable().required(),
  ftpPort: Yup.number().nullable().required(),
  url: Yup.string().nullable().required(),
  ftpNotfisFolder: Yup.string().nullable(),
  ftpOcorenFolder: Yup.string().nullable(),
  user: Yup.string().nullable().required(),
  password: Yup.string().nullable().required(),
})
