import React, { useEffect } from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { handleSubmit, InputDecimal } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  icms: any
  visibleModal: boolean
  closeModal: () => void
  onHide: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ icms, visibleModal, closeModal, onHide }) => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    form.reset({ state: icms?.state, ...icms })

    return () => {}
  }, [icms])

  useEffect(() => {
    form.register({ name: 'state' })

    return () => {}
  }, [form.register()])

  return (
    <Modal
      title={`Edição de Icms - Origem ${icms?.state}`}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ ...form.getValues(), id: icms.id })) {
            form.reset(form.getValues(), { dirty: false })
            await onHide()
            closeModal()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => closeModal()}>
      <Grid>
        <FormContext {...form}>
          <InputDecimal name='ac' label='AC *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='al' label='AL *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='am' label='AM *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='ap' label='AP *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='ba' label='BA *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='ce' label='CE *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='df' label='DF *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='es' label='ES *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='go' label='GO *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='ma' label='MA *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='mg' label='MG *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='ms' label='MS *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='mt' label='MT *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='pa' label='PA *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='pb' label='PB *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='pe' label='PE *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='pi' label='PI *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='pr' label='PR *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='rj' label='RJ *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='rn' label='RN *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='ro' label='RO *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='rr' label='RR *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='rs' label='RS *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='sc' label='SC *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='se' label='SE *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='sp' label='SP *' desktopSize={3} tabletSize={2} />
          <InputDecimal name='to' label='TO *' desktopSize={3} tabletSize={2} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
