import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'
import { FormContextValues } from 'react-hook-form'

import Address from 'components/address'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Endereço Comercial
        </Text>
      </Cell>
      <Cell>
        <Address form={form} />
      </Cell>
    </Grid>
  )
}

export default Component
