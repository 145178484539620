import { trackPromise } from 'react-promise-tracker'
import { AxiosRequestConfig } from 'axios'

import { ApiResponse } from 'types/core/api'
import api from 'utils/api'

import { DataTableRequest } from 'components/data-table/types'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/iss'
const Api = {
  getCsv: (): Promise<any> => trackPromise<ApiResponse>(api.get(`${url}/csv`, headerConfig)),
  import: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  downloadCsv: (): Promise<any> => trackPromise<ApiResponse>(api.post(`${url}/csv`, null, headerConfig)),
}

export default Api
