import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/order'
const Api = {
  getById: (volumeId: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/volume/${volumeId}/delivery-information`)),
}

export default Api
