import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'
import { Grid, Text } from 'react-md'

import { InputDecimal, SelectBoolean } from 'components/form'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Grid>
        <Cell>
          <Text type='headline-6' margin='none'>
            Outras Regras
          </Text>
        </Cell>
        <Cell>
          <Grid>
            <SelectBoolean
              name='loggiAllowAirTransport'
              label='Loggi - Permitir Transporte Aéreo *'
              helpText={'Ativa ou Desativa transferência áerea Loggi'}
              desktopSize={4}
              tabletSize={4}
            />
            <SelectBoolean
              name='booksIcmsExemption'
              label='Livros - Isenção Icms *'
              helpText={'Marca flag no NOTFIS que é enviado para os parceiros para sinalizar que se trata de produtos isentos de ICMS'}
              desktopSize={4}
              tabletSize={4}
            />
            <SelectBoolean
              name='correiosRedispatch'
              label='Repostagem Correios *'
              helpText={'Ativa ou Desativa repostagem nos correios. Caso Desativada, o parceiro correios não será escolhido como parceiro de entrega'}
              desktopSize={4}
              tabletSize={4}
            />
            <SelectBoolean
              name='fmTemplateTrackingEmails'
              label='E-mails de Rastreio Modelo FM *'
              helpText={'Ativa ou Desativa envio de email de rastreio no modelo FM'}
              desktopSize={4}
              tabletSize={4}
            />
            <InputDecimal name='cubageExemption' label='Exceção de Cubagem *' desktopSize={4} tabletSize={4} />
          </Grid>
        </Cell>
      </Grid>
    </FormContext>
  )
}

export default Component
