import api from 'services/system/profile'

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}

export const handleAddPermission = async (profileId: string, permission: string) => {
  let res = await api.addPermission({ profileId, permission })

  return res?.data?.success
}

export const handleAddBusinessGroup = async (profileId: string, businessGroupId: string) => {
  let res = await api.addBusinessGroup({ profileId, businessGroupId })

  return res?.data?.success
}

export const handleDeletePermission = async (profileId: string, id: string) => {
  let res = await api.deletePermission(profileId, id)

  return res?.data?.success
}

export const handleDeleteBusinessGroup = async (profileId: string, id: string) => {
  let res = await api.deleteBusinessGroup(profileId, id)

  return res?.data?.success
}

export const getPermissionDescription = (permissionsArray: Array<any>, permission: string): string => {
  return permissionsArray.filter(x => x.value === permission.toString())[0]?.label
}
