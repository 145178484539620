import Yup from 'utils/yup'

export const defaultValues = {
  date: null,
  status: null,
  responsible: null,
  volumeQuantity: null,
  observation: null,
}

export const validationSchema = Yup.object().shape({
  date: Yup.date().nullable().required(),
  status: Yup.string().nullable().required(),
  responsible: Yup.string().nullable(),
  volumeQuantity: Yup.number().nullable(),
  observation: Yup.string().nullable(),
})
