import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  active: false,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().min(3, 'O nome deve ter no mínimo 3 caracteres').max(40, 'O nome deve ter no máximo 40 caracteres'),
  active: Yup.bool().nullable().required(),
})
