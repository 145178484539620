import React from 'react'
import { Button, TextIconSpacing } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import { useAuth } from 'contexts/auth/auth-context'
import { Theme } from 'contexts/auth/types'

interface Props {
  text: string
  icon?: React.ReactNode
  disabled?: boolean
  onClick?: () => Promise<void>
  style?: React.CSSProperties
  [x: string]: any
}

const ButtonEdit: React.FC<Props> = ({ text, icon, disabled, onClick, style, ...rest }) => {
  const { promiseInProgress } = usePromiseTracker()
  const { theme } = useAuth()

  return (
    <Button
      theme={disabled || promiseInProgress ? 'disabled' : 'clear'}
      disabled={disabled || promiseInProgress}
      onClick={() => (onClick ? onClick() : {})}
      style={
        disabled || promiseInProgress
          ? { ...style }
          : theme === Theme.Dark
          ? { ...style, margin: 2, backgroundColor: '#f57c00', color: '#FFFFFF' }
          : { ...style, margin: 2, backgroundColor: '#ffb74d', color: '#212121' }
      }
      {...rest}>
      <TextIconSpacing icon={icon}>{text}</TextIconSpacing>
    </Button>
  )
}

export default ButtonEdit
