import api from 'services/quote/client-coverage'

import downloadFile from 'utils/download-file'

export const handleImport = async (clientCoverageId: string, fileName: string, file: any): Promise<Array<string> | null> => {
  let res = await api.uploadCoverage({ clientCoverageId, fileName, file })

  return res?.data?.success
}

export const handleGetCoverageCsv = async (fileName: string) => {
  let res = await api.getCsv()

  downloadFile(fileName, res?.data)
}
