import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getOrderFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (
    permissions?.order.fm.deleteLot ||
    permissions?.order.fm.search ||
    permissions?.order.fm.importCsv ||
    permissions?.order.fm.importManual ||
    permissions?.order.fm.importXml ||
    permissions?.order.fm.fixImportValidation ||
    permissions?.order.fm.report.lot
  ) {
    menu['/order'] = getMenu('/order', null, 'Pedidos', 'receipt', null)

    if (permissions?.order.fm.changePartnerLot)
      menu['/order/change-partner/lot'] = getMenu(
        '/order/change-partner/lot',
        '/order',
        'Atualização de Parceiro',
        'sync',
        '/order/change-partner/lot',
      )

    if (permissions?.order.fm.updateStatus)
      menu['/order/update-status/lot'] = getMenu(
        '/order/update-status/lot',
        '/order',
        'Atualização de Status',
        'move_down',
        '/order/update-status/lot',
      )

    if (permissions?.order.fm.deleteLot) menu['/order/delete/lot'] = getMenu('/order/delete/lot', '/order', 'Exclusão', 'delete', '/order/delete/lot')

    if (
      permissions?.order.fm.importCsv ||
      permissions?.order.fm.importManual ||
      permissions?.order.fm.importXml ||
      permissions?.order.fm.fixImportValidation
    ) {
      menu['/order/import'] = getMenu('/order/import', '/order', 'Importação', 'upload', null)

      if (permissions?.order.fm.importCsv)
        menu['/order/import/csv'] = getMenu('/order/import/csv', '/order/import', 'Csv', 'description', '/order/import/csv')

      if (permissions?.order.fm.importManual)
        menu['/order/import/manual'] = getMenu('/order/import/manual', '/order/import', 'Manual', 'description', '/order/import/manual')

      if (permissions?.order.fm.importXml)
        menu['/order/import/xml'] = getMenu('/order/import/xml', '/order/import', 'Xml', 'description', '/order/import/xml')
    }

    if (permissions?.order.fm.search)
      menu['/order/log/search'] = getMenu('/order/log/search', '/order', 'Log de Exclusão', 'history_toggle_off', '/order/log/search')

    if (permissions?.order.fm.fixImportValidation)
      menu['/order/import/fix'] = getMenu('/order/import/fix', '/order', 'Pacotes Inválidos', 'build', '/order/import/fix')

    if (permissions?.order.fm.search) menu['/order/search'] = getMenu('/order/search', '/order', 'Pesquisa', 'search', '/order/search')

    if (permissions?.order.fm.report.lot) {
      menu['/order/report'] = getMenu('/order/report', '/order', 'Relatórios', 'print', null)

      if (permissions?.order.fm.report.lot)
        menu['/order/report/lot'] = getMenu('/order/report/lot', '/order/report', 'Busca Lote', 'receipt_long', '/order/report/lot')
    }
  }
}
