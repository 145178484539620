import React from 'react'
import { Grid } from '@react-md/utils'
import { FontIcon, Text } from 'react-md'

import { ButtonEdit, Cell } from 'components/core'
import Address from 'components/address'

import { FormContextValues } from 'react-hook-form'

interface ComponentProps {
  form: FormContextValues<any>
  copyAddress: () => void
}

const Component: React.FC<ComponentProps> = ({ form, copyAddress }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Endereço do Tomador
        </Text>
      </Cell>
      <Cell>
        <ButtonEdit theme='secondary' text='Copiar Endereço Comercial' icon={<FontIcon>copy_all</FontIcon>} onClick={async () => copyAddress()} />
        <Address form={form} />
      </Cell>
    </Grid>
  )
}

export default Component
