import React, { useState } from 'react'
import { Text } from '@react-md/typography'

import { DataTableRequest } from 'components/data-table/types'
import { NavigateBefore, NavigateNext, getLastRow, getStartRow } from 'components/data-table'

interface Props {
  request: DataTableRequest | undefined
  setRequest: (value: React.SetStateAction<DataTableRequest | undefined>) => void
  total: number
}

const Component: React.FC<Props> = ({ request, setRequest, total }) => {
  const [rowsPerPage] = useState<number>(5)

  return (
    <>
      <Text style={{ marginRight: 5 }}>
        {getStartRow(request, rowsPerPage)}-{getLastRow(request, total, rowsPerPage)} de {total}
      </Text>
      <NavigateBefore request={request} setRequest={setRequest} rowsPerPage={rowsPerPage} />
      <NavigateNext request={request} setRequest={setRequest} rowsPerPage={rowsPerPage} total={total} />
    </>
  )
}

export default Component
