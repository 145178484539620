import api from 'services/integration/client-integration'

export const handleGetIntegrations = async (clientId: any) => {
  let res = await api.list(clientId)

  return res?.data?.data ?? []
}

export const handleRemoveIntegration = async (clientId: any, id: string) => {
  let res = await api.remove(clientId, id)

  return res?.data?.data ?? []
}
