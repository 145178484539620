import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'

import { Input, InputDocument, Select, SelectBoolean } from 'components/form'
import { ActivityOptions } from 'types/sale/activity'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Grid>
        <Cell>
          <Text type='headline-6' margin='none'>
            Tomador
          </Text>
        </Cell>
        <Cell>
          <Grid>
            <Input name='takerName' label='Nome *' desktopSize={12} tabletSize={8} />
            <InputDocument name='takerDocument' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
            <Input name='takerIe' label='Inscrição Estadual *' desktopSize={3} tabletSize={4} />
            <SelectBoolean name='issueCte' label='Emissão de Cte' desktopSize={3} tabletSize={4} />
            <Select name='activity' label='Atividade' options={ActivityOptions} desktopSize={3} tabletSize={4} />
          </Grid>
        </Cell>
      </Grid>
    </FormContext>
  )
}

export default Component
