import { trackPromise } from 'react-promise-tracker'
import { AxiosRequestConfig } from 'axios'

import { ApiResponse } from 'types/core/api'
import api from 'utils/api'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/order/import/csv'
const Api = {
  getCsv: (): Promise<any> => trackPromise<ApiResponse>(api.get(url, headerConfig)),
  importCsv: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
}

export default Api
