import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/block-task'
const Api = {
  list: (): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/list`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  delete: (task: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${task}`)),
}

export default Api
