import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { handleSubmit, InputDecimal, InputNumber } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  index: number | null
  visibleModal: boolean
  updateVolume: (index: number, volume: any) => void
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ index, visibleModal, updateVolume, disableModal }) => {
  const form = useForm({ defaultValues })

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    disableModal()
  }

  return (
    <Modal
      title={'Volume'}
      visible={visibleModal}
      width={800}
      confirmTitle={'Confirmar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          updateVolume(index!, form.getValues())
          clear()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => clear()}>
      <FormContext {...form}>
        <Grid>
          <InputDecimal name='weight' label='Peso (kg) *' desktopSize={3} tabletSize={4} maxLength={5} />
          <InputNumber name='length' label='Comprimento (cm) *' desktopSize={3} tabletSize={4} maxLength={3} />
          <InputNumber name='height' label='Altura (cm) *' desktopSize={3} tabletSize={4} maxLength={3} />
          <InputNumber name='width' label='Largura (cm) *' desktopSize={3} tabletSize={4} maxLength={3} />
        </Grid>
      </FormContext>
    </Modal>
  )
}

export default Component
