import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'
import { Grid } from 'react-md'

import { PartnerIntegrationOptions } from 'types/quote/partner-integration'
import { Input, Select } from 'components/form'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Grid>
        <Input name='name' label='Nome *' desktopSize={6} tabletSize={4} />
        <Select name='integration' label='Integração *' options={PartnerIntegrationOptions} desktopSize={4} tabletSize={4} />
      </Grid>
    </FormContext>
  )
}

export default Component
