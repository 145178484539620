import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { ClientFareAutoComplete } from 'components/auto-complete'
import { CalloutType } from 'components/core/callout'
import { Callout, Cell, Modal } from 'components/core'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  clientFareId: string
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ clientFareId, visibleModal, disableModal }) => {
  const form = useForm({ defaultValues })

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
  }

  return (
    <Modal
      title={'Migrar Clientes'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ currentClientFareId: clientFareId, ...form.getValues() })) {
            clear()
            disableModal()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <Grid>
        <Cell>
          <Callout text={'Para realizar a migração de clientes entre tarifas informe a nova tarifa e clique em salvar'} type={CalloutType.Info} />
        </Cell>
        <FormContext {...form}>
          <ClientFareAutoComplete name='newClientFare' label='Nova Tarifa *' />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
