import React from 'react'
import { AppBar, AppBarAction, AppBarNav, Badge, FAB, FontIcon, MenuSVGIcon, Tooltipped, useLayoutConfig, useToggle } from 'react-md'

import { useNotification } from 'contexts/notification/notification-context'
import { useAuth } from 'contexts/auth/auth-context'
import { Loading } from 'components/core'

import Notification from './components/notification'
import User from './components/user'

const ActionBar: React.FC = () => {
  const [visibleUser, enableUser, disableUser] = useToggle(false)
  const [visibleNotifications, enableNotifications, disableNotifications] = useToggle(false)
  const { count } = useNotification()
  const { showNav } = useLayoutConfig()
  const { logout } = useAuth()

  return (
    <>
      <AppBar>
        <AppBarNav>
          <MenuSVGIcon onClick={showNav} />
        </AppBarNav>
        <AppBarAction onClick={enableNotifications} first>
          <FAB>
            <FontIcon>notifications</FontIcon>
            <Badge id='notification-badge' theme='secondary'>
              {count}
            </Badge>
          </FAB>
        </AppBarAction>
        <AppBarAction onClick={enableUser}>
          <FontIcon>person</FontIcon>
        </AppBarAction>
        <AppBarAction last>
          <Tooltipped id='logout' tooltip='Sair' position='left'>
            <FontIcon onClick={() => logout()}>logout</FontIcon>
          </Tooltipped>
        </AppBarAction>
      </AppBar>

      <Loading />

      <Notification visible={visibleNotifications} disable={disableNotifications} />

      <User visible={visibleUser} disable={disableUser} />
    </>
  )
}

export default React.memo(ActionBar)
