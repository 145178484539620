import { trackPromise } from 'react-promise-tracker'

import { ApiResponse } from 'types/core/api'
import api from 'utils/api'

const url = '/v1/order/import/manual'
const Api = {
  importManual: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
}

export default Api
