import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, Grid, Table, TableBody, TableCell, TableHeader, TableRow, Text, useToggle } from 'react-md'

import { ButtonSuccess, Callout, Cell, ConfirmDialog } from 'components/core'
import { CalloutType } from 'components/core/callout'

import { handleGetServices, handleRemoveService } from './actions'
import { DeleteButton, EditButton } from 'components/data-table'
import { getServiceDescription } from 'types/quote/service'

import EditService from './components/edit-service'
import AddService from './components/add-service'

interface ComponentProps {
  clientId: string
}

const Component: React.FC<ComponentProps> = ({ clientId }) => {
  const [services, setServices] = useState<Array<any> | null>(null)
  const [serviceId, setServiceId] = useState<string>()
  const [visibleAddModal, enableAddModal, disableAddModal] = useToggle(false)
  const [visibleEditModal, enableEditModal, disableEditModal] = useToggle(false)
  const [visibleDeleteModal, enableDeleteModal, disableDeleteModal] = useToggle(false)

  useEffect(() => {
    loadServices()

    return () => {}
  }, [clientId])

  const loadServices = async () => setServices(await handleGetServices(clientId))

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <Grid>
            <Cell>
              <Text type='headline-6' margin='none'>
                Serviços Contratados
              </Text>
            </Cell>
            <Cell>
              {services?.length === 0 && <Callout text={'Nenhum serviço localizado'} type={CalloutType.Info} />}
              {services && services.length > 0 && (
                <Card fullWidth>
                  <CardContent>
                    <Table fullWidth>
                      <TableHeader>
                        <TableRow>
                          <TableCell>Serviço</TableCell>
                          <TableCell>Tarifa</TableCell>
                          <TableCell>Abrangência</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {services.map((serviceData: any, index) => (
                          <TableRow key={index}>
                            <TableCell>{getServiceDescription(serviceData.service)}</TableCell>
                            <TableCell>{serviceData.clientFareName}</TableCell>
                            <TableCell>{serviceData.clientCoverageName}</TableCell>
                            <TableCell align='right'>
                              <EditButton
                                id={`edit-${index}`}
                                action={() => {
                                  setServiceId(serviceData.id)
                                  enableEditModal()
                                }}
                              />
                              <DeleteButton
                                id={`delete-${index}`}
                                action={() => {
                                  setServiceId(serviceData.id)
                                  enableDeleteModal()
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              )}
            </Cell>
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonSuccess text='Novo Serviço' icon={<FontIcon>add</FontIcon>} onClick={async () => enableAddModal()} />
        </CardActions>
      </Card>

      <AddService clientId={clientId} visibleModal={visibleAddModal} disableModal={disableAddModal} loadServices={loadServices} />

      {visibleEditModal && (
        <EditService
          clientId={clientId}
          serviceId={serviceId}
          visibleModal={visibleEditModal}
          disableModal={disableEditModal}
          loadServices={loadServices}
        />
      )}

      {visibleDeleteModal && (
        <ConfirmDialog
          text='Deseja realmente excluir o serviço? Essa ação não poderá ser desfeita'
          visible={true}
          confirmAction={async () => {
            if (serviceId)
              if (await await handleRemoveService(clientId, serviceId)) {
                setServiceId(undefined)
                disableDeleteModal()
                await loadServices()
              }
          }}
          cancelAction={async () => {
            setServiceId(undefined)
            disableDeleteModal()
          }}
        />
      )}
    </>
  )
}

export default Component
