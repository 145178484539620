import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Table, TableHeader, TableRow, TableCell, TableBody, TableContainer } from 'react-md'
import { format, parseISO } from 'date-fns'

import { Cell, PageTitle, Callout } from 'components/core'
import { CalloutType } from 'components/core/callout'

import { handleList } from './actions'

const Component: React.FC = () => {
  const [blockTasks, setBlockTasks] = useState<Array<any> | null>(null)

  useEffect(() => {
    document.title = 'Tarefas Executadas'

    load()

    return () => {}
  }, [])

  const load = async () => setBlockTasks(await handleList())

  return (
    <Grid>
      <Cell>
        <PageTitle title='Tarefas Executadas' />
      </Cell>
      <Cell>
        {blockTasks?.length === 0 && <Callout text={'Nenhuma tarefa localizada'} type={CalloutType.Info} />}
        {blockTasks != null && blockTasks?.length !== 0 && (
          <Card fullWidth>
            <CardContent>
              <TableContainer>
                <Table fullWidth>
                  <TableHeader>
                    <TableRow>
                      <TableCell>Iniciada Em</TableCell>
                      <TableCell>Tarefa</TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {blockTasks?.map((data: any, index) => (
                      <TableRow key={index}>
                        <TableCell>{format(parseISO(data.lastTime), "dd'/'MM'/'yyyy' 'HH:mm:ss")}</TableCell>
                        <TableCell>{data.taskName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}
      </Cell>
    </Grid>
  )
}

export default Component
