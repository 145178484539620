import api from 'services/order/order-log'

export const handleSearch = async (request: any) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/order/log/${id}/detail`)
}
