export const getZipCodeTypeDescription = (status: string): string => {
  return ZipCodeTypeOptions.filter(x => x.value === status.toString())[0]?.label
}

export interface ZipCodeTypeList {
  value: ZipCodeTypeEnum | null
  label: string
}

export enum ZipCodeTypeEnum {
  Region1 = 'Region1',
  Region2 = 'Region2',
}

export const ZipCodeTypeOptions: Array<ZipCodeTypeList> = [
  { value: null, label: '' },
  { value: ZipCodeTypeEnum.Region1, label: 'Região 1' },
  { value: ZipCodeTypeEnum.Region2, label: 'Região 2' },
]
