import api from 'services/sale/client'

export const handleUpdateDetail = async (values: any) => {
  let res = await api.updateContactDetail(values)

  return res?.data?.success
}

export const handleAddDepartment = async (values: any) => {
  let res = await api.addDepartmentToContactDetail(values)

  return res?.data?.success
}

export const handleRemoveDepartment = async (clientId: string, contactId: string, detailId: string, id: string) => {
  let res = await api.removeDepartmentFromContactDetail(clientId, contactId, detailId, id)

  return res?.data?.success
}
