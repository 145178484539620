import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getDeliveryMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions.delivery.deliveryPackingList) {
    menu['/delivery'] = getMenu('/delivery', null, 'Entrega', 'local_shipping', null)

    if (permissions.delivery.deliveryPackingList) {
      menu['/delivery/delivery-packing-list'] = getMenu('/delivery/delivery-packing-list', '/delivery', 'Romaneio', 'flag_circle', null)
      menu['/delivery/delivery-packing-list/new'] = getMenu(
        '/delivery/delivery-packing-list/new',
        '/delivery/delivery-packing-list',
        'Novo',
        'add',
        '/delivery/delivery-packing-list/new/redirect',
      )
      menu['/delivery/delivery-packing-list/search'] = getMenu(
        '/delivery/delivery-packing-list/search',
        '/delivery/delivery-packing-list',
        'Pesquisa',
        'search',
        '/delivery/delivery-packing-list/search',
      )
    }
  }
}
