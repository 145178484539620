export const getPartnerIntegrationDescription = (status: string): string => {
  return PartnerIntegrationOptions.filter(x => x.value === status.toString())[0]?.label
}

export interface PartnerIntegrationList {
  value: PartnerIntegrationEnum | null
  label: string
}

export enum PartnerIntegrationEnum {
  FmTransportes = 'FmTransportes',
  Loggi = 'Loggi',
  TotalExpressStandard = 'TotalExpressStandard',
  TotalExpressExpresso = 'TotalExpressExpresso',
  TotalExpressMedioRodo = 'TotalExpressMedioRodo',
  CargoBr = 'CargoBr',
  BusLog = 'BusLog',
  JadLog = 'JadLog',
  CorreiosMinasGeraisPac = 'CorreiosMinasGeraisPac',
  CorreiosMinasGeraisSedex = 'CorreiosMinasGeraisSedex',
  CorreiosMinasGeraisMiniEnvios = 'CorreiosMinasGeraisMiniEnvios',
  CorreiosMatoGrossoPac = 'CorreiosMatoGrossoPac',
  CorreiosMatoGrossoSedex = 'CorreiosMatoGrossoSedex',
  CorreiosMatoGrossoMiniEnvios = 'CorreiosMatoGrossoMiniEnvios',
  CorreiosParaibaPac = 'CorreiosParaibaPac',
  CorreiosParaibaSedex = 'CorreiosParaibaSedex',
  CorreiosParaibaMiniEnvios = 'CorreiosParaibaMiniEnvios',
  CorreiosGoiasPac = 'CorreiosGoiasPac',
  CorreiosGoiasSedex = 'CorreiosGoiasSedex',
  CorreiosGoiasMiniEnvios = 'CorreiosGoiasMiniEnvios',
  CorreiosCearaPac = 'CorreiosCearaPac',
  CorreiosCearaSedex = 'CorreiosCearaSedex',
  CorreiosCearaMiniEnvios = 'CorreiosCearaMiniEnvios',
  CorreiosBahiaPac = 'CorreiosBahiaPac',
  CorreiosBahiaSedex = 'CorreiosBahiaSedex',
  CorreiosBahiaMiniEnvios = 'CorreiosBahiaMiniEnvios',
  CorreiosPiauiPac = 'CorreiosPiauiPac',
  CorreiosPiauiSedex = 'CorreiosPiauiSedex',
  CorreiosPiauiMiniEnvios = 'CorreiosPiauiMiniEnvios',
  CorreiosSaoPauloPac = 'CorreiosSaoPauloPac',
  CorreiosSaoPauloSedex = 'CorreiosSaoPauloSedex',
  CorreiosSaoPauloMiniEnvios = 'CorreiosSaoPauloMiniEnvios',
}

export const PartnerIntegrationOptions: Array<PartnerIntegrationList> = [
  { value: null, label: '' },
  { value: PartnerIntegrationEnum.BusLog, label: 'BusLog' },
  { value: PartnerIntegrationEnum.CargoBr, label: 'CargoBR' },
  { value: PartnerIntegrationEnum.FmTransportes, label: 'FM Transportes' },
  { value: PartnerIntegrationEnum.JadLog, label: 'JadLog' },
  { value: PartnerIntegrationEnum.Loggi, label: 'Loggi' },
  { value: PartnerIntegrationEnum.TotalExpressStandard, label: 'Total Express - Standard' },
  { value: PartnerIntegrationEnum.TotalExpressExpresso, label: 'Total Express - Expresso' },
  { value: PartnerIntegrationEnum.TotalExpressMedioRodo, label: 'Total Express - Medio Rodo' },
  { value: PartnerIntegrationEnum.CorreiosMinasGeraisPac, label: 'Correios Minas Gerais - Pac' },
  { value: PartnerIntegrationEnum.CorreiosMinasGeraisSedex, label: 'Correios Minas Gerais - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosMinasGeraisMiniEnvios, label: 'Correios Minas Gerais - Mini Envios' },
  { value: PartnerIntegrationEnum.CorreiosMatoGrossoPac, label: 'Correios Mato Grosso - Pac' },
  { value: PartnerIntegrationEnum.CorreiosMatoGrossoSedex, label: 'Correios Mato Grosso - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosMatoGrossoMiniEnvios, label: 'Correios Mato Grosso - Mini Envios' },
  { value: PartnerIntegrationEnum.CorreiosParaibaPac, label: 'Correios Paraíba - Pac' },
  { value: PartnerIntegrationEnum.CorreiosParaibaSedex, label: 'Correios Paraíba - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosParaibaMiniEnvios, label: 'Correios Paraíba - Mini Envios' },
  { value: PartnerIntegrationEnum.CorreiosGoiasPac, label: 'Correios Goiás - Pac' },
  { value: PartnerIntegrationEnum.CorreiosGoiasSedex, label: 'Correios Goiás - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosGoiasMiniEnvios, label: 'Correios Goiás - Mini Envios' },
  { value: PartnerIntegrationEnum.CorreiosCearaPac, label: 'Correios Ceará - Pac' },
  { value: PartnerIntegrationEnum.CorreiosCearaSedex, label: 'Correios Ceará - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosCearaMiniEnvios, label: 'Correios Ceará - Mini Envios' },
  { value: PartnerIntegrationEnum.CorreiosBahiaPac, label: 'Correios Bahia - Pac' },
  { value: PartnerIntegrationEnum.CorreiosBahiaSedex, label: 'Correios Bahia - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosBahiaMiniEnvios, label: 'Correios Bahia - Mini Envios' },
  { value: PartnerIntegrationEnum.CorreiosPiauiPac, label: 'Correios Piauí - Pac' },
  { value: PartnerIntegrationEnum.CorreiosPiauiSedex, label: 'Correios Piauí - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosPiauiMiniEnvios, label: 'Correios Piauí - Mini Envios' },
  { value: PartnerIntegrationEnum.CorreiosSaoPauloPac, label: 'Correios São Paulo - Pac' },
  { value: PartnerIntegrationEnum.CorreiosSaoPauloSedex, label: 'Correios São Paulo - Sedex' },
  { value: PartnerIntegrationEnum.CorreiosSaoPauloMiniEnvios, label: 'Correios São Paulo - Mini Envios' },
]
