import Yup from 'utils/yup'

export const defaultValues = {
  day: null,
  month: null,
  description: null,
}

export const validationSchema = Yup.object().shape({
  day: Yup.number().nullable().required().max(31, 'Dia inválido'),
  month: Yup.number().nullable().required().max(12, 'Mês inválido'),
  description: Yup.string().nullable().required().max(60, 'A descrição  deve ter no máximo 60 caracteres'),
})
