import React from 'react'
import { Card, CardContent, Table, TableHeader, TableRow, TableCell, TableBody, Text } from 'react-md'

import { CalloutType } from 'components/core/callout'
import { formatCurrency } from 'types/core/currency'
import { DeleteButton } from 'components/data-table'
import { Cell, Callout } from 'components/core'

interface ComponentProps {
  volumes: Array<any>
  disabled: boolean
  removeVolume: (volumeIndex: number) => void
}

const Component: React.FC<ComponentProps> = ({ volumes, disabled, removeVolume }) => {
  return (
    <Cell>
      {volumes?.length === 0 && <Callout text={'Nenhum volume informado'} type={CalloutType.Info} />}
      {volumes && volumes.length > 0 && (
        <>
          <Cell>
            <Text type='headline-6' margin='none'>
              Volumes
            </Text>
          </Cell>
          <Card fullWidth>
            <CardContent>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Comprimento</TableCell>
                    <TableCell>Altura</TableCell>
                    <TableCell>Largura</TableCell>
                    <TableCell>Peso Real</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {volumes.map((volumeData: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{volumeData.quantity}</TableCell>
                      <TableCell>{volumeData.length}cm</TableCell>
                      <TableCell>{volumeData.height}cm</TableCell>
                      <TableCell>{volumeData.width}cm</TableCell>
                      <TableCell>{formatCurrency(volumeData.realWeight)}kg</TableCell>
                      <TableCell align='right'>
                        <DeleteButton id={`delete-${index}`} disabled={disabled} action={() => removeVolume(index)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </>
      )}
    </Cell>
  )
}

export default Component
