import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  initialDate: null,
  finalDate: null,
  status: null,
  notRouted: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  initialDate: Yup.date().nullable(),
  finalDate: Yup.date().nullable(),
  status: Yup.string().nullable(),
  notRouted: Yup.boolean().nullable(),
})
