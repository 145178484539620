import api from 'services/quote/client-fare'

import downloadFile from 'utils/download-file'

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}

export const handleDownloadCsv = async (request: any, fileName: string) => {
  let res = await api.downloadCsv(request)

  downloadFile(fileName, res?.data)
}
