import api from 'services/quote/partner'
import { AutocompleteResult } from 'types/core/autocomplete'

export const partnerAutocomplete = async (text: string): Promise<Array<AutocompleteResult>> => {
  if (!text) return []

  let res = await api.autocomplete({ text })

  if (!res?.data?.success) return []

  return res?.data?.data
}
