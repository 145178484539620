import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  trackingCode: null,
  orderNumber: null,
  fiscalNoteNumber: null,
  FiscalNoteAccessKey: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  trackingCode: Yup.string().nullable().max(30, 'O tracking deve ter no máximo 30 caracteres'),
  orderNumber: Yup.string().nullable().max(50, 'O núm. pedido deve ter no máximo 50 caracteres'),
  fiscalNoteNumber: Yup.string().nullable().max(30, 'O núm. nf deve ter no máximo 30 caracteres'),
  FiscalNoteAccessKey: Yup.string().nullable().max(45, 'A chave da nf deve ter no máximo 45 caracteres'),
})
