import React from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { InputNumber, handleSubmit } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  requestId: string
  disableModal: () => void
  loadRequest: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ requestId, disableModal, loadRequest }) => {
  const form = useForm({ defaultValues })

  return (
    <Modal
      title={'Atualizar Romaneio'}
      visible={true}
      width={500}
      confirmTitle={'Salvar'}
      confirmAction={async () =>
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ requestId: requestId, ...form.getValues() })) {
            form.reset(defaultValues, { dirty: false })
            disableModal()
            loadRequest()
          }
        })
      }
      confirmDisabled={!form.formState.dirty}
      closeTitle={'Fechar'}
      closeAction={async () => disableModal()}>
      <FormContext {...form}>
        <Grid>
          <InputNumber name='packingListNumber' label='Número do Romaneio *' />
        </Grid>
      </FormContext>
    </Modal>
  )
}

export default Component
