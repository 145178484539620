import api from 'services/sale/client'

export const handleSave = async (values: any) => {
  let res = await api.updateContact(values)

  return res?.data?.success
}

export const handleUpdateDetail = async (values: any) => {
  let res = await api.updateContactDetail(values)

  return res?.data?.success
}

export const handleRemoveDetail = async (clientId: string, contactId: string, detailId: string) => {
  let res = await api.removeContactDetail(clientId, contactId, detailId)

  return res?.data?.success
}

export const handleAddDepartment = async (values: any) => {
  let res = await api.addDepartmentToContactDetail(values)

  return res?.data?.success
}

export const handleRemoveDepartment = async (clientId: string, contactId: string, detailId: string, id: string) => {
  let res = await api.removeDepartmentFromContactDetail(clientId, contactId, detailId, id)

  return res?.data?.success
}
