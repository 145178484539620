import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, ListItem, useToggle } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess, ButtonEdit, Actions } from 'components/core'
import { handleSubmit, Input, Switch } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleGet, handleSave } from './actions'

import UpdatePassword from './components/update-password'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleUpdatePasswordModal, enableUpdatePasswordModal, disableUpdatePasswordModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Portador'

    return () => {}
  }, [])

  useEffect(() => {
    const load = async () => {
      const res = await handleGet(id)
      if (res) form.reset(res)
    }

    load()

    return () => {}
  }, [id])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Portador' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <Input name='name' label='Nome *' desktopSize={6} tabletSize={4} />
                  <Switch name='active' labelTrue='Ativo' labelFalse='Inativo' defaultChecked={false} inline desktopSize={6} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Salvar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                  })
                }
              />
              <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
              <ButtonLog entity='Carrier' id={id} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      {visibleUpdatePasswordModal && <UpdatePassword carrierId={id} disableModal={disableUpdatePasswordModal} />}

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>lock</FontIcon>} onClick={async () => enableUpdatePasswordModal()}>
          Atualizar Senha
        </ListItem>
      </Actions>
    </>
  )
}

export default Component
