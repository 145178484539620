import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, Grid, TableCell, TableRow } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { DataTable, EditButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'
import { handleSubmit, Input } from 'components/form'
import { ButtonSearch } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSearch, handleEdit } from './actions'

interface ComponentProps {
  clientCoverageId: string
}

const Component: React.FC<ComponentProps> = ({ clientCoverageId }) => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    if (clientCoverageId) setFilter({ clientCoverageId })

    return () => {}
  }, [clientCoverageId])

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <FormContext {...form}>
            <Grid>
              <Input name='name' label='Nome Fantasia' />
            </Grid>
          </FormContext>
        </CardContent>
        <CardActions>
          <ButtonSearch
            onClick={async () => await handleSubmit(form, validationSchema, () => setFilter({ clientCoverageId, ...form.getValues() }))}
          />
        </CardActions>
      </Card>

      <div style={{ marginTop: 10 }}>
        <DataTable
          filter={filter}
          header={[{ label: 'Nome Fantasia' }, { label: '' }]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}
          showEmptyMessageAlert={false}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell grow>{data.clientFantasyName}</TableCell>
                <TableCell align='right'>
                  <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                </TableCell>
              </TableRow>
            ))}
        </DataTable>
      </div>
    </>
  )
}

export default Component
