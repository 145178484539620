import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'
import { Grid, Text } from 'react-md'

import { Input, InputDocument } from 'components/form'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Grid>
        <Cell>
          <Text type='headline-6' margin='none'>
            Dados Bancários
          </Text>
        </Cell>
        <Cell>
          <Grid>
            <Input name='bankName' label='Nome do Banco' desktopSize={6} tabletSize={4} />
            <Input name='agencyNumber' label='Número da Agência' desktopSize={3} tabletSize={2} />
            <Input name='accountNumber' label='Número da Conta' desktopSize={3} tabletSize={2} />
            <Input name='beneficiaryName' label='Nome do Beneficiário' desktopSize={6} tabletSize={2} />
            <InputDocument name='document' label='Cpf/Cnpj' maxLength={18} desktopSize={3} tabletSize={2} />
            <Input name='pixKey' label='Chave Pix' desktopSize={3} tabletSize={4} />
          </Grid>
        </Cell>
      </Grid>
    </FormContext>
  )
}

export default Component
