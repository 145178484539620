import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, FontIcon, useToggle, ListItem, TabsManager, Tabs, TabPanels, TabPanel } from 'react-md'

import { getPickupRequestStatusDescription, PickupRequestStatusEnum } from 'types/pickup/pickup-request-status'
import { Cell, PageTitle, ButtonLog, ButtonSuccess, Actions, ButtonEdit, Callout } from 'components/core'
import { DateLabel, DateTimeLabel, NumberLabel, TextLabel, TimeLabel } from 'components/label'
import { addressDefaultValues } from 'components/address/config'
import Address from 'components/address'

import { handleGet } from './actions'

import AddInPackingList from './components/add-in-packing-list'
import { CalloutType } from 'components/core/callout'
import Conclude from './components/conclude'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const addressForm = useForm({ defaultValues: addressDefaultValues })
  const [activeTab, setActiveTab] = useState<number>(0)
  const [pickupRequest, setPickupRequest] = useState<any>()
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleAddInPackingListModal, enableAddInPackingListModal, disableAddInPackingListModal] = useToggle(false)
  const [visibleConcludeModal, enableConcludeModal, disableConcludeModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Solicitação de Coleta'

    load()

    return () => {}
  }, [id])

  const load = async () => {
    const res = await handleGet(id)
    if (res) {
      addressForm.reset(res.address)
      setPickupRequest(res)
    }
  }

  const openMap = (geolocation: string) => {
    const lat = geolocation.split(',')[0]
    const lng = geolocation.split(',')[1]

    window.open('https://www.google.com/maps/place/' + lat + ',' + lng + '/' + lat + ',' + lng)
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Solicitação de Coleta' />
          <TabsManager
            tabs={['Solicitação', 'Conclusão']}
            activeIndex={activeTab}
            onActiveIndexChange={activeIndexNumber => setActiveTab(activeIndexNumber)}
            tabsId='packing-list-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <Card fullWidth>
                  <CardContent>
                    <Grid>
                      <NumberLabel
                        name='requestNumber'
                        label='Número da Solicitação'
                        value={pickupRequest?.requestNumber}
                        desktopSize={2}
                        tabletSize={3}
                      />
                      <NumberLabel
                        name='packingListNumber'
                        label='Romaneio'
                        value={pickupRequest?.packingListNumber}
                        desktopSize={2}
                        tabletSize={3}
                      />
                      <TextLabel
                        name='status'
                        label='Status'
                        value={getPickupRequestStatusDescription(pickupRequest?.status)}
                        desktopSize={2}
                        tabletSize={3}
                      />
                      <DateLabel name='date' label='Data' value={pickupRequest?.date} desktopSize={2} tabletSize={2} />
                      <TimeLabel
                        name='pickupStartTime'
                        label='Horário Inicial'
                        value={pickupRequest?.pickupStartTime}
                        desktopSize={2}
                        tabletSize={3}
                      />
                      <TimeLabel name='pickupFinalTime' label='Horário Final' value={pickupRequest?.pickupFinalTime} desktopSize={2} tabletSize={3} />
                    </Grid>
                    <Address form={addressForm} disabled />
                  </CardContent>
                  <CardActions>
                    <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                    <ButtonLog entity='PickupRequest' id={id} />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel>
                {!pickupRequest?.dateCheckin && !pickupRequest?.conclude?.date ? (
                  <Callout text={'Solicitação de coleta não concluída'} type={CalloutType.Info} />
                ) : (
                  <Card fullWidth>
                    <CardContent>
                      <Grid>
                        <DateTimeLabel name='dateCheckin' label='Data do Checkin' value={pickupRequest?.dateCheckin} desktopSize={2} tabletSize={4} />
                        {pickupRequest?.conclude && (
                          <>
                            <DateLabel name='date' label='Data da Coleta' value={pickupRequest?.conclude?.date} desktopSize={2} tabletSize={4} />
                            <TextLabel
                              name='status'
                              label='Status'
                              value={getPickupRequestStatusDescription(pickupRequest?.conclude?.status)}
                              desktopSize={2}
                              tabletSize={4}
                            />
                            <NumberLabel
                              name='volumeQuantity'
                              label='Qtd. de Volumes'
                              value={pickupRequest?.conclude?.volumeQuantity}
                              desktopSize={2}
                              tabletSize={4}
                            />
                            <TextLabel
                              name='responsible'
                              label='Responsável'
                              value={pickupRequest?.conclude?.responsible}
                              desktopSize={4}
                              tabletSize={8}
                            />
                            <TextLabel
                              name='observation'
                              label='Observação'
                              value={pickupRequest?.conclude?.observation}
                              desktopSize={12}
                              tabletSize={8}
                            />
                          </>
                        )}
                      </Grid>
                    </CardContent>
                    {pickupRequest?.conclude?.geolocation && (
                      <CardActions>
                        <ButtonSuccess
                          text='Abrir Mapa'
                          icon={<FontIcon>map</FontIcon>}
                          onClick={async () => openMap(pickupRequest?.conclude?.geolocation)}
                        />
                      </CardActions>
                    )}
                  </Card>
                )}
              </TabPanel>
            </TabPanels>
          </TabsManager>
        </Cell>
      </Grid>

      {visibleAddInPackingListModal && <AddInPackingList requestId={id} disableModal={disableAddInPackingListModal} loadRequest={load} />}

      {visibleConcludeModal && <Conclude requestId={id} disableModal={disableConcludeModal} loadRequest={load} />}

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem
          leftAddon={<FontIcon>playlist_add</FontIcon>}
          disabled={pickupRequest?.status !== PickupRequestStatusEnum.Opened && pickupRequest?.status !== PickupRequestStatusEnum.Running}
          onClick={async () => enableAddInPackingListModal()}>
          Atualizar Romaneio
        </ListItem>

        {pickupRequest?.packingListNumber >= 0 && (
          <ListItem
            leftAddon={<FontIcon>verified</FontIcon>}
            disabled={pickupRequest?.status !== PickupRequestStatusEnum.Running}
            onClick={async () => enableConcludeModal()}>
            Concluir Solicitação
          </ListItem>
        )}
      </Actions>
    </>
  )
}

export default Component
