import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { handleSubmit, Input, InputNumber } from 'components/form'
import ButtonSuccess from 'components/core/button-success'
import { Cell, PageTitle } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'
import { format, formatISO, lightFormat, parseISO } from 'date-fns'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Novo Feriado'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Feriado' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <InputNumber name='day' label='Dia *' desktopSize={2} tabletSize={2} maxLength={2} />
                <InputNumber name='month' label='Mês *' desktopSize={2} tabletSize={2} maxLength={2} />
                <Input name='description' label='Descrição *' desktopSize={8} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave(form.getValues())) form.reset(defaultValues, { dirty: false })
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
