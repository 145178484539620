import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useToggle } from 'react-md'

import { ConfirmDialog } from 'components/core'
import { history } from 'utils/history'

import { handleSave } from './actions'

const Component: React.FC = () => {
  const location = useLocation()
  const [visibleCreate, enableCreate, disableCreate] = useToggle(true)

  useEffect(() => {
    document.title = 'Novo Romaneio de Coleta'

    if (location.pathname.endsWith('/redirect')) history.push('/delivery/delivery-packing-list/new')
    else enableCreate()

    return () => {}
  }, [])

  return (
    <ConfirmDialog
      text='Deseja realmente criar o romaneio de entrega?'
      visible={visibleCreate}
      confirmAction={async () => {
        disableCreate()
        let res = await handleSave({})
        if (res?.success) window.open(`/delivery/delivery-packing-list/${res.data}/edit`, '_self')
      }}
      cancelAction={async () => disableCreate()}
    />
  )
}

export default Component
