import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import DeliveryPackingListEdit from 'pages/delivery/delivery-packing-list/edit'
import DeliveryPackingListNew from 'pages/delivery/delivery-packing-list/new'
import DeliveryPackingListSearch from 'pages/delivery/delivery-packing-list/search'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.delivery.deliveryPackingList && (
        <>
          <Route path='/delivery/delivery-packing-list/:id/edit' exact component={DeliveryPackingListEdit} />
          <Route path='/delivery/delivery-packing-list/new' exact component={DeliveryPackingListNew} />
          <Route path='/delivery/delivery-packing-list/new/redirect' exact component={DeliveryPackingListNew} />
          <Route path='/delivery/delivery-packing-list/search' exact component={DeliveryPackingListSearch} />
        </>
      )}
    </>
  )
}

export default Routes
