import Cookies from 'universal-cookie'

import { Theme } from 'contexts/auth/types'
import api from 'services/system/user'

export const handleGet = async () => {
  let res = await api.getMe()

  if (res?.data?.data) {
    if (res.data.data.userTheme === 'Dark') res.data.data.userTheme = 2
    else res.data.data.userTheme = 1
  }

  return res?.data?.data
}

export const handleSave = async (values: any, logout: () => void, setTheme: React.Dispatch<React.SetStateAction<Theme | null>>) => {
  let cookies = new Cookies()
  let res = await api.updateMe(values)

  if (res && res.data.success) {
    logout()
    cookies.set('theme', parseInt(values.userTheme) === 2 ? 'Dark' : 'Light', { path: '/' })
    setTheme(parseInt(values.userTheme) === 2 ? Theme.Dark : Theme.Light)
  } else {
    return false
  }
}
