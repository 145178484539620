import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import ClientCoverageEdit from 'pages/quote/client-coverage/edit'
import ClientCoverageNew from 'pages/quote/client-coverage/new'
import ClientCoverageSearch from 'pages/quote/client-coverage/search'
import ClientCoverageSearchCoverage from 'pages/quote/client-coverage/search-coverage'

import ClientFareEdit from 'pages/quote/client-fare/edit'
import ClientFareNew from 'pages/quote/client-fare/new'
import ClientFareSearch from 'pages/quote/client-fare/search'
import ClientFareSearchFare from 'pages/quote/client-fare/search-fare'

import GrisEdit from 'pages/quote/gris/edit'
import GrisNew from 'pages/quote/gris/new'
import GrisSearch from 'pages/quote/gris/search'

import PartnerEdit from 'pages/quote/partner/edit'
import PartnerNew from 'pages/quote/partner/new'
import PartnerSearch from 'pages/quote/partner/search'
import PartnerSearchCoverage from 'pages/quote/partner/search-coverage'
import PartnerSearchFare from 'pages/quote/partner/search-fare'

import IcmsList from 'pages/quote/icms/list'

import IssImport from 'pages/quote/iss/import'
import IssSearch from 'pages/quote/iss/search'

import QuoteClient from 'pages/quote/quote/client'
import QuoteFm from 'pages/quote/quote/fm'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.quote.quote.client && (
        <>
          <Route path='/quote/client' exact component={QuoteClient} />
        </>
      )}

      {menuPermission?.quote.clientCoverage && (
        <>
          <Route path='/quote/client-coverage/:id/edit' exact component={ClientCoverageEdit} />
          <Route path='/quote/client-coverage/new' exact component={ClientCoverageNew} />
          <Route path='/quote/client-coverage/search' exact component={ClientCoverageSearch} />
          <Route path='/quote/client-coverage/search-coverage' exact component={ClientCoverageSearchCoverage} />
        </>
      )}

      {menuPermission?.quote.clientFare && (
        <>
          <Route path='/quote/client-fare/:id/edit' exact component={ClientFareEdit} />
          <Route path='/quote/client-fare/new' exact component={ClientFareNew} />
          <Route path='/quote/client-fare/search' exact component={ClientFareSearch} />
          <Route path='/quote/client-fare/search-fare' exact component={ClientFareSearchFare} />
        </>
      )}

      {menuPermission?.quote.gris && (
        <>
          <Route path='/quote/gris/:id/edit' exact component={GrisEdit} />
          <Route path='/quote/gris/new' exact component={GrisNew} />
          <Route path='/quote/gris/search' exact component={GrisSearch} />
        </>
      )}

      {menuPermission?.quote.partner && (
        <>
          <Route path='/quote/partner/:id/edit' exact component={PartnerEdit} />
          <Route path='/quote/partner/new' exact component={PartnerNew} />
          <Route path='/quote/partner/search' exact component={PartnerSearch} />
          <Route path='/quote/partner/search-coverage' exact component={PartnerSearchCoverage} />
          <Route path='/quote/partner/search-fare' exact component={PartnerSearchFare} />
        </>
      )}

      {menuPermission?.quote.icms && (
        <>
          <Route path='/quote/icms' exact component={IcmsList} />
        </>
      )}

      {menuPermission?.quote.iss && (
        <>
          <Route path='/quote/iss/import' exact component={IssImport} />
          <Route path='/quote/iss/search' exact component={IssSearch} />
        </>
      )}

      {menuPermission?.quote.quote.fm && (
        <>
          <Route path='/quote/fm' exact component={QuoteFm} />
        </>
      )}
    </>
  )
}

export default Routes
