import Yup from 'utils/yup'

export const defaultValues = {
  route: {
    id: null,
  },
}

export const validationSchema = Yup.object().shape({
  route: Yup.object({
    id: Yup.string().nullable().required(),
  }),
})
