import Yup from 'utils/yup'

export const defaultValues = {
  token: null,
  extraConfiguration1: null,
  standardServiceId: null,
  expressServiceId: null,
  medioRodoServiceId: null,
}

export const validationSchema = Yup.object().shape({
  token: Yup.string().nullable().required(),
  extraConfiguration1: Yup.string().nullable().required().max(60, 'O id logística deve ter no máximo 60 caracteres'),
  standardServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('standard-service-id', 'Informe um código de serviço', function (value) {
      return this.parent.expressServiceId || this.parent.medioRodoServiceId || value
    }),
  expressServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('express-service-id', 'Informe um código de serviço', function (value) {
      return this.parent.standardServiceId || this.parent.medioRodoServiceId || value
    }),
  medioRodoServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('medio-rodo-service-id', 'Informe um código de serviço', function (value) {
      return this.parent.expressServiceId || this.parent.standardServiceId || value
    }),
})
