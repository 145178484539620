import { useEffect } from 'react'
import { FormMessage, Chip } from 'react-md'
import { useFormContext } from 'react-hook-form'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

export const ChipBoolean = ({ name, label, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const form = useFormContext()
  const value = form.watch(name)

  useEffect(() => {
    form.register({ name })

    return () => {}
  }, [form.register])

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <Chip
        selected={value}
        selectedThemed
        onClick={() => {
          if (form.errors[name]) form.clearError(name)
          form.setValue(name, !value)
        }}
        style={{ width: '100%', marginRight: 3 }}
        {...rest}>
        {label}
      </Chip>
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
