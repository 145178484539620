import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, Grid, Table, TableBody, TableCell, TableHeader, TableRow, Text, useToggle } from 'react-md'

import { ButtonSuccess, Callout, Cell, ConfirmDialog } from 'components/core'
import { getIntegrationDescription } from 'types/integration/integration'
import { DeleteButton, EditButton } from 'components/data-table'
import { CalloutType } from 'components/core/callout'

import { handleGetIntegrations, handleRemoveIntegration } from './actions'

import EditIntegration from './components/edit-integration'
import AddIntegration from './components/add-integration'

interface ComponentProps {
  clientId: string
}

const Component: React.FC<ComponentProps> = ({ clientId }) => {
  const [integrations, setIntegrations] = useState<Array<any> | null>(null)
  const [integrationId, setIntegrationId] = useState<string>()
  const [visibleAddModal, enableAddModal, disableAddModal] = useToggle(false)
  const [visibleEditModal, enableEditModal, disableEditModal] = useToggle(false)
  const [visibleDeleteModal, enableDeleteModal, disableDeleteModal] = useToggle(false)

  useEffect(() => {
    loadIntegrations()

    return () => {}
  }, [clientId])

  const loadIntegrations = async () => setIntegrations(await handleGetIntegrations(clientId))

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <Grid>
            <Cell>
              <Text type='headline-6' margin='none'>
                Integrações
              </Text>
            </Cell>
            <Cell>
              {integrations?.length === 0 && <Callout text={'Nenhuma integração localizada'} type={CalloutType.Info} />}
              {integrations && integrations.length > 0 && (
                <Card fullWidth>
                  <CardContent>
                    <Table fullWidth>
                      <TableHeader>
                        <TableRow>
                          <TableCell>Integração</TableCell>
                          <TableCell>Notfis</TableCell>
                          <TableCell>Ocoren</TableCell>
                          <TableCell>Ativo</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {integrations.map((integrationData: any, index) => (
                          <TableRow key={index}>
                            <TableCell>{getIntegrationDescription(integrationData.integration)}</TableCell>
                            <TableCell>{integrationData.notfis ? 'Sim' : 'Não'}</TableCell>
                            <TableCell>{integrationData.ocoren ? 'Sim' : 'Não'}</TableCell>
                            <TableCell>{integrationData.active ? 'Sim' : 'Não'}</TableCell>
                            <TableCell align='right'>
                              <EditButton
                                id={`edit-${index}`}
                                action={() => {
                                  setIntegrationId(integrationData.id)
                                  enableEditModal()
                                }}
                              />
                              <DeleteButton
                                id={`delete-${index}`}
                                action={() => {
                                  setIntegrationId(integrationData.id)
                                  enableDeleteModal()
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              )}
            </Cell>
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonSuccess text='Nova Integração' icon={<FontIcon>add</FontIcon>} onClick={async () => enableAddModal()} />
        </CardActions>
      </Card>

      <AddIntegration clientId={clientId} visibleModal={visibleAddModal} disableModal={disableAddModal} loadIntegrations={loadIntegrations} />

      {visibleEditModal && (
        <EditIntegration
          clientId={clientId}
          integrationId={integrationId}
          visibleModal={visibleEditModal}
          disableModal={disableEditModal}
          loadIntegrations={loadIntegrations}
        />
      )}

      {visibleDeleteModal && (
        <ConfirmDialog
          text='Deseja realmente excluir a integração? Essa ação não poderá ser desfeita'
          visible={true}
          confirmAction={async () => {
            if (integrationId)
              if (await await handleRemoveIntegration(clientId, integrationId)) {
                setIntegrationId(undefined)
                disableDeleteModal()
                await loadIntegrations()
              }
          }}
          cancelAction={async () => {
            setIntegrationId(undefined)
            disableDeleteModal()
          }}
        />
      )}
    </>
  )
}

export default Component
