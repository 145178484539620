import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow, TableContainer, Table, TableHeader, TableBody, useToggle } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSearch, Callout, ConfirmDialog, ButtonClearSearch } from 'components/core'
import { DeleteButton, EditButton } from 'components/data-table'
import { ClientAutoComplete } from 'components/auto-complete'
import { CalloutType } from 'components/core/callout'
import { EmptyResultMessage } from 'types/core/api'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSearch, handleDiscard } from './actions'

import FixImportValidation from './fix-import-validation'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [validation, setValidation] = useState<any>()
  const [validations, setValidations] = useState<any>()
  const [visibleFixModal, enableFixModal, disableFixModal] = useToggle(false)
  const [visibleDeleteModal, enableDeleteModal, disableDeleteModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Pacotes Inválidos'

    return () => {}
  }, [])

  const search = async () => {
    await handleSubmit(form, validationSchema, async () => {
      let res = await handleSearch(form.getValues())
      if (res?.length === 0) toast.info(EmptyResultMessage)
      setValidations(res)
    })
  }

  const discard = async (clientId: string, id: string) => {
    if (await handleDiscard({ clientId: clientId, id: id })) search()
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pacotes Inválidos' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente *' desktopSize={3} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch disabled={!form.formState.dirty} onClick={async () => await search()} />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
            </CardActions>
          </Card>
        </Cell>
        {validations?.length === 0 && (
          <Cell>
            <Callout text={EmptyResultMessage} type={CalloutType.Info} />
          </Cell>
        )}
        {validations?.length > 0 && (
          <Cell>
            <Card fullWidth>
              <CardContent>
                <TableContainer>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Destinatário</TableCell>
                        <TableCell>Núm. Pedido</TableCell>
                        <TableCell>Núm. NF</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {validations.map((data: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{data.clientFantasyName}</TableCell>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.orderNumber}</TableCell>
                          <TableCell>{data.fiscalNoteNumber}</TableCell>
                          <TableCell>Com Problema</TableCell>
                          <TableCell align='right'>
                            <EditButton
                              id={`edit-${index}`}
                              tooltip={'Corrigir Pedido'}
                              action={() => {
                                setValidation(data)
                                enableFixModal()
                              }}
                            />
                            <DeleteButton
                              id={`edit-${index}`}
                              tooltip={'excluir Pedido'}
                              action={() => {
                                setValidation(data)
                                enableDeleteModal()
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Cell>
        )}
      </Grid>

      <FixImportValidation
        validation={validation}
        visibleModal={visibleFixModal}
        disableModal={() => disableFixModal()}
        onSuccess={() => {
          setValidation(null)
          search()
        }}
      />

      <ConfirmDialog
        text='Deseja realmente excluir o pedido? Essa ação não poderá ser desfeita'
        visible={visibleDeleteModal}
        confirmAction={async () => {
          setValidation(undefined)
          disableDeleteModal()
          if (validation) await discard(validation.clientId, validation.id)
        }}
        cancelAction={async () => {
          setValidation(undefined)
          disableDeleteModal()
        }}
      />
    </>
  )
}

export default Component
