import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { ClientCoverageAutoComplete, ClientFareAutoComplete } from 'components/auto-complete'
import { handleSubmit, Select } from 'components/form'
import { ServiceOptions } from 'types/quote/service'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  clientId: string
  visibleModal: boolean
  disableModal: () => void
  loadServices: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ clientId, visibleModal, disableModal, loadServices }) => {
  const form = useForm({ defaultValues })

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
  }

  return (
    <Modal
      title={'Adicionar Serviço'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ clientId, ...form.getValues() })) {
            await loadServices()
            clear()
            disableModal()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <Select name='service' label='Serviço *' options={ServiceOptions} desktopSize={4} tabletSize={2} />
          <ClientFareAutoComplete name='clientFare' label='Tarifa *' desktopSize={4} tabletSize={3} />
          <ClientCoverageAutoComplete name='clientCoverage' label='Abrangência *' desktopSize={4} tabletSize={3} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
