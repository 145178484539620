import Yup from 'utils/yup'

export const defaultValues = {
  partner: {
    id: null,
  },
  fareCode: null,
}

export const validationSchema = Yup.object().shape({
  partner: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  fareCode: Yup.string().nullable().max(4, 'A tarifa deve ter no máximo 4 caracteres'),
})
