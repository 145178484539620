import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Table, TableHeader, TableRow, TableCell, TableBody, TableContainer, CardActions, FontIcon } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'
import { format, parseISO } from 'date-fns'

import { Cell, PageTitle, Callout, ConfirmDialog, ButtonSuccess } from 'components/core'
import { CronTaskOptions, getCronTaskDescription } from 'types/system/cron-task'
import { Select, handleSubmit } from 'components/form'
import { CalloutType } from 'components/core/callout'
import { DeleteButton } from 'components/data-table'

import { handleDelete, handleList, handleSave } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [blockTask, setBlockTask] = useState<string>()
  const [blockTasks, setBlockTasks] = useState<Array<any> | null>(null)

  useEffect(() => {
    document.title = 'Bloqueio de Tarefas'

    load()

    return () => {}
  }, [])

  const load = async () => setBlockTasks(await handleList())

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Bloqueio de Tarefas' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <Select name='task' label='Tarefa *' options={CronTaskOptions} form={form} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Bloquear'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () => {
                  await handleSubmit(form, validationSchema, async () => {
                    if (await handleSave(form.getValues())) await load()
                    form.reset(defaultValues, { dirty: false })
                  })
                }}
              />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          {blockTasks?.length === 0 && <Callout text={'Nenhum bloqueio de tarefa localizado'} type={CalloutType.Info} />}
          {blockTasks != null && blockTasks?.length !== 0 && (
            <Card fullWidth>
              <CardContent>
                <TableContainer>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Tarefa</TableCell>
                        <TableCell>Bloqueada Em</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {blockTasks?.map((data: any, index) => (
                        <TableRow key={index}>
                          <TableCell>{getCronTaskDescription(data.task)}</TableCell>
                          <TableCell>{format(parseISO(data.blockedAt), "dd'/'MM'/'yyyy' 'HH:mm:ss")}</TableCell>
                          <TableCell align='right'>
                            <DeleteButton id={`delete-${index}`} action={() => setBlockTask(data.task)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
        </Cell>
      </Grid>

      <ConfirmDialog
        text='Deseja realmente desbloquear a tarefa?'
        visible={blockTask ? true : false}
        confirmAction={async () => {
          setBlockTask(undefined)
          if (blockTask && (await handleDelete(blockTask))) await load()
        }}
        cancelAction={async () => setBlockTask(undefined)}
      />
    </>
  )
}

export default Component
