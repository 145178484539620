import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { DateLabel, DecimalLabel, NumberLabel } from 'components/label'
import { Cell } from 'components/core'

interface ComponentProps {
  order: any
}

const Component: React.FC<ComponentProps> = ({ order }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações Financeiras
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <DecimalLabel name='deliveryFreightAmount' label='Frete Entrega' value={order.deliveryFreightAmount} desktopSize={4} tabletSize={3} />
          <DateLabel name='deliveryInvoiceDate' label='Data Faturamento Entrega' value={order.deliveryInvoiceDate} desktopSize={4} tabletSize={3} />
          <NumberLabel name='deliveryCte' label='Núm. Cte Entrega' value={order.deliveryCte} desktopSize={4} tabletSize={2} />
          <DecimalLabel name='returnFreightAmount' label='Frete Devolução' value={order.returnFreightAmount} desktopSize={4} tabletSize={3} />
          <DateLabel name='returnInvoiceDate' label='Data Faturamento Devolução' value={order.returnInvoiceDate} desktopSize={4} tabletSize={3} />
          <NumberLabel name='returnCte' label='Núm. Cte Devolução' value={order.returnCte} desktopSize={4} tabletSize={2} />
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
