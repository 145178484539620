import Yup from 'utils/yup'

export const bankDataDefaultValues = {
  bankName: null,
  agencyNumber: null,
  accountNumber: null,
  beneficiaryName: null,
  document: null,
  pixKey: null,
}

export const bankDataValidationSchema = Yup.object().shape({
  bankName: Yup.string().nullable().max(20, 'O nome do banco deve ter no máximo 60 caracteres'),
  agencyNumber: Yup.string().nullable().max(10, 'O número da agência deve ter no máximo 60 caracteres'),
  accountNumber: Yup.string().nullable().max(15, 'O número da conta deve ter no máximo 60 caracteres'),
  beneficiaryName: Yup.string().nullable().max(60, 'O beneficiário deve ter no máximo 60 caracteres'),
  document: Yup.string().nullable().max(14, 'O cpf/cnpj deve ter no máximo 60 caracteres'),
  pixKey: Yup.string().nullable().max(80, 'O pix deve ter no máximo 60 caracteres'),
})
