import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Novo Portador'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Portador' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Input name='name' label='Nome *' desktopSize={3} tabletSize={4} />
                <Input name='login' label='Login *' desktopSize={3} tabletSize={4} />
                <Input name='password' label='Senha *' type='password' desktopSize={3} tabletSize={4} />
                <Input name='confirmPassword' label='Confirmação de Senha *' type='password' desktopSize={3} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave(form.getValues())) form.reset(defaultValues, { dirty: false })
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
