import React, { useEffect } from 'react'
import { CircularProgress, Grid } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'
import { usePromiseTracker } from 'react-promise-tracker'
import { toast } from 'react-toastify'

import { InputNumber, Select } from 'components/form'
import { Input } from 'components/form'

import { ZipCode } from './components/zip-code'

import { getIbgeInfoByZipCode } from './actions'
import { StateOptions } from './types'

interface ComponentProps {
  form: FormContextValues<any>
  disabled?: boolean
}

const Address: React.FC<ComponentProps> = ({ form, disabled }) => {
  const { promiseInProgress } = usePromiseTracker()
  const { zipCode, state, cityIbgeCode, city } = form.watch(['zipCode', 'state', 'cityIbgeCode', 'city'])

  useEffect(() => {
    const search = async () => {
      if (zipCode && zipCode.toString().length >= 7 && !state && !cityIbgeCode && !city) {
        getIbgeInfoByZipCode(parseInt(zipCode)).then(res => {
          if (res) {
            let values = form.getValues()
            values.state = res.state
            values.cityIbgeCode = res.cityIbgeCode
            values.city = res.city
            form.reset(values, { dirty: true })
          } else {
            toast.warn('Cep não localizado')
          }
        })
      }
    }

    search()

    return () => {}
  }, [zipCode])

  const clear = () => {
    let values = form.getValues()
    values.state = null
    values.cityIbgeCode = null
    values.city = null
    form.reset(values)
    form.clearError(['state', 'cityIbgeCode', 'city'])
  }

  return (
    <Grid>
      <FormContext {...form}>
        <ZipCode
          name='zipCode'
          label='Cep *'
          rightChildren={!disabled && promiseInProgress && <CircularProgress id={'zipCode-circular-progress'} small />}
          maxLength={8}
          desktopSize={2}
          tabletSize={4}
          clear={disabled ? undefined : clear}
          disabled={disabled}
        />
        <Select name='state' label='Estado *' options={StateOptions} desktopSize={2} tabletSize={4} disabled />
        <InputNumber name='cityIbgeCode' label='Cód. Ibge da Cidade *' maxLength={7} desktopSize={2} tabletSize={4} disabled />
        <Input name='city' label='Cidade *' desktopSize={3} tabletSize={4} disabled />
        <Input name='neighborhood' label='Bairro *' desktopSize={3} tabletSize={4} disabled={disabled} />
        <Input name='street' label='Logradouro *' desktopSize={6} tabletSize={4} disabled={disabled} />
        <Input name='number' label='Número *' desktopSize={2} tabletSize={2} disabled={disabled} />
        <Input name='complement' label='Complemento' desktopSize={4} tabletSize={2} disabled={disabled} />
      </FormContext>
    </Grid>
  )
}

export default React.memo(Address)
