import { TextField } from 'react-md'

import Cell from 'components/core/cell'
import { FieldProps } from 'components/form/types'
import { format, parseISO } from 'date-fns'

interface LabelProps extends FieldProps {
  label: string
  value: Date | string | null | undefined
}

export const DateLabel = ({ name, label, value, desktopSize, tabletSize }: LabelProps) => {
  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        disabled={true}
        label={label}
        value={value ? format(parseISO(String(value)), "dd'/'MM'/'yyyy") : ''}
      />
    </Cell>
  )
}
