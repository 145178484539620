import apiPackingList from 'services/pickup/pickup-packing-list'
import apiRequest from 'services/pickup/pickup-request'

export const handleList = async (packingListId: any) => {
  let res = await apiRequest.getPackingListSequence(packingListId)

  return res?.data?.data ?? []
}

export const handleSave = async (values: any) => {
  let res = await apiPackingList.updateSequence(values)

  return res?.data?.success
}
