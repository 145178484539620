import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/order/change-partner/lot'
const Api = {
  changePartnerLot: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
}

export default Api
