export const getDepartmentDescription = (status: string): string => {
  return DepartmentOptions.filter(x => x.value === status.toString())[0]?.label
}

export interface DepartmentOption {
  value: DepartmentEnum | null
  label: string
}

export enum DepartmentEnum {
  LogisticsAndShipping = 'LogisticsAndShipping',
  Financial = 'Financial',
  Legal = 'Legal',
  ItAndIntegrations = 'ItAndIntegrations',
  BoardDirectors = 'BoardDirectors',
}

export const DepartmentOptions: Array<DepartmentOption> = [
  { value: DepartmentEnum.BoardDirectors, label: 'Diretoria' },
  { value: DepartmentEnum.Financial, label: 'Financeiro' },
  { value: DepartmentEnum.Legal, label: 'Jurídico' },
  { value: DepartmentEnum.LogisticsAndShipping, label: 'Logística e Expedição' },
  { value: DepartmentEnum.ItAndIntegrations, label: 'TI e Integrações' },
]
