export interface SearchLotClientFieldList {
  value: SearchLotClientFieldEnum | null
  label: string
}

export enum SearchLotClientFieldEnum {
  OrderNumber = 'OrderNumber',
  FiscalNoteNumber = 'FiscalNoteNumber',
  TrackingCode = 'TrackingCode',
  RecipientDocument = 'RecipientDocument',
  FiscalNoteAccessKey = 'FiscalNoteAccessKey',
}

export const SearchLotClientFieldOptions: Array<SearchLotClientFieldList> = [
  { value: null, label: '' },
  { value: SearchLotClientFieldEnum.OrderNumber, label: 'Núm. Pedido' },
  { value: SearchLotClientFieldEnum.FiscalNoteNumber, label: 'Núm. NF' },
  { value: SearchLotClientFieldEnum.FiscalNoteAccessKey, label: 'Chave NF' },
  { value: SearchLotClientFieldEnum.TrackingCode, label: 'Tracking' },
  { value: SearchLotClientFieldEnum.RecipientDocument, label: 'Cpf/Cnpj' },
]
