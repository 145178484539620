import api from 'services/quote/partner'

import downloadFile from 'utils/download-file'

export const handleImport = async (partnerId: string, fileName: string, file: any): Promise<Array<string> | null> => {
  let res = await api.uploadCoverage({ partnerId, fileName, file })

  return res?.data?.success
}

export const handleGetCoverageCsv = async (fileName: string) => {
  let res = await api.getCoverageCsv()

  downloadFile(fileName, res?.data)
}
