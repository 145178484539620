import { toast } from 'react-toastify'

import { handleValidate } from 'components/form/form-submit'
import api from 'services/order/order-import-manual'
import { ValidationMessage } from 'types/core/api'
import { FormList } from 'types/core/form'

export const handleValidateForms = async (form: FormList, addressForm: FormList): Promise<boolean> => {
  let success = true

  if (!(await handleValidate(form.form, form.validationSchema))) success = false
  if (!(await handleValidate(addressForm.form, addressForm.validationSchema))) success = false

  if (!success) toast.warn(ValidationMessage)

  return success
}

export const handleImport = async (values: any): Promise<Array<string> | null> => {
  let res = await api.importManual(values)

  return res?.data?.success
}
