export interface SearchClientFieldList {
  value: SearchClientFieldEnum | null
  label: string
}

export enum SearchClientFieldEnum {
  RecipientName = 'RecipientName',
  RecipientDocument = 'RecipientDocument',
  OrderNumber = 'OrderNumber',
  FiscalNoteNumber = 'FiscalNoteNumber',
  TrackingCode = 'TrackingCode',
}

export const SearchClientFieldOptions: Array<SearchClientFieldList> = [
  { value: null, label: '' },
  { value: SearchClientFieldEnum.RecipientName, label: 'Nome do Destinatário' },
  { value: SearchClientFieldEnum.RecipientDocument, label: 'Cpf/Cnpj' },
  { value: SearchClientFieldEnum.OrderNumber, label: 'Núm. Pedido' },
  { value: SearchClientFieldEnum.FiscalNoteNumber, label: 'Núm. NF' },
  { value: SearchClientFieldEnum.TrackingCode, label: 'Tracking' },
]
