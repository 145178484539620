import Yup from 'utils/yup'

export const defaultValues = {
  partner: {
    id: null,
  },
}

export const validationSchema = Yup.object().shape({
  partner: Yup.object({
    id: Yup.string().nullable().required(),
  }),
})
