import api from 'services/quote/iss'

import downloadFile from 'utils/download-file'

export const handleSave = async (fileName: string | null | undefined, file: any): Promise<Array<string> | null> => {
  let response = await api.import({ fileName, file })

  return response.success ? null : response.data.data?.map((val: any) => val.message)
}

export const handleGetCsv = async (fileName: string) => {
  let res = await api.getCsv()

  downloadFile(fileName, res?.data)
}
