import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, FontIcon, useToggle } from 'react-md'

import { Cell, PageTitle, ButtonLog, ButtonSuccess, ButtonEdit, ConfirmDialog } from 'components/core'
import { handleSubmit, Input, Select, SelectBoolean } from 'components/form'
import { InvoicingPeriodOptions } from 'types/sale/invoicing-period'
import { CompanySelect } from 'components/select'
import { InputDecimal } from 'components/form'
import { TextLabel } from 'components/label'

import { defaultValues, validationSchema } from './config'
import { handleGenerateNewToken, handleGet, handleSave } from './actions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [businessGroup, setBusinessGroup] = useState<any>({})
  const [visibleNewTokenModal, enableNewTokenModal, disableNewTokenModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Grupo Empresarial'

    load()

    return () => {}
  }, [id])

  const load = async () => {
    const res = await handleGet(id)
    if (res) {
      form.reset(res)
      setBusinessGroup(res)
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Grupo Empresarial' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <TextLabel name='token' label='Token' value={businessGroup.token} desktopSize={6} tabletSize={8} />
                  <CompanySelect name='companyId' label='Empresa *' desktopSize={6} tabletSize={4} />
                  <Input name='name' label='Nome *' desktopSize={12} tabletSize={4} />
                  <SelectBoolean name='groupInvoicing' label='Agrupar Faturamento *' desktopSize={3} tabletSize={4} />
                  <Select name='invoicingPeriod' label='Ciclo de Faturamento *' options={InvoicingPeriodOptions} desktopSize={3} tabletSize={4} />
                  <InputDecimal name='minimumInvoicing' label='Faturamento Mínimo *' desktopSize={3} tabletSize={4} />
                  <SelectBoolean name='blocked' label='Grupo Bloqueado *' desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Salvar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                  })
                }
              />
              <ButtonEdit text='Gerar Novo Token' icon={<FontIcon>sync</FontIcon>} onClick={async () => enableNewTokenModal()} />
              <ButtonLog entity='BusinessGroup' id={id} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <ConfirmDialog
        text='Deseja realmente gerar um novo token? Será necessário alterar o token na integração com a api Fm Transportes e essa ação não poderá ser desfeita'
        visible={visibleNewTokenModal}
        confirmAction={async () => {
          await handleGenerateNewToken(id)
          disableNewTokenModal()
          load()
        }}
        cancelAction={async () => disableNewTokenModal()}
      />
    </>
  )
}

export default Component
