import api from 'services/quote/iss'

import downloadFile from 'utils/download-file'

export const handleSearch = async (request: any) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const handleDownloadCsv = async (fileName: string) => {
  let res = await api.downloadCsv()

  downloadFile(fileName, res?.data)
}
