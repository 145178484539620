import { useCallback, useEffect, useRef, useState } from 'react'
import { FontIcon, FormMessage, List, ListItem, TextField, useResizeObserver } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'
import { useFormContext } from 'react-hook-form'
import debounce from 'lodash.debounce'

import { AutocompleteResult } from 'types/core/autocomplete'
import Cell from 'components/core/cell'

import { FieldProps } from './types'

interface AutoCompleteProps extends FieldProps {
  searchData: (text: string) => Promise<Array<AutocompleteResult>>
  onSelect?: (item: AutocompleteResult) => void
}

export const InputAutoComplete = ({ name, searchData, onSelect, desktopSize, tabletSize, disabled, ...rest }: AutoCompleteProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const [value, setValue] = useState<AutocompleteResult>({ id: null, label: null })
  const [dataOptions, setDataOptions] = useState<Array<AutocompleteResult>>([])
  const [width, setWidth] = useState<number | null>(null)
  const minimumCharactersToSearch = 3

  const handleResize = useCallback(({ width }) => setWidth(width), [])
  const [_, refHandler] = useResizeObserver(handleResize)
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })

    return () => {}
  }, [form.register])

  useEffect(() => {
    if (!formValue?.id) setValue({ id: null, label: null })

    return () => {}
  }, [formValue?.id])

  useEffect(() => {
    setValue(form.getValues()[name])

    return () => {}
  }, [form.getValues()[name]?.id])

  const debouncedChange = useRef(
    debounce(async value => {
      if (value.length >= minimumCharactersToSearch) setDataOptions(await searchData(value))
    }, 300),
  ).current

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name]?.id || form.errors[name] ? true : false}
        disabled={disabled}
        value={value?.label ?? ''}
        onBlur={() => setTimeout(() => setDataOptions([]), 100)}
        onChange={async event => {
          setValue({ id: null, label: event.target.value })
          form.setValue(name, { id: null, label: event.target.value })
          if (form.errors[name]?.id || form.errors[name]) form.clearError(name)
          debouncedChange(event.target.value)
          return event
        }}
        rightChildren={promiseInProgress ? <></> : <FontIcon>search</FontIcon>}
        {...rest}
      />
      {form.errors[name]?.id && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].id.message}
        </FormMessage>
      )}
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}

      <div ref={refHandler}>
        {dataOptions && dataOptions.length > 0 && (
          <List className='rmd-listbox rmd-listbox--temporary' style={{ position: 'fixed', width: `${width}px`, transformOrigin: '50% 0px' }}>
            {dataOptions.map((item, index) => (
              <ListItem
                key={index}
                id={`${name}-item-${index}`}
                onClick={() => {
                  setValue({ id: item.id, label: item.label })
                  form.setValue(name, { id: item.id, label: item.label })
                  if (onSelect) onSelect(item)
                  setDataOptions([])
                }}
                className='rmd-list-item rmd-list-item--clickable rmd-option'>
                {item.label}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </Cell>
  )
}
