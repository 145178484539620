import api from 'services/quote/partner'

export const handleSearch = async (request: any) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/quote/partner/${id}/edit`)
}

export const handleDelete = async (id: string) => {
  let res = await api.delete(id)

  return res?.data?.success
}
