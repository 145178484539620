import React from 'react'
import { Grid, useToggle } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { InputDatePicker, handleSubmit } from 'components/form'
import { ConfirmDialog, Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  pickupAddressId: string
  clientId: string | null
  disableModal: () => void
  reset: () => void
}

const Component: React.FC<ComponentProps> = ({ pickupAddressId, clientId, disableModal, reset }) => {
  const form = useForm({ defaultValues })
  const [visibleConfirm, enableConfirm, disableConfirm] = useToggle(false)

  return (
    <>
      <Modal
        title={'Confirmação da Solicitação'}
        visible={true}
        width={500}
        confirmTitle={'Solicitar'}
        confirmAction={async () => enableConfirm()}
        confirmDisabled={!form.formState.dirty}
        closeTitle={'Fechar'}
        closeAction={async () => disableModal()}>
        <FormContext {...form}>
          <Grid>
            <InputDatePicker name='date' label='Data *' />
          </Grid>
        </FormContext>
      </Modal>

      <ConfirmDialog
        text='Deseja realmente realizar a solicitação de coleta no endereço indicado? Essa ação não poderá ser desfeita'
        visible={visibleConfirm}
        confirmAction={async () => {
          disableConfirm()
          await handleSubmit(form, validationSchema, async () => {
            let res = await handleSave({ pickupAddressId: pickupAddressId, client: { id: clientId }, ...form.getValues() })

            if (res?.success) {
              form.reset(defaultValues, { dirty: false })
              disableModal()
              reset()
              window.open(`/pickup/pickup-request/${res.data}/edit`, '_self')
            }
          })
        }}
        cancelAction={async () => disableConfirm()}
      />
    </>
  )
}

export default Component
