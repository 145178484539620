import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/order'
const Api = {
  getByTagFm: (tag: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${tag}/cubage/fm`)),
  validateCubage: (trackingCode: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${trackingCode}/cubage/validate`)),
  cubageManual: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/cubage/manual`, request)),
}

export default Api
