import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { UpdateDeadlineActionOptions } from 'types/quote/update-deadline-action'
import { handleSubmit, InputNumber, Select } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  clientCoverageId: string
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ clientCoverageId, visibleModal, disableModal }) => {
  const form = useForm({ defaultValues })

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
  }

  return (
    <Modal
      title={'Atualizar Prazo'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ clientCoverageId, ...form.getValues() })) {
            clear()
            disableModal()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <Select name='action' label='Ação' options={UpdateDeadlineActionOptions} desktopSize={6} tabletSize={4} />
          <InputNumber name='deadline' label='Prazo' desktopSize={6} tabletSize={4} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
