import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  service: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  service: Yup.string().nullable().required(),
})
