import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import IntegrationWebhookResendClient from 'pages/integration/client/webhook/resend'
import IntegrationWebhookSendTestClient from 'pages/integration/client/webhook/send-test'

import IntegrationBlingGenerateTokenClient from 'pages/integration/fm/bling/generate-token'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.integration.client && (
        <>
          <Route path='/integration/webhook/resend/client' exact component={IntegrationWebhookResendClient} />
          <Route path='/integration/webhook/send-test/client' exact component={IntegrationWebhookSendTestClient} />
        </>
      )}

      {menuPermission?.integration.bling && (
        <>
          <Route path='/integration/bling/generate-token' exact component={IntegrationBlingGenerateTokenClient} />
        </>
      )}
    </>
  )
}

export default Routes
