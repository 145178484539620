import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, TabsManager, TabPanels, TabPanel, Tabs, useToggle, ListItem, FontIcon } from 'react-md'

import { DateTimeLabel, NumberLabel, TextLabel } from 'components/label'
import { Cell, PageTitle, ButtonLog, Actions, ButtonEdit } from 'components/core'

import { handleGet } from './actions'

import Requests from './components/requests'
import UpdateSequence from './components/sequence'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [pickupPackingList, setPickupPackingList] = useState<any>()
  const [loadRequests, setLoadRequests] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleUpdateSequenceModal, enableUpdateSequenceModal, disableUpdateSequenceModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Romaneio de Coleta'

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadRequests(true)

    return () => {}
  }, [activeTab])

  const load = async () => {
    const res = await handleGet(id)
    if (res) setPickupPackingList(res)
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Romaneio de Coleta' />
          <TabsManager
            tabs={['Romaneio', 'Solicitações']}
            activeIndex={activeTab}
            onActiveIndexChange={activeIndexNumber => setActiveTab(activeIndexNumber)}
            tabsId='packing-list-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <Card fullWidth>
                  <CardContent>
                    <Grid>
                      <NumberLabel name='number' label='Número' value={pickupPackingList?.number} desktopSize={2} tabletSize={4} />
                      <DateTimeLabel name='createdAt' label='Data de Criação' value={pickupPackingList?.createdAt} desktopSize={2} tabletSize={4} />
                      <TextLabel name='routeName' label='Rota' value={pickupPackingList?.routeName} desktopSize={3} tabletSize={4} />
                      <TextLabel name='carrierName' label='Portador' value={pickupPackingList?.carrierName} desktopSize={3} tabletSize={4} />
                      <DateTimeLabel
                        name='finishedAt'
                        label='Data de Finalização'
                        value={pickupPackingList?.finishedAt}
                        desktopSize={2}
                        tabletSize={4}
                      />
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                    <ButtonLog entity='PickupPackingList' id={id} />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel>{loadRequests && <Requests packingListId={id} />}</TabPanel>
            </TabPanels>
          </TabsManager>
        </Cell>
      </Grid>

      {visibleUpdateSequenceModal && <UpdateSequence packingListId={id} disableModal={disableUpdateSequenceModal} loadRequest={load} />}

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>sync</FontIcon>} disabled={pickupPackingList?.finishedAt} onClick={async () => enableUpdateSequenceModal()}>
          Roteirizar
        </ListItem>
      </Actions>
    </>
  )
}

export default Component
