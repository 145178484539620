import Yup from 'utils/yup'

export const defaultValues = {
  notfis: null,
  ocoren: null,
  active: null,
}

export const validationSchema = Yup.object().shape({
  notfis: Yup.bool()
    .nullable()
    .required()
    .test('integration-flux-notfis', 'Fluxo de notfis ou ocoren não informado', function (value) {
      return this.parent.ocoren || value
    }),
  ocoren: Yup.bool()
    .nullable()
    .required()
    .test('integration-flux-ocoren', 'Fluxo de notfis ou ocoren não informado', function (value) {
      return this.parent.notfis || value
    }),
  active: Yup.bool().nullable().required(),
})
