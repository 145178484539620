import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Card, CardContent } from 'react-md'

import { BusinessGroupAutoComplete } from 'components/auto-complete'
import { AutocompleteResult } from 'types/core/autocomplete'
import { CalloutType } from 'components/core/callout'
import { DeleteButton } from 'components/data-table'
import { Cell, Callout } from 'components/core'

import { handleAddBusinessGroup, handleDeleteBusinessGroup } from 'pages/system/profile/edit/actions'
import { defaultValuesAddBusinessGroup } from 'pages/system/profile/new/config'

interface ComponentProps {
  id: string
  businessGroupList: Array<any>
  loadProfile: () => void
}

const Component: React.FC<ComponentProps> = ({ id, businessGroupList, loadProfile }) => {
  const formBusinessGroup = useForm({ defaultValues: defaultValuesAddBusinessGroup })
  const [businessGroups, setBusinessGroups] = useState<Array<any>>([])

  useEffect(() => {
    setBusinessGroups(businessGroupList)

    return () => {}
  }, [id, businessGroupList])

  return (
    <Card fullWidth>
      <CardContent>
        <Callout
          type={CalloutType.Info}
          text='Para incluir os grupos empresariais no perfil basta realizar a pesquisa e selecionar o grupo empresarial desejado'
        />
        <FormContext {...formBusinessGroup}>
          <Grid>
            <BusinessGroupAutoComplete
              name='businessGroup'
              label='Grupo Empresarial *'
              onSelect={async (item: AutocompleteResult) => {
                if (businessGroups.some((x: any) => x.businessGroupId === item.id)) toast.warn('Grupo já adicionado')
                else {
                  if (await handleAddBusinessGroup(id, item.id ?? '')) loadProfile()
                }
                formBusinessGroup.reset()
              }}
              desktopSize={10}
              tabletSize={7}
            />
            {businessGroups && (
              <Cell desktopSize={10} tabletSize={7}>
                <TableContainer>
                  <Table fullWidth>
                    <TableBody>
                      {businessGroups.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <DeleteButton
                              id='delete-business-group-button'
                              action={async () => {
                                if (await handleDeleteBusinessGroup(id, item.businessGroupId)) loadProfile()
                              }}
                            />
                          </TableCell>
                          <TableCell grow>{item.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Cell>
            )}
          </Grid>
        </FormContext>
      </CardContent>
    </Card>
  )
}

export default Component
