import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'

import { InputArea } from 'components/form'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Observações
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <FormContext {...form}>
            <InputArea name='observation' label='Observação' rows={3} maxRows={3} />
          </FormContext>
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
