export const getFtpProtocolDescription = (status: string): string => {
  return FtpProtocolOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface FtpProtocolList {
  value: FtpProtocolEnum | null
  label: string
}

export enum FtpProtocolEnum {
  Ftp = 'Ftp',
  SFtp = 'SFtp',
}

export const FtpProtocolOptions: Array<FtpProtocolList> = [
  { value: null, label: '' },
  { value: FtpProtocolEnum.Ftp, label: 'FTP' },
  { value: FtpProtocolEnum.SFtp, label: 'sFTP' },
]
