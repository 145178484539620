import React, { useEffect } from 'react'
import { Grid, Card, CardContent, Text, CardActions, FontIcon } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { Input, InputDatePicker, InputDecimal, InputDocument, InputNumber, InputPhone, Select, SelectBoolean } from 'components/form'
import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import { ButtonSuccess, Cell, PageTitle } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { ServiceOptions } from 'types/quote/service'
import Address from 'components/address'

import { handleImport, handleValidateForms } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })
  const isDeclaration = form.watch('isDeclaration')

  useEffect(() => {
    document.title = 'Importação Manual'

    return () => {}
  }, [])

  const formIsDirty = (): boolean => {
    return !form.formState.dirty && !addressForm.formState.dirty
  }

  const formsIsValid = async (): Promise<boolean> => {
    return await handleValidateForms(
      { form: form, validationSchema: validationSchema },
      { form: addressForm, validationSchema: addressValidationSchema },
    )
  }

  const getOrderObj = () => {
    return {
      ...form.getValues(),
      ...addressForm.getValues(),
    }
  }

  const resetForms = () => {
    form.reset(defaultValues, { dirty: false })
    addressForm.reset(addressDefaultValues, { dirty: false })
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Importação Manual' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Informações Principais
                  </Text>
                </Cell>
                <Cell>
                  <Grid>
                    <ClientAutoComplete name='client' label='Cliente *' desktopSize={6} tabletSize={8} />
                    <Select name='service' label='Serviço *' options={ServiceOptions} desktopSize={3} tabletSize={4} />
                    <SelectBoolean
                      name='isDeclaration'
                      label='Documento Fiscal *'
                      labelTrue='Declaração'
                      labelFalse='Nota Fiscal'
                      desktopSize={3}
                      tabletSize={4}
                    />
                  </Grid>
                </Cell>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Destinatário
                  </Text>
                </Cell>
                <Cell>
                  <Grid>
                    <Input name='name' label='Nome *' desktopSize={6} tabletSize={8} />
                    <InputDocument name='document' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
                    <Input name='ie' label='Inscrição Estadual' desktopSize={3} tabletSize={4} />
                  </Grid>
                </Cell>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Contatos
                  </Text>
                </Cell>
                <Cell>
                  <Grid>
                    <Input name='email' label='E-mail' desktopSize={3} tabletSize={4} />
                    <InputPhone name='phone' label='Telefone' desktopSize={3} tabletSize={4} />
                  </Grid>
                </Cell>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Endereço Comercial
                  </Text>
                </Cell>
                <Cell>
                  <Address form={addressForm} />
                </Cell>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Informações Fiscais
                  </Text>
                </Cell>
                <Cell>
                  <Grid>
                    <InputNumber name='volumes' label='Volumes *' desktopSize={3} tabletSize={4} />
                    <InputDecimal name='weight' label='Peso' desktopSize={3} tabletSize={4} />
                    <Input name='orderNumber' label={isDeclaration ? 'Núm. Pedido *' : 'Núm. Pedido'} desktopSize={3} tabletSize={4} />
                    <InputDecimal name='totalValue' label='Valor Declarado *' desktopSize={3} tabletSize={4} />
                    <Input name='nature' label='Descrição Produto/Natureza' desktopSize={12} tabletSize={8} />
                    {isDeclaration === false && (
                      <>
                        <Input name='fiscalNoteNumber' label='Núm. Nota Fiscal *' desktopSize={2} tabletSize={4} />
                        <InputNumber name='fiscalNoteSerie' label='Série Nota Fiscal *' desktopSize={2} tabletSize={4} />
                        <InputDatePicker name='fiscalNoteDate' label='Data Nota Fiscal *' desktopSize={2} tabletSize={4} />
                        <Input name='fiscalNoteAccessKey' label='Chave Nota Fiscal *' desktopSize={6} tabletSize={8} />
                      </>
                    )}
                  </Grid>
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={formIsDirty()}
              onClick={async () => {
                if (await formsIsValid()) if (await handleImport(getOrderObj())) resetForms()
              }}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
