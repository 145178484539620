import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getQuoteClientMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.quote) {
    if (permissions?.quote.quote.client) {
      menu['/quote/client'] = getMenu('/quote/client', null, 'Realizar Cotação', 'paid', '/quote/client')
    }
  }
}
