import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogFooter, Text, useAppSize } from 'react-md'

import ButtonError from './button-error'
import ButtonSuccess from './button-success'

interface Props {
  title: string
  width?: number | null | undefined
  visible: boolean
  confirmTitle?: string | null
  confirmAction?: (() => Promise<void>) | null
  confirmDisabled?: boolean
  closeTitle: string
  closeAction: () => Promise<void>
  onHide?: Function
}

const Modal: React.FC<Props> = ({
  title,
  width,
  visible,
  confirmTitle,
  confirmAction,
  confirmDisabled,
  closeTitle,
  closeAction,
  onHide,
  children,
}) => {
  const context = useAppSize()
  const [widthModal, setWidthModal] = useState<number | string>('800px')

  useEffect(() => {
    if (context.isPhone || context.isTablet) {
      setWidthModal('100%')
    } else {
      if (width && width > 0) {
        setWidthModal(width)
      } else {
        setWidthModal('800px')
      }
    }

    return () => {}
  }, [context])

  return (
    <Dialog
      id='confirm-dialog'
      aria-labelledby='dialog-title'
      visible={visible}
      onRequestClose={() => (onHide ? onHide() : {})}
      disableFocusOnMount={true}
      style={{ width: widthModal }}>
      <DialogContent>
        <Text id='dialog-title' margin='none' color='secondary' type='headline-6'>
          {title}
        </Text>
        {children}
      </DialogContent>
      <DialogFooter>
        {confirmAction && confirmTitle && (
          <ButtonSuccess text={confirmTitle} disabled={confirmDisabled} onClick={async () => await confirmAction()} />
        )}
        <ButtonError theme='error' text={closeTitle} onClick={closeAction} />
      </DialogFooter>
    </Dialog>
  )
}

export default Modal
