import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardActions, CardTitle, ExpansionList, Grid } from 'react-md'

import { DataTableRequest, DataTableResult } from 'components/data-table/types'
import { Cell, PageTitle } from 'components/core'

import DataTablePagination from './components/data-table-pagination'
import Panel from './components/panel'
import { convertParam, handleSearch } from './actions'

const Component: React.FC = () => {
  const { filter } = useParams<{ filter: string }>()
  const param = convertParam(filter)
  const [request, setRequest] = useState<DataTableRequest | undefined>({
    filter: { aggregateName: param.aggregateName, id: '{"ExternalId":"' + param.id + '"}' },
    order: { field: '', desc: true },
    pagination: { page: 0, length: 5 },
  })
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [toogleReset, setToogleReset] = useState<boolean>(true)

  useEffect(() => {
    document.title = 'Log de Alterações'

    return () => {}
  }, [])

  useEffect(() => {
    const load = async () => {
      if (request && param) {
        const res = await handleSearch(request)
        setResult(res.data)
        setToogleReset(!toogleReset)
      }
    }

    load()

    return () => {}
  }, [request])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Log de Alterações' />
        {result && result.results.length > 0 && (
          <>
            <ExpansionList onKeyDown={() => {}}>
              {result.results.map((data, index) => (
                <Panel key={index} data={data} toogleReset={toogleReset} />
              ))}
            </ExpansionList>
            <Card fullWidth>
              <CardActions>
                <DataTablePagination request={request} setRequest={setRequest} total={result.total} />
              </CardActions>
            </Card>
          </>
        )}
        {(!result || result.results.length === 0) && (
          <Card>
            <CardTitle title='Nenhuma informação localizada' />
          </Card>
        )}
      </Cell>
    </Grid>
  )
}

export default Component
