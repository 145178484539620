import React, { useContext, useEffect, useState } from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { ContactContext } from 'contexts/client/contact/contact-context'
import { Callout, Cell, Modal } from 'components/core'
import { handleSubmit, Input } from 'components/form'
import { CalloutType } from 'components/core/callout'

import { defaultValues, detailDefaultValues, validationSchema } from './config'
import { handleRemoveDetail, handleSave } from './actions'
import List from './components/list'
import Edit from './components/edit'

interface ComponentProps {
  clientId: string
  contactData: any
  onHide: () => void
}

const Component: React.FC<ComponentProps> = ({ clientId, contactData, onHide }) => {
  const context = useContext(ContactContext)
  const form = useForm({ defaultValues })
  const detailForm = useForm({ defaultValues: detailDefaultValues })
  const [contactId, setContactId] = useState<string>()
  const [contact, setContact] = useState<any | null>(null)
  const [contacts, setContacts] = useState<Array<any>>([])

  useEffect(() => {
    if (contactData) {
      form.reset({ name: contactData.name }, { dirty: false })
      setContacts(contactData.contacts)
    }

    return () => {}
  }, [contactData])

  useEffect(() => {
    if (contactId) setContact(contacts.find(x => x.id === contactId))

    return () => {}
  }, [contactId, contacts])

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    detailForm.reset(detailDefaultValues, { dirty: false })
    setContacts([])
  }

  const editContact = (id: string) => setContactId(id)

  const removeContact = async (id: string) => {
    if (await handleRemoveDetail(clientId, contactData.id, id)) {
      setContacts(contacts.filter((data, _) => data.id != id))
      await context.loadContacts()
    }
  }

  const onCloseEdit = () => {
    setContactId(undefined)
    setContact(null)
  }

  return (
    <Modal
      title={'Edição de Contato'}
      visible={true}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ clientId, id: contactData.id, ...form.getValues(), contacts })) {
            await context.loadContacts()
            clear()
            onHide()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        onHide()
      }}>
      <Grid>
        <FormContext {...form}>
          <Input name='name' label='Nome *' desktopSize={12} tabletSize={12} />
        </FormContext>
        <Cell>
          {contacts.length === 0 && <Callout text={'Nenhum contato informado'} type={CalloutType.Info} />}
          {contact && <Edit clientId={clientId} contactId={contactData.id} contactData={contact} onClose={onCloseEdit} />}
          {!contact && contacts.length > 0 && <List contactList={contacts} editContact={editContact} removeContact={removeContact} />}
        </Cell>
      </Grid>
    </Modal>
  )
}

export default Component
