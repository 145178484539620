import React from 'react'
import { Grid, Text } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import { ChipBoolean, InputTime, SelectBoolean } from 'components/form'
import { PickupRouteAutoComplete } from 'components/auto-complete'
import { Cell, Modal } from 'components/core'
import Address from 'components/address'

import { pickupDefaultValues, pickupValidationSchema } from './config'
import { handleValidateForms, handleAddAddress } from './actions'

interface ComponentProps {
  clientId: string
  visibleModal: boolean
  onHide: (refresh: boolean) => void
}

const Component: React.FC<ComponentProps> = ({ clientId, visibleModal, onHide }) => {
  const pickupForm = useForm({ defaultValues: pickupDefaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })

  const formsIsValid = async (): Promise<boolean> => {
    return await handleValidateForms(
      { form: pickupForm, validationSchema: pickupValidationSchema },
      { form: addressForm, validationSchema: addressValidationSchema },
    )
  }

  const clear = (refresh: boolean) => {
    pickupForm.reset(pickupDefaultValues, { dirty: false })
    addressForm.reset(addressDefaultValues, { dirty: false })
    onHide(refresh)
  }

  return (
    <Modal
      title={'Adicionar Endereço de Coleta'}
      width={900}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!pickupForm.formState.dirty && !addressForm.formState.dirty}
      confirmAction={async () => {
        let pickupAddress = {
          clientId,
          ...pickupForm.getValues(),
          address: addressForm.getValues(),
        }

        if ((await formsIsValid()) && (await handleAddAddress(pickupAddress))) clear(true)
      }}
      closeTitle={'Fechar'}
      closeAction={async () => clear(false)}>
      <Grid>
        <FormContext {...pickupForm}>
          <PickupRouteAutoComplete name='route' label='Rota de Coleta *' desktopSize={6} tabletSize={4} />
          <SelectBoolean name='automaticPickupMethod' label='Método de Coleta Automático *' desktopSize={6} tabletSize={4} />
          <InputTime name='pickupStartTime' label='Horário de Coleta Inicial *' desktopSize={6} tabletSize={4} />
          <InputTime name='pickupFinalTime' label='Horário de Coleta Final *' desktopSize={6} tabletSize={4} />
          <ChipBoolean name='monday' label='Segunda' desktopSize={2} tabletSize={2} />
          <ChipBoolean name='tuesday' label='Terça' desktopSize={2} tabletSize={2} />
          <ChipBoolean name='wednesday' label='Quarta' desktopSize={2} tabletSize={2} />
          <ChipBoolean name='thursday' label='Quinta' desktopSize={2} tabletSize={2} />
          <ChipBoolean name='friday' label='Sexta' desktopSize={2} tabletSize={2} />
          <ChipBoolean name='saturday' label='Sábado' desktopSize={2} tabletSize={2} />
          <ChipBoolean name='sunday' label='Domingo' desktopSize={2} tabletSize={2} />
        </FormContext>
      </Grid>
      <Grid>
        <Cell>
          <Text type='headline-6' margin='none'>
            Endereço
          </Text>
        </Cell>
        <Cell>
          <Address form={addressForm} />
        </Cell>
      </Grid>
    </Modal>
  )
}

export default Component
