import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/order/report'
const Api = {
  reportLotClient: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/lot/client`, request)),
  reportLotFm: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/lot/fm`, request)),
}

export default Api
