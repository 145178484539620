import Yup from 'utils/yup'

export const defaultValues = {
  partner: null,
  trackings: '',
}

export const validationSchema = Yup.object().shape({
  partner: Yup.string().nullable(),
  trackings: Yup.string().nullable().required(),
})
