import { trackPromise } from 'react-promise-tracker'
import { AxiosRequestConfig } from 'axios'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/client-coverage'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getCsv: (): Promise<any> => trackPromise<ApiResponse>(api.get(`${url}/csv`, headerConfig)),
  autocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  addPartnerLinkAsync: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/add-partner-link`, request)),
  uploadCoverage: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/coverage/upload`, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  searchCoverage: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/coverage/search`, request)),
  searchClient: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/client/search`, request)),
  searchPartnerLink: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/partner-link/search`, request)),
  downloadCsv: (request: any): Promise<any> => trackPromise<ApiResponse>(api.post(`${url}/csv`, request, headerConfig)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  migrate: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/migrate`, request)),
  deadlineUpdate: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/deadline`, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
  removePartnerLink: (id: string, partnerId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${id}/partner/${partnerId}`)),
}

export default Api
