import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, FontIcon, useToggle, ListItem } from 'react-md'

import { Cell, PageTitle, ButtonLog, ButtonSuccess, ButtonEdit, Actions } from 'components/core'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './components/partner/config'
import { handleDownloadCoverageCsv, handleDownloadFareCsv, handleGet, handleSave } from './actions'

import DeadlineUpdate from './components/deadline-update'
import Partner from './components/partner'
import ImportCoverage from './components/import-coverage'
import ImportFare from './components/import-fare'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleCoverageModal, enableCoverageModal, disableCoverageModal] = useToggle(false)
  const [visibleFareModal, enableFareModal, disableFareModal] = useToggle(false)
  const [visibleDeadlineUpdateModal, enableDeadlineUpdateModal, disableDeadlineUpdateModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Parceiro'

    const load = async () => {
      const res = await handleGet(id)
      if (res) form.reset(res)
    }

    load()

    return () => {}
  }, [id])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Parceiro' />
          <Card fullWidth>
            <CardContent>
              <Partner form={form} />
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Salvar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                  })
                }
              />
              <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
              <ButtonLog entity='Partner' id={id} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <ImportCoverage partnerId={id} visibleModal={visibleCoverageModal} disableModal={disableCoverageModal} />

      <ImportFare partnerId={id} visibleModal={visibleFareModal} disableModal={disableFareModal} />

      <DeadlineUpdate partnerId={id} visibleModal={visibleDeadlineUpdateModal} disableModal={disableDeadlineUpdateModal} />

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>upload</FontIcon>} onClick={async () => enableCoverageModal()}>
          Importar Abrangência
        </ListItem>
        <ListItem leftAddon={<FontIcon>upload</FontIcon>} onClick={async () => enableFareModal()}>
          Importar Tarifa
        </ListItem>
        <ListItem leftAddon={<FontIcon>sync</FontIcon>} onClick={async () => enableDeadlineUpdateModal()}>
          Atualizar Prazos
        </ListItem>
        <ListItem leftAddon={<FontIcon>download</FontIcon>} onClick={async () => handleDownloadCoverageCsv({ partnerId: id }, 'Abrangência.csv')}>
          Download da Abrangência
        </ListItem>
        <ListItem leftAddon={<FontIcon>download</FontIcon>} onClick={async () => handleDownloadFareCsv({ partnerId: id }, 'Tarifa.csv')}>
          Download da Tarifa
        </ListItem>
      </Actions>
    </>
  )
}

export default Component
