import React from 'react'
import { Card, CardActions, CardContent, FontIcon } from 'react-md'

import { ButtonLog, ButtonSuccess } from 'components/core'

interface ComponentProps {
  id: string
  formIsDirty: () => boolean
  formsIsValid: () => Promise<boolean>
  resetForms: () => void
  getClientObj: () => any
  handleSave: (values: any) => Promise<boolean>
}

const Component: React.FC<ComponentProps> = ({ id, formIsDirty, formsIsValid, resetForms, getClientObj, handleSave, children }) => {
  return (
    <Card fullWidth>
      <CardContent>{children}</CardContent>
      <CardActions>
        <ButtonSuccess
          text='Salvar'
          icon={<FontIcon>done</FontIcon>}
          disabled={formIsDirty()}
          onClick={async () => {
            if (await formsIsValid()) if (await handleSave(getClientObj())) resetForms()
          }}
        />
        <ButtonLog entity='Client' id={id} />
      </CardActions>
    </Card>
  )
}

export default Component
