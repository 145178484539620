import React, { useEffect } from 'react'
import { format } from 'date-fns'

interface ComponentProps {
  protocol: any
  handlePrint: () => void
}

const Component = React.forwardRef<HTMLDivElement, ComponentProps>((props, ref) => {
  let { protocol, handlePrint } = props

  useEffect(() => {
    if (protocol) handlePrint()
  }, [protocol])

  return (
    <>
      {protocol && (
        <div ref={ref}>
          <style type='text/css' media='print'>
            {'@page { margin: 10px 10px 10px 10px !important; }'}
          </style>
          <div style={{ pageBreakInside: 'avoid' }}>
            <div style={{ width: '100%', border: '1px solid', pageBreakInside: 'avoid' }}>
              <div style={{ textAlign: 'center', borderBottom: '1px solid' }}>
                <h1>FM Transportes - Protocolo</h1>
              </div>

              <div style={{ borderBottom: '1px solid', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', padding: '10px', borderRight: '1px solid' }}>
                  <b>Remetente:</b> {protocol.clientFantasyName}
                </div>
                <div style={{ width: '50%', padding: '10px' }}>
                  <b>Tracking:</b> {protocol.volumeId}
                </div>
              </div>

              <div style={{ borderBottom: '1px solid', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', padding: '10px', borderRight: '1px solid' }}>
                  <b>Destinatário:</b> {protocol.recipientName}
                </div>
                <div style={{ width: '50%', padding: '10px' }}>
                  <b>Entregue Em:</b> {format(new Date(protocol.deliveryDate), 'dd/MM/yyyy HH:mm')}
                </div>
              </div>

              <div style={{ borderBottom: '1px solid', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', padding: '10px', borderRight: '1px solid' }}>
                  <b>Número da Nota Fiscal:</b> {protocol.fiscalNoteNumber}
                </div>
                <div style={{ width: '50%', padding: '10px' }}>
                  <b>Recebido Por:</b> {protocol.name}
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', padding: '10px', borderRight: '1px solid' }}>
                  <b>Número do Pedido:</b> {protocol.orderNumber}
                </div>
                <div style={{ width: '50%', padding: '10px' }}>
                  <b>Documento:</b> {protocol.document}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
})

export default Component
