export interface UpdateDeadlineActionList {
  value: UpdateDeadlineActionEnum | null
  label: string
}

export enum UpdateDeadlineActionEnum {
  Add = 'Add',
  Subtract = 'Subtract',
}

export const UpdateDeadlineActionOptions: Array<UpdateDeadlineActionList> = [
  { value: null, label: '' },
  { value: UpdateDeadlineActionEnum.Add, label: 'Acrescentar' },
  { value: UpdateDeadlineActionEnum.Subtract, label: 'Subtrair' },
]
