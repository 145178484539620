export const getIntegrationDescription = (status: string): string => {
  return IntegrationOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface IntegrationList {
  value: IntegrationEnum | null
  label: string
}

export enum IntegrationEnum {
  FmTransportesApi = 'FmTransportesApi',
  FmTransportesWebhook = 'FmTransportesWebhook',
  IntelipostApi = 'IntelipostApi',
  Enel = 'Enel',
  Bling = 'Bling',
  Tiny = 'Tiny',
  TinyDeclaration = 'TinyDeclaration',
  VtexLog = 'VtexLog',
  EmailXml = 'EmailXml',
}

export const IntegrationOptions: Array<IntegrationList> = [
  { value: null, label: '' },
  { value: IntegrationEnum.Bling, label: 'Bling' },
  { value: IntegrationEnum.EmailXml, label: 'E-mail (Xml)' },
  { value: IntegrationEnum.Enel, label: 'Enel' },
  { value: IntegrationEnum.FmTransportesApi, label: 'Fm Transportes (Api)' },
  { value: IntegrationEnum.FmTransportesWebhook, label: 'Fm Transportes (Webhook)' },
  { value: IntegrationEnum.IntelipostApi, label: 'Intelipost (Api)' },
  { value: IntegrationEnum.Tiny, label: 'Tiny' },
  { value: IntegrationEnum.TinyDeclaration, label: 'Tiny (Declaração)' },
  { value: IntegrationEnum.VtexLog, label: 'VtexLog' },
]
