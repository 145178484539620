import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'
import { toast } from 'react-toastify'

import { IntegrationEnum, IntegrationOptions } from 'types/integration/integration'
import { handleSubmit, Input, Select, SelectBoolean } from 'components/form'
import { handleValidate } from 'components/form/form-submit'
import { ValidationMessage } from 'types/core/api'
import { TextLabel } from 'components/label'
import { Modal } from 'components/core'

import { defaultValues as defaultValuesFtp, validationSchema as validationSchemaFtp } from './components/ftp/config'
import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'
import {
  defaultValues as defaultValuesApiWithServiceId,
  validationSchema as validationSchemaApiWithServiceId,
} from './components/api-with-service-id/config'
import {
  defaultValues as defaultValuesApiWithServiceAndLogisticId,
  validationSchema as validationSchemaApiWithServiceAndLogisticId,
} from './components/api-with-service-and-logistic-id/config'
import { defaultValues as defaultValuesWebhook, validationSchema as validationSchemaWebhook } from './components/webhook/config'

import ApiWithServiceId from './components/api-with-service-id'
import ApiWithServiceAndLogisticId from './components/api-with-service-and-logistic-id'
import Ftp from './components/ftp'
import Webhook from './components/webhook'

interface ComponentProps {
  clientId: string
  visibleModal: boolean
  disableModal: () => void
  loadIntegrations: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ clientId, visibleModal, disableModal, loadIntegrations }) => {
  const form = useForm({ defaultValues })
  const formApiWithServiceId = useForm({ defaultValues: defaultValuesApiWithServiceId })
  const formApiWithServiceAndLogisticId = useForm({ defaultValues: defaultValuesApiWithServiceAndLogisticId })
  const formFtp = useForm({ defaultValues: defaultValuesFtp })
  const formWebhook = useForm({ defaultValues: defaultValuesWebhook })

  const integration: IntegrationEnum | null = form.watch('integration')

  useEffect(() => {
    if (integration === IntegrationEnum.FmTransportesApi) form.setValue<string, boolean>('notfis', true, false)
    else if (integration === IntegrationEnum.FmTransportesWebhook) form.setValue<string, boolean>('notfis', false, false)
    else form.setValue('notfis', null, false)

    if (integration === IntegrationEnum.Tiny || integration === IntegrationEnum.TinyDeclaration || integration === IntegrationEnum.EmailXml)
      form.setValue<string, boolean>('ocoren', false, false)
    else form.setValue('ocoren', null, false)
  }, [integration])

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    formApiWithServiceId.reset(defaultValuesApiWithServiceId, { dirty: false })
    formApiWithServiceAndLogisticId.reset(defaultValuesApiWithServiceAndLogisticId, { dirty: false })
    formFtp.reset(defaultValuesFtp, { dirty: false })
    formWebhook.reset(defaultValuesWebhook, { dirty: false })
  }

  const getValues = () => {
    if (integration) {
      switch (integration) {
        case IntegrationEnum.FmTransportesApi:
        case IntegrationEnum.EmailXml:
          return null

        case IntegrationEnum.Tiny:
        case IntegrationEnum.TinyDeclaration:
        case IntegrationEnum.IntelipostApi:
        case IntegrationEnum.VtexLog:
          return formApiWithServiceId.getValues()

        case IntegrationEnum.Bling:
          return formApiWithServiceAndLogisticId.getValues()

        case IntegrationEnum.Enel:
          return formFtp.getValues()

        case IntegrationEnum.FmTransportesWebhook:
          return formWebhook.getValues()

        default:
          return null
      }
    }
  }

  const validateForms = async (): Promise<boolean> => {
    let success = true

    if (integration) {
      switch (integration) {
        case IntegrationEnum.FmTransportesApi:
        case IntegrationEnum.EmailXml:
          break

        case IntegrationEnum.Tiny:
        case IntegrationEnum.TinyDeclaration:
        case IntegrationEnum.IntelipostApi:
        case IntegrationEnum.VtexLog:
          if (!(await handleValidate(formApiWithServiceId, validationSchemaApiWithServiceId))) success = false
          break

        case IntegrationEnum.Bling:
          if (!(await handleValidate(formApiWithServiceAndLogisticId, validationSchemaApiWithServiceAndLogisticId))) success = false
          break

        case IntegrationEnum.Enel:
          if (!(await handleValidate(formFtp, validationSchemaFtp))) success = false
          break

        case IntegrationEnum.FmTransportesWebhook:
          if (!(await handleValidate(formWebhook, validationSchemaWebhook))) success = false
          break

        default:
          toast.warn('Integração inválida')
          return false
      }
    }

    if (!success) toast.warn(ValidationMessage)

    return success
  }

  return (
    <Modal
      title={'Adicionar Integração'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={
        !form.formState.dirty && !formApiWithServiceId.formState.dirty && !formApiWithServiceAndLogisticId.formState.dirty && !formFtp.formState.dirty
      }
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await validateForms()) {
            if (await handleSave({ clientId, ...form.getValues(), ...getValues() })) {
              await loadIntegrations()
              clear()
              disableModal()
            }
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <Select name='integration' label='Integração *' options={IntegrationOptions} desktopSize={4} tabletSize={8} />

          {integration === IntegrationEnum.FmTransportesApi && (
            <TextLabel name='notfis' label='Notfis' value={'Sim'} desktopSize={4} tabletSize={4} />
          )}
          {integration === IntegrationEnum.FmTransportesWebhook && (
            <TextLabel name='notfis' label='Notfis' value={'Não'} desktopSize={4} tabletSize={4} />
          )}
          {integration !== IntegrationEnum.FmTransportesApi && integration !== IntegrationEnum.FmTransportesWebhook && (
            <SelectBoolean name='notfis' label='Notfis *' desktopSize={4} tabletSize={4} />
          )}

          {(integration === IntegrationEnum.Tiny || integration === IntegrationEnum.TinyDeclaration || integration === IntegrationEnum.EmailXml) && (
            <TextLabel name='ocoren' label='Ocoren' value={'Não'} desktopSize={4} tabletSize={4} />
          )}

          {integration !== IntegrationEnum.Tiny && integration !== IntegrationEnum.TinyDeclaration && integration !== IntegrationEnum.EmailXml && (
            <SelectBoolean name='ocoren' label='Ocoren *' defaultChecked={true} desktopSize={4} tabletSize={4} />
          )}
        </FormContext>

        {(integration === IntegrationEnum.Tiny ||
          integration === IntegrationEnum.TinyDeclaration ||
          integration === IntegrationEnum.IntelipostApi ||
          integration === IntegrationEnum.VtexLog) && <ApiWithServiceId form={formApiWithServiceId} />}

        {integration === IntegrationEnum.Bling && <ApiWithServiceAndLogisticId form={formApiWithServiceAndLogisticId} />}

        {integration === IntegrationEnum.Enel && <Ftp form={formFtp} />}

        {integration === IntegrationEnum.FmTransportesWebhook && <Webhook form={formWebhook} />}
      </Grid>
    </Modal>
  )
}

export default Component
