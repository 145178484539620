import { useAuth } from 'contexts/auth/auth-context'
import React from 'react'
import { Dialog, DialogContent, Text, DialogFooter, Button } from 'react-md'

interface Props {
  visible: boolean
  disable: () => void
}

const Component: React.FC<Props> = ({ visible, disable }) => {
  const { user } = useAuth()

  return (
    <Dialog id='top-app-bar-dialog' visible={visible} disableFocusOnMount={true} onRequestClose={disable} aria-labelledby='dialog-title'>
      <DialogContent>
        <Text margin='none'>Usuário logado: {user?.name}</Text>
      </DialogContent>
      <DialogFooter>
        <Button theme='primary' onClick={disable}>
          Fechar
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default Component
