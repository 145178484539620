import React from 'react'
import { FontIcon, Grid, useToggle } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { ButtonSuccess, Cell, ConfirmDialog } from 'components/core'
import { addressDefaultValues } from 'components/address/config'
import { InputDatePicker, handleSubmit } from 'components/form'
import Address from 'components/address'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  clientId: string | null
  reset: () => void
}

const Component: React.FC<ComponentProps> = ({ clientId, reset }) => {
  const form = useForm({ defaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })
  const [visibleConfirm, enableConfirm, disableConfirm] = useToggle(false)

  return (
    <>
      <FormContext {...form}>
        <Address form={addressForm} />
        <Grid>
          <InputDatePicker name='date' label='Data *' desktopSize={2} tabletSize={4} />
        </Grid>
        <Grid>
          <Cell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <ButtonSuccess
              text='Solicitar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty && !addressForm.formState.dirty}
              onClick={async () => await handleSubmit(form, validationSchema, async () => enableConfirm())}
            />
          </Cell>
        </Grid>
      </FormContext>

      <ConfirmDialog
        text='Deseja realmente realizar a solicitação de coleta no endereço indicado? Essa ação não poderá ser desfeita'
        visible={visibleConfirm}
        confirmAction={async () => {
          disableConfirm()
          let res = await handleSave({
            client: { id: clientId },
            address: addressForm.getValues(),
            ...form.getValues(),
          })

          if (res) {
            form.reset(defaultValues, { dirty: false })
            addressForm.reset(addressDefaultValues, { dirty: false })
            reset()
          }
        }}
        cancelAction={async () => disableConfirm()}
      />
    </>
  )
}

export default Component
