import React from 'react'
import { FontIcon, Button, Tooltipped, SimplePosition } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import { useAuth } from 'contexts/auth/auth-context'
import { Theme } from 'contexts/auth/types'

interface Props {
  id: string
  tooltip?: string
  tooltipPosition?: SimplePosition
  action: () => Promise<void>
  disabled?: boolean
}

const AddButton: React.FC<Props> = ({ id, tooltip, tooltipPosition, action, disabled }) => {
  const { promiseInProgress } = usePromiseTracker()
  const { theme } = useAuth()

  return (
    <Tooltipped id={id} tooltip={tooltip || 'Adicionar'} position={tooltipPosition || 'above'}>
      <Button
        theme={disabled || promiseInProgress ? 'disabled' : 'clear'}
        disabled={disabled || promiseInProgress}
        buttonType='icon'
        style={
          disabled || promiseInProgress
            ? { margin: 2 }
            : theme === Theme.Dark
            ? { margin: 2, backgroundColor: '#00695C', color: '#FFFFFF' }
            : { margin: 2, backgroundColor: '#4DB6AC', color: '#212121' }
        }
        onClick={action}>
        <FontIcon>add</FontIcon>
      </Button>
    </Tooltipped>
  )
}

export default AddButton
