import React, { useEffect } from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { handleSubmit, Input, InputDocument } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  volumeId: string
  deliveryInformation: any
  disableModal: () => void
  loadOrder: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ volumeId, deliveryInformation, disableModal, loadOrder }) => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    form.reset(deliveryInformation)
  }, [deliveryInformation])

  return (
    <Modal
      title={'Atualizar Informações de Entrega'}
      visible={true}
      confirmTitle={'Salvar'}
      confirmAction={async () =>
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ volumeId, ...form.getValues() })) {
            form.reset(defaultValues, { dirty: false })
            disableModal()
            loadOrder()
          }
        })
      }
      confirmDisabled={!form.formState.dirty}
      closeTitle={'Fechar'}
      closeAction={async () => disableModal()}>
      <FormContext {...form}>
        <Grid>
          <Input name='name' label='Nome do Recebedor' desktopSize={6} tabletSize={4} />
          <Input name='document' label='Cpf/Cnpj do Recebedor' desktopSize={6} tabletSize={4} />
          <Input name='latitude' label='Latitude da Entrega' desktopSize={6} tabletSize={4} />
          <Input name='longitude' label='Longitude da Entrega' desktopSize={6} tabletSize={4} />
        </Grid>
      </FormContext>
    </Modal>
  )
}

export default Component
