import React, { useEffect } from 'react'
import Bounce from 'react-reveal/Bounce'
import { Grid, Card, CardContent, Configuration, Text, FontIcon } from 'react-md'

import { ButtonCustom, Cell } from 'components/core'
import Logo from 'components/core/logo'
import Error from 'assets/error.png'

const Component: React.FC = () => {
  useEffect(() => {
    document.title = 'Oops'

    return () => {}
  }, [])

  return (
    <Configuration>
      <Bounce bottom>
        <Grid>
          <Cell tabletSize={6} tabletStart={2} desktopSize={4} desktopStart={5}>
            <Card fullWidth>
              <CardContent>
                <Logo />
                <Cell>
                  <img
                    src={Error}
                    alt='error'
                    style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '50%', maxHeight: '50%' }}
                  />
                </Cell>
                <Text align='center' type='headline-4'>
                  Oooops! Um erro ocorreu
                </Text>
                <ButtonCustom
                  text='Voltar para home'
                  icon={<FontIcon>arrow_back</FontIcon>}
                  whiteThemeBackgroundColor={'#e57373'}
                  darkThemeBackgroundColor={'#c62828'}
                  onClick={async () => location.assign('/')}
                  style={{ width: '100%' }}
                />
              </CardContent>
            </Card>
          </Cell>
        </Grid>
      </Bounce>
    </Configuration>
  )
}

export default Component
