export interface InvoicingPeriodList {
  value: InvoicingPeriodEnum | null
  label: string
}

export enum InvoicingPeriodEnum {
  Monthly = 'Monthly',
  Biweekly = 'Biweekly',
  Weekly = 'Weekly',
}

export const InvoicingPeriodOptions: Array<InvoicingPeriodList> = [
  { value: null, label: '' },
  { value: InvoicingPeriodEnum.Monthly, label: 'Mensal' },
  { value: InvoicingPeriodEnum.Biweekly, label: 'Quinzenal' },
  { value: InvoicingPeriodEnum.Weekly, label: 'Semanal' },
]
