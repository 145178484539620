import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  document: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().max(60, 'O nome deve ter no máximo 60 caracteres'),
  document: Yup.string().nullable().max(18, 'O nome deve ter no máximo 18 caracteres'),
})
