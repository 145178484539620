import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import PickupPackingListEdit from 'pages/pickup/pickup-packing-list/edit'
import PickupPackingListNew from 'pages/pickup/pickup-packing-list/new'
import PickupPackingListSearch from 'pages/pickup/pickup-packing-list/search'

import PickupRequestEditClient from 'pages/pickup/pickup-request/client/edit'
import PickupRequestNewClient from 'pages/pickup/pickup-request/client/new'
import PickupRequestSearchClient from 'pages/pickup/pickup-request/client/search'

import PickupRequestEditFm from 'pages/pickup/pickup-request/fm/edit'
import PickupRequestNewFm from 'pages/pickup/pickup-request/fm/new'
import PickupRequestSearchFm from 'pages/pickup/pickup-request/fm/search'

import PickupRouteEdit from 'pages/pickup/pickup-route/edit'
import PickupRouteNew from 'pages/pickup/pickup-route/new'
import PickupRouteSearch from 'pages/pickup/pickup-route/search'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.pickup.client && (
        <>
          <Route path='/pickup/pickup-request/:id/edit/client' exact component={PickupRequestEditClient} />
          <Route path='/pickup/pickup-request/new/client' exact component={PickupRequestNewClient} />
          <Route path='/pickup/pickup-request/search/client' exact component={PickupRequestSearchClient} />
        </>
      )}

      {menuPermission?.pickup.pickupPackingList && (
        <>
          <Route path='/pickup/pickup-packing-list/:id/edit' exact component={PickupPackingListEdit} />
          <Route path='/pickup/pickup-packing-list/new' exact component={PickupPackingListNew} />
          <Route path='/pickup/pickup-packing-list/search' exact component={PickupPackingListSearch} />
        </>
      )}

      {menuPermission?.pickup.fm.pickupRequest && (
        <>
          <Route path='/pickup/pickup-request/:id/edit' exact component={PickupRequestEditFm} />
          <Route path='/pickup/pickup-request/new' exact component={PickupRequestNewFm} />
          <Route path='/pickup/pickup-request/search' exact component={PickupRequestSearchFm} />
        </>
      )}

      {menuPermission?.pickup.pickupRoute && (
        <>
          <Route path='/pickup/pickup-route/:id/edit' exact component={PickupRouteEdit} />
          <Route path='/pickup/pickup-route/new' exact component={PickupRouteNew} />
          <Route path='/pickup/pickup-route/search' exact component={PickupRouteSearch} />
        </>
      )}
    </>
  )
}

export default Routes
