import api from 'services/order/order-import-csv'

import downloadFile from 'utils/download-file'

export const handleImport = async (values: any): Promise<Array<string> | null> => {
  let res = await api.importCsv(values)

  return res?.data?.success
}

export const handleGetCsv = async (fileName: string) => {
  let res = await api.getCsv()

  downloadFile(fileName, res?.data)
}
