import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'

import { Input } from 'components/form'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Input name='token' label='Token *' desktopSize={8} tabletSize={6} />
      <Input name='extraConfiguration1' label='Id Logística *' desktopSize={4} tabletSize={2} />
      <Input name='standardServiceId' label='Id Serviço Standard *' desktopSize={4} tabletSize={4} />
      <Input name='expressServiceId' label='Id Serviço Expresso *' desktopSize={4} tabletSize={4} />
      <Input name='medioRodoServiceId' label='Id Serviço Medio Rodo *' desktopSize={4} tabletSize={4} />
    </FormContext>
  )
}

export default Component
