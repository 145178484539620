import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, FileInput, Chip, FontIcon, Text } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { ButtonSuccess, Cell, PageTitle } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { handleSubmit, Select } from 'components/form'
import ButtonError from 'components/core/button-error'
import { ServiceOptions } from 'types/quote/service'

import { defaultValues, validationSchema } from './config'
import { handleImport } from './actions'

const Component: React.FC = () => {
  const [fileNames, setFileNames] = useState<Array<string> | null>()
  const [files, setFiles] = useState<Array<any> | null>()
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Importação Xml'

    return () => {}
  }, [])

  const getBase64 = (file: any) => {
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      if (reader && reader.result) {
        let base64File = btoa(
          encodeURIComponent(reader.result.toString()).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))),
        )

        let requestFile = {
          fileName: file.name,
          file: base64File,
        }

        setFileNames(x => (x ? [...x, file.name] : [file.name]))
        setFiles(x => (x ? [...x, requestFile] : [requestFile]))
      }
    }
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Importação Xml' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Remetente' desktopSize={3} tabletSize={4} />
                <Select name='service' label='Serviço *' options={ServiceOptions} desktopSize={3} tabletSize={4} />
              </Grid>
              <Grid>
                {!fileNames ? (
                  <Cell>
                    <Chip>{'Nenhum arquivo selecionado'}</Chip>
                  </Cell>
                ) : (
                  fileNames.map((data: any, index) => (
                    <Cell key={index}>
                      <Chip>Arquivo: {data}</Chip>
                    </Cell>
                  ))
                )}
                <Cell>
                  <FileInput
                    id='import-input'
                    theme={'secondary'}
                    themeType={'contained'}
                    buttonType={'text'}
                    accept={'.xml'}
                    multiple
                    icon={<FontIcon>description</FontIcon>}
                    disabled={fileNames ? true : false}
                    onChange={event => {
                      if (event?.currentTarget?.files) {
                        for (let index = 0; index < event.currentTarget.files.length; index++) {
                          if (event.target.files) getBase64(event.target.files[index])
                        }
                      }
                    }}>
                    {'Selecionar Arquivos'}
                  </FileInput>
                  <ButtonSuccess
                    text='Importar'
                    disabled={!fileNames}
                    icon={<FontIcon>check</FontIcon>}
                    onClick={async () =>
                      await handleSubmit(form, validationSchema, async () => {
                        if (await handleImport({ ...form.getValues(), files })) {
                          form.reset(defaultValues, { dirty: false })
                          setFileNames(null)
                          setFiles(null)
                        }
                      })
                    }
                  />
                  <ButtonError
                    text='Apagar'
                    disabled={!fileNames}
                    icon={<FontIcon>cancel</FontIcon>}
                    onClick={async () => {
                      setFileNames(null)
                      setFiles(null)
                    }}
                  />
                </Cell>
                <Cell>
                  <Text type='caption' component='h1'>
                    <i>Para realizar a importação informe até no máximo 50 arquivos com no máximo 100kb de tamanho cada</i>
                  </Text>
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
