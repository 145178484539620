import { BrowserRouter, Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './App.scss'

import { history } from 'utils/history'
import { AuthProvider } from 'contexts/auth/auth-context'

import Routes from './routes'

const App = () => {
  return (
    <BrowserRouter>
      <Router history={history}>
        <ToastContainer autoClose={4000} />
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </Router>
    </BrowserRouter>
  )
}

export default App
