import Yup from 'utils/yup'

export const taxInformationDefaultValues = {
  orderNumber: null,
  fiscalNoteNumber: null,
  totalValue: null,
}

export const taxInformationValidationSchema = Yup.object().shape({
  orderNumber: Yup.string().nullable().max(50, 'O pedido deve ter no máximo 50 caracteres'),
  fiscalNoteNumber: Yup.string().nullable().max(30, 'A nota fiscal deve ter no máximo 30 caracteres'),
  totalValue: Yup.number().nullable(),
})
