import React, { useState } from 'react'
import { Card, CardActions, CardContent, Chip, FontIcon, FormMessage, Grid } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'
import { FormContext, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { DepartmentEnum, DepartmentOption, DepartmentOptions } from 'types/sale/department'
import { handleSubmit, Input, InputPhone } from 'components/form'
import { ButtonSuccess, Cell } from 'components/core'

import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  addContact: (contact: any) => void
}

const Component: React.FC<ComponentProps> = ({ addContact }) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useForm({ defaultValues })
  const email = form.watch('email')
  const [departments, setDepartments] = useState<Array<string>>([])

  const addOrRemoveDepartment = (department: string | null) => {
    if (department) {
      if (departments.includes(department)) {
        setDepartments(departments.filter(x => x != department))
      } else {
        setDepartments([...departments, department])
      }
    }
  }

  return (
    <Card fullWidth>
      <CardContent>
        <FormContext {...form}>
          <Grid>
            <InputPhone name='phone1' label='Telefone 1 *' desktopSize={6} tabletSize={4} />
            <InputPhone name='phone2' label='Telefone 2' desktopSize={6} tabletSize={4} />
            <Input name='email' label='E-mail' maxLength={60} desktopSize={12} tabletSize={8} />
            <Cell>
              {DepartmentOptions.map((data: DepartmentOption, index) => (
                <Chip
                  key={index}
                  disabled={promiseInProgress}
                  selected={departments.includes(data.value ?? '')}
                  selectedThemed
                  onClick={() => addOrRemoveDepartment(data.value)}
                  style={{ marginRight: 3 }}>
                  {data.label}
                </Chip>
              ))}
              {departments.some((x: any) => x === DepartmentEnum.LogisticsAndShipping) && email && (
                <FormMessage style={{ marginTop: 15 }} messageStyle={{ fontStyle: 'italic' }} id={'add-logistics-and-shipping'}>
                  * Logística e Expedição: O e-mail informado receberá as notificações em caso de pedidos inválidos cadastrados na plataforma e
                  proximidade de coleta
                </FormMessage>
              )}
            </Cell>
          </Grid>
        </FormContext>
      </CardContent>
      <CardActions>
        <ButtonSuccess
          text='Adicionar'
          icon={<FontIcon>add</FontIcon>}
          disabled={!form.formState.dirty}
          onClick={async () =>
            await handleSubmit(form, validationSchema, async () => {
              if (departments.length === 0) {
                toast.warn('Nenhum departamento informado')
              } else {
                setDepartments([])
                addContact({ ...form.getValues(), departments })
                form.reset(defaultValues, { dirty: false })
              }
            })
          }
        />
      </CardActions>
    </Card>
  )
}

export default Component
