import Yup from 'utils/yup'

export const defaultValues = {
  field: null,
  values: '',
}

export const validationSchema = Yup.object().shape({
  field: Yup.string().nullable().required(),
  values: Yup.string().nullable().required(),
})
