import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'

import { Input } from 'components/form'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Input name='url' label='Url *' desktopSize={12} tabletSize={8} />
      <Input name='user' label='Usuário *' desktopSize={6} tabletSize={4} />
      <Input name='password' label='Senha *' desktopSize={6} tabletSize={4} />
    </FormContext>
  )
}

export default Component
