import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, useToggle, FontIcon, useCrossFade, ENTER } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSearch, ButtonSuccess, ButtonClearSearch } from 'components/core'
import { ZipCode } from 'components/address/components/zip-code'
import { ClientAutoComplete } from 'components/auto-complete'
import { InputDecimal, handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleQuote } from './actions'

import AddVolume from './components/add-volume'
import ListVolume from './components/list-volume'
import Result from './components/result'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [volumes, setVolumes] = useState<Array<any>>([])
  const [results, setResults] = useState<Array<any>>([])
  const [visibleAddVolumeModal, enableAddVolumeModal, disableAddVolumeModal] = useToggle(false)
  const [, transitionProps, dispatch] = useCrossFade({ appear: false })

  useEffect(() => {
    document.title = 'Cotação'

    return () => {}
  }, [])

  const addVolume = (volume: any) => setVolumes([...volumes, volume])

  const removeVolume = (volumeIndex: number) => setVolumes(volumes.filter((_, index) => index != volumeIndex))

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Cotação' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente *' desktopSize={6} />
                  <InputDecimal name='totalValue' label='Valor Total' desktopSize={3} tabletSize={4} />
                  <ZipCode name='zipCodeDestination' label='Cep de Destino *' maxLength={8} desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Adicionar Volume'
                icon={<FontIcon>add</FontIcon>}
                disabled={results.length !== 0}
                onClick={async () => enableAddVolumeModal()}
              />
              <ButtonClearSearch
                text='Nova Cotação'
                disabled={results.length === 0}
                onClick={async () => {
                  form.reset(defaultValues, { dirty: false })
                  setVolumes([])
                  setResults([])
                }}
              />
              <ButtonSearch
                disabled={(!form.formState.dirty && volumes.length === 0) || results.length !== 0}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    if (volumes.length === 0) {
                      toast.warn('Nenhum volume informado')
                    } else {
                      let quoteResult = await handleQuote({ ...form.getValues(), volumes: volumes })
                      if (quoteResult.success) {
                        setResults(quoteResult.data)
                        dispatch(ENTER)
                      }
                    }
                  })
                }
              />
            </CardActions>
          </Card>
        </Cell>
        <ListVolume volumes={volumes} disabled={results.length !== 0} removeVolume={removeVolume} />
        <Result results={results} transitionProps={transitionProps} />
      </Grid>

      <AddVolume visibleModal={visibleAddVolumeModal} disableModal={disableAddVolumeModal} addVolume={addVolume} />
    </>
  )
}

export default Component
