import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  login: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().max(40, 'O nome deve ter no máximo 40 caracteres'),
  login: Yup.string().nullable().max(20, 'O login deve ter no máximo 20 caracteres'),
})
