import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input, Select } from 'components/form'
import { useAuth } from 'contexts/auth/auth-context'

import { defaultValues, validationSchema } from './config'
import { handleGet, handleSave } from './actions'

const Component: React.FC = () => {
  const { user, logout, setTheme } = useAuth()
  const form = useForm({ defaultValues })

  const UserTheme = [
    { value: '1', label: 'Claro' },
    { value: '2', label: 'Escuro' },
  ]

  useEffect(() => {
    document.title = 'Meus Dados'

    const load = async () => {
      if (user) {
        const res = await handleGet()
        if (res) {
          res.userTheme = res.userTheme.toString()
          form.reset(res)
        }
      }
    }

    load()

    return () => {}
  }, [user])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Meus Dados' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Input name='name' label='Nome *' desktopSize={4} tabletSize={3} />
                <Input name='email' label='Email *' desktopSize={4} tabletSize={3} />
                <Select name='userTheme' label='Tema *' options={UserTheme} desktopSize={4} tabletSize={2} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () => await handleSubmit(form, validationSchema, async () => await handleSave(form.getValues(), logout, setTheme))}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
