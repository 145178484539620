import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { FontIcon, Grid, Card, CardActions, CardContent, TabPanels, TabPanel, TabsManager, Tabs } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

import BusinessGroups from './components/business-groups'
import Permissions from './components/permissions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [toggleReset, setToggleReset] = useState<boolean>(false)
  const [showBusinessGroups, setShowBusinessGroups] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Novo Perfil'
    form.register({ name: 'permissions' })
    form.register({ name: 'businessGroups' })

    return () => {}
  }, [form.register])

  useEffect(() => {
    if (toggleReset) setToggleReset(false)

    return () => {}
  }, [toggleReset])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Perfil' />
        <Card fullWidth>
          <CardContent>
            {!toggleReset && (
              <TabsManager
                tabs={showBusinessGroups ? ['Perfil', 'Permissões', 'Grupos Empresariais'] : ['Perfil', 'Permissões']}
                tabsId='profile-tabs'>
                <Tabs />
                <TabPanels persistent={true}>
                  <TabPanel>
                    <FormContext {...form}>
                      <Grid>
                        <Input name='name' label='Nome *' desktopSize={12} tabletSize={8} />
                      </Grid>
                    </FormContext>
                  </TabPanel>
                  <TabPanel>
                    <Permissions form={form} showBusinessGroups={(show: boolean) => setShowBusinessGroups(show)} />
                  </TabPanel>
                  {showBusinessGroups && (
                    <TabPanel>
                      <BusinessGroups form={form} />
                    </TabPanel>
                  )}
                </TabPanels>
              </TabsManager>
            )}
          </CardContent>
          <CardActions align='end'>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () => {
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave(form.getValues())) {
                    setToggleReset(true)
                    form.reset(defaultValues, { dirty: false })
                  }
                })
              }}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
