import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Table, TableHeader, TableRow, TableCell, TableBody, useToggle, TableContainer } from 'react-md'

import { Cell, PageTitle, Callout, ButtonLog } from 'components/core'
import { CalloutType } from 'components/core/callout'
import { formatCurrency } from 'types/core/currency'
import { EditButton } from 'components/data-table'

import List from './components/edit'

import { handleGet } from './actions'

const Component: React.FC = () => {
  const [visibleEditModal, enableEditModal, disableEditModal] = useToggle(false)
  const [icmses, setIcmses] = useState<Array<any> | null>(null)
  const [icms, setIcms] = useState<any | null>(null)

  useEffect(() => {
    document.title = 'Icms'

    load()

    return () => {}
  }, [])

  const load = async () => setIcmses(await handleGet())

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Icms' />
          {icmses?.length === 0 && <Callout text={'Nenhum icms localizado'} type={CalloutType.Info} />}
          {icmses != null && icmses?.length !== 0 && (
            <Card fullWidth>
              <CardContent>
                <TableContainer>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>AC</TableCell>
                        <TableCell>AL</TableCell>
                        <TableCell>AM</TableCell>
                        <TableCell>AP</TableCell>
                        <TableCell>BA</TableCell>
                        <TableCell>CE</TableCell>
                        <TableCell>DF</TableCell>
                        <TableCell>ES</TableCell>
                        <TableCell>GO</TableCell>
                        <TableCell>MA</TableCell>
                        <TableCell>MG</TableCell>
                        <TableCell>MS</TableCell>
                        <TableCell>MT</TableCell>
                        <TableCell>PA</TableCell>
                        <TableCell>PB</TableCell>
                        <TableCell>PE</TableCell>
                        <TableCell>PI</TableCell>
                        <TableCell>PR</TableCell>
                        <TableCell>RJ</TableCell>
                        <TableCell>RN</TableCell>
                        <TableCell>RO</TableCell>
                        <TableCell>RR</TableCell>
                        <TableCell>RS</TableCell>
                        <TableCell>SC</TableCell>
                        <TableCell>SE</TableCell>
                        <TableCell>SP</TableCell>
                        <TableCell>TO</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {icmses?.map((data: any, index) => (
                        <TableRow key={index}>
                          <TableCell>{data.state}</TableCell>
                          <TableCell>{formatCurrency(data.ac)}</TableCell>
                          <TableCell>{formatCurrency(data.al)}</TableCell>
                          <TableCell>{formatCurrency(data.am)}</TableCell>
                          <TableCell>{formatCurrency(data.ap)}</TableCell>
                          <TableCell>{formatCurrency(data.ba)}</TableCell>
                          <TableCell>{formatCurrency(data.ce)}</TableCell>
                          <TableCell>{formatCurrency(data.df)}</TableCell>
                          <TableCell>{formatCurrency(data.es)}</TableCell>
                          <TableCell>{formatCurrency(data.go)}</TableCell>
                          <TableCell>{formatCurrency(data.ma)}</TableCell>
                          <TableCell>{formatCurrency(data.mg)}</TableCell>
                          <TableCell>{formatCurrency(data.ms)}</TableCell>
                          <TableCell>{formatCurrency(data.mt)}</TableCell>
                          <TableCell>{formatCurrency(data.pa)}</TableCell>
                          <TableCell>{formatCurrency(data.pb)}</TableCell>
                          <TableCell>{formatCurrency(data.pe)}</TableCell>
                          <TableCell>{formatCurrency(data.pi)}</TableCell>
                          <TableCell>{formatCurrency(data.pr)}</TableCell>
                          <TableCell>{formatCurrency(data.rj)}</TableCell>
                          <TableCell>{formatCurrency(data.rn)}</TableCell>
                          <TableCell>{formatCurrency(data.ro)}</TableCell>
                          <TableCell>{formatCurrency(data.rr)}</TableCell>
                          <TableCell>{formatCurrency(data.rs)}</TableCell>
                          <TableCell>{formatCurrency(data.sc)}</TableCell>
                          <TableCell>{formatCurrency(data.se)}</TableCell>
                          <TableCell>{formatCurrency(data.sp)}</TableCell>
                          <TableCell>{formatCurrency(data.to)}</TableCell>
                          <TableCell align='right'>
                            <EditButton
                              id={`edit-${index}`}
                              action={() => {
                                setIcms(icmses.find(x => x.id === data.id))
                                enableEditModal()
                              }}
                            />
                            <ButtonLog entity='Icms' id={data.id} rounded />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
        </Cell>
      </Grid>

      <List icms={icms} visibleModal={visibleEditModal} closeModal={disableEditModal} onHide={async () => await load()} />
    </>
  )
}

export default Component
