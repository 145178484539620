import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableRow, TableCell } from 'react-md'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { PartnerAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'
import { handleSubmit, Input } from 'components/form'
import { DataTable } from 'components/data-table'

import { defaultValues, validationSchema } from './config'
import { handleSearch } from './actions'
import { formatCurrency } from 'types/core/currency'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Tarifa'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pesquisa de Tarifa' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <PartnerAutoComplete name='partner' label='Parceiro *' desktopSize={6} tabletSize={8} />
                <Input name='fare' label='Tarifa' maxLength={8} desktopSize={2} tabletSize={3} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => setFilter(form.getValues()))} />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <DataTable
          filter={filter}
          header={[
            { label: 'Tarifa' },
            { label: '0,300' },
            { label: '0,500' },
            { label: '0,750' },
            { label: '1,000' },
            { label: '1,250' },
            { label: '1,500' },
            { label: '2,000' },
            { label: '2,500' },
            { label: '3,000' },
            { label: '3,500' },
            { label: '4,000' },
            { label: '5,000' },
            { label: '6,000' },
            { label: '7,000' },
            { label: '8,000' },
            { label: '9,000' },
            { label: '10,000' },
            { label: '11,000' },
            { label: '12,000' },
            { label: '13,000' },
            { label: '14,000' },
            { label: '15,000' },
            { label: '16,000' },
            { label: '17,000' },
            { label: '18,000' },
            { label: '19,000' },
            { label: '20,000' },
            { label: '21,000' },
            { label: '22,000' },
            { label: '23,000' },
            { label: '24,000' },
            { label: '25,000' },
            { label: '26,000' },
            { label: '27,000' },
            { label: '28,000' },
            { label: '29,000' },
            { label: '30,000' },
            { label: '31,000' },
            { label: '32,000' },
            { label: '33,000' },
            { label: '34,000' },
            { label: '35,000' },
            { label: '36,000' },
            { label: '37,000' },
            { label: '38,000' },
            { label: '39,000' },
            { label: '40,000' },
            { label: '41,000' },
            { label: '42,000' },
            { label: '43,000' },
            { label: '44,000' },
            { label: '45,000' },
            { label: '46,000' },
            { label: '47,000' },
            { label: '48,000' },
            { label: '49,000' },
            { label: '50,000' },
            { label: '60,000' },
            { label: '70,000' },
            { label: '80,000' },
            { label: '90,000' },
            { label: '100,000' },
            { label: 'Adicional' },
          ]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell>{data.fareCode}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight300gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight750gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight1kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight1250gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight1500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight2kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight2500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight3kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight3500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight4kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight5kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight6kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight7kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight8kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight9kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight10kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight11kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight12kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight13kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight14kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight15kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight16kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight17kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight18kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight19kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight20kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight21kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight22kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight23kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight24kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight25kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight26kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight27kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight28kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight29kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight30kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight31kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight32kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight33kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight34kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight35kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight36kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight37kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight38kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight39kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight40kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight41kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight42kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight43kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight44kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight45kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight46kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight47kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight48kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight49kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight50kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight60kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight70kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight80kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight90kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight100kg)}</TableCell>
                <TableCell>{formatCurrency(data.additionalKg)}</TableCell>
              </TableRow>
            ))}
        </DataTable>
      </Cell>
    </Grid>
  )
}

export default Component
