import Yup from 'utils/yup'

export const pickupDefaultValues = {
  route: {
    id: null,
  },
  automaticPickupMethod: null,
  pickupStartTime: null,
  pickupFinalTime: null,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
}

export const pickupValidationSchema = Yup.object().shape({
  route: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  automaticPickupMethod: Yup.boolean().nullable().required(),
  pickupStartTime: Yup.string().nullable().required(),
  pickupFinalTime: Yup.string().nullable().required(),
  monday: Yup.bool().required(),
  tuesday: Yup.bool().required(),
  wednesday: Yup.bool().required(),
  thursday: Yup.bool().required(),
  friday: Yup.bool().required(),
  saturday: Yup.bool().required(),
  sunday: Yup.bool()
    .required()
    .test('frequency', 'Frequência não informada', function (value: boolean | undefined) {
      return (
        this.parent.monday ||
        this.parent.tuesday ||
        this.parent.wednesday ||
        this.parent.thursday ||
        this.parent.friday ||
        this.parent.saturday ||
        this.parent.sunday
      )
    }),
})
