export interface SearchLotFmFieldList {
  value: SearchLotFmFieldEnum | null
  label: string
}

export enum SearchLotFmFieldEnum {
  OrderNumber = 'OrderNumber',
  FiscalNoteNumber = 'FiscalNoteNumber',
  TrackingCode = 'TrackingCode',
  RecipientDocument = 'RecipientDocument',
  FiscalNoteAccessKey = 'FiscalNoteAccessKey',
}

export const SearchLotFmFieldOptions: Array<SearchLotFmFieldList> = [
  { value: null, label: '' },
  { value: SearchLotFmFieldEnum.OrderNumber, label: 'Núm. Pedido' },
  { value: SearchLotFmFieldEnum.FiscalNoteNumber, label: 'Núm. NF' },
  { value: SearchLotFmFieldEnum.FiscalNoteAccessKey, label: 'Chave NF' },
  { value: SearchLotFmFieldEnum.TrackingCode, label: 'Tracking' },
  { value: SearchLotFmFieldEnum.RecipientDocument, label: 'Cpf/Cnpj' },
]
