import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  field: null,
  values: '',
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  field: Yup.string().nullable().required(),
  values: Yup.string().nullable().required(),
})
