import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getOrderClientMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.order.client) {
    menu['/order/client'] = getMenu('/order/client', null, 'Pedidos', 'receipt', null)

    menu['/order/delete/lot/client'] = getMenu('/order/delete/lot/client', '/order/client', 'Exclusão', 'delete', '/order/delete/lot/client')

    menu['/order/import/client'] = getMenu('/order/import/client', '/order/client', 'Importação', 'upload', null)
    menu['/order/import/csv/client'] = getMenu('/order/import/csv/client', '/order/import/client', 'Csv', 'description', '/order/import/csv/client')
    menu['/order/import/manual/client'] = getMenu(
      '/order/import/manual/client',
      '/order/import/client',
      'Manual',
      'description',
      '/order/import/manual/client',
    )
    menu['/order/import/xml/client'] = getMenu('/order/import/xml/client', '/order/import/client', 'Xml', 'description', '/order/import/xml/client')

    menu['/order/import/fix/client'] = getMenu('/order/import/fix/client', '/order/client', 'Pacotes Inválidos', 'build', '/order/import/fix/client')

    menu['/order/search/client'] = getMenu('/order/search/client', '/order/client', 'Pesquisa', 'search', '/order/search/client')

    menu['/order/report/client'] = getMenu('/order/report/client', '/order/client', 'Relatórios', 'print', null)
    menu['/order/report/lot/client'] = getMenu(
      '/order/report/lot/client',
      '/order/report/client',
      'Busca Lote',
      'receipt_long',
      '/order/report/lot/client',
    )
  }
}
