import React from 'react'
import {
  Sheet,
  List,
  Divider,
  Text,
  SimpleListItem,
  ListSubheader,
  Button,
  FontIcon,
  AppBar,
  AppBarAction,
  CloseSVGIcon,
  AppBarTitle,
} from 'react-md'

import { useNotification } from 'contexts/notification/notification-context'

interface Props {
  visible: boolean
  disable: () => void
}

const Component: React.FC<Props> = ({ visible, disable }) => {
  const { notifications } = useNotification()

  return (
    <Sheet id='example-sheet-1' aria-label='Notificações' visible={visible} onRequestClose={disable} position={'right'} disableFocusOnMount={true}>
      <AppBar theme='primary'>
        <AppBarTitle>Notificações</AppBarTitle>
        <AppBarAction first onClick={disable}>
          <CloseSVGIcon />
        </AppBarAction>
      </AppBar>

      {(!notifications || notifications.length === 0) && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Button buttonType='icon' themeType='flat' theme='disabled'>
            <FontIcon style={{ fontSize: 100 }}>notifications_off</FontIcon>
          </Button>
          <Text id='empty-notifications' margin='none' type='body-2' align='center' style={{ margin: 5, marginTop: 40 }}>
            Nenhuma notificação recebida
          </Text>
        </div>
      )}

      {notifications?.map((notification: any, index: number) => {
        return (
          <List key={index}>
            <ListSubheader>{notification.date}</ListSubheader>
            <SimpleListItem>
              <Text id='dialog-title-2' margin='none' type='body-2'>
                {notification.message}
              </Text>
            </SimpleListItem>
            <Divider />
          </List>
        )
      })}
    </Sheet>
  )
}

export default Component
