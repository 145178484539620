import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'

import { FtpProtocolOptions } from 'types/integration/ftp-protocol'
import { Input, InputNumber, Select } from 'components/form'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Select name='ftpProtocol' label='Protocolo *' options={FtpProtocolOptions} desktopSize={3} tabletSize={2} />
      <Input name='url' label='Url *' desktopSize={6} tabletSize={4} />
      <InputNumber name='ftpPort' label='Porta *' desktopSize={3} tabletSize={2} />
      <Input name='user' label='Usuário *' desktopSize={6} tabletSize={4} />
      <Input name='password' label='Senha *' desktopSize={6} tabletSize={4} />
      <Input name='ftpNotfisFolder' label='Pasta Notfis *' desktopSize={6} tabletSize={4} />
      <Input name='ftpOcorenFolder' label='Pasta Ocoren *' desktopSize={6} tabletSize={4} />
    </FormContext>
  )
}

export default Component
