import api from 'services/system/block-taks'

export const handleList = async () => {
  let res = await api.list()

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.create(values)

  return res?.data?.success
}

export const handleDelete = async (task: string) => {
  let res = await api.delete(task)

  return res?.data?.success
}
