import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, Text } from 'react-md'
import { toast } from 'react-toastify'

import { handleSubmit, InputArea, Select } from 'components/form'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'

import { SearchLotFmFieldEnum, SearchLotFmFieldOptions } from './types'
import { defaultValues, validationSchema } from './config'
import { handleReport } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [fieldDescription, setFieldDescription] = useState<string | null>(null)
  const field = form.watch('field')

  useEffect(() => {
    document.title = 'Busca Lote'

    return () => {}
  }, [])

  useEffect(() => {
    form.clearError()
    if (field) {
      if (field == SearchLotFmFieldEnum.OrderNumber) setFieldDescription('Pedidos')
      else if (field == SearchLotFmFieldEnum.FiscalNoteNumber) setFieldDescription('Notas Fiscais')
      else if (field == SearchLotFmFieldEnum.TrackingCode) setFieldDescription('Trackings')
      else if (field == SearchLotFmFieldEnum.RecipientDocument) setFieldDescription('Documentos')
      else if (field == SearchLotFmFieldEnum.FiscalNoteAccessKey) setFieldDescription('Chaves de Nota Fiscal')
    } else setFieldDescription(null)

    return () => {}
  }, [field])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Busca Lote' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Cliente' desktopSize={3} tabletSize={4} />
                <Select name='field' label='Filtro' options={SearchLotFmFieldOptions} desktopSize={3} tabletSize={4} />
              </Grid>

              {fieldDescription && (
                <Grid>
                  <InputArea name='values' label={fieldDescription} rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                  <Cell>
                    <Text type='caption' component='h1'>
                      <i>Para realizar a pesquisa informe no máximo de 5000 {fieldDescription.toLocaleLowerCase()}</i>
                    </Text>
                  </Cell>
                </Grid>
              )}
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  let formValues = form.getValues()
                  let values: Array<string> = []

                  if (formValues.values) {
                    let itens = formValues.values as string
                    itens.split('\n').forEach((value: string) => {
                      if (value) values.push(value)
                    })

                    if (values.length > 5000) {
                      toast.warn(`Não é permitido pesquisar mais de 5000 ${fieldDescription?.toLocaleLowerCase()}`)
                      return
                    }

                    await handleReport({ field: formValues.field, values: values })

                    form.reset(defaultValues, { dirty: false })
                  }
                })
              }
            />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
