import React, { useEffect } from 'react'
import { AppBar, AppBarAction, AppBarTitle, CloseSVGIcon, List, Sheet, useToggle } from 'react-md'

interface Props {
  title?: string
  visible: boolean
  disable: any
}

const Action: React.FC<Props> = ({ title, visible, disable, children }) => {
  return (
    <Sheet id='actions' aria-label={title ?? 'Ações'} visible={visible} onRequestClose={disable} position={'right'} disableFocusOnMount={true}>
      <AppBar>
        <AppBarTitle>{title ?? 'Ações'}</AppBarTitle>
        <AppBarAction first onClick={disable}>
          <CloseSVGIcon />
        </AppBarAction>
      </AppBar>
      <List onClick={disable}>{children}</List>
    </Sheet>
  )
}

export default Action
