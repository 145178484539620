import React from 'react'
import { TableHeader, TableRow, TableCell, FontIcon } from 'react-md'

import { DataTableRequest, Header } from 'components/data-table/types'
import { getSorted, handleOrder } from 'components/data-table/actions'

interface Props {
  request: DataTableRequest | undefined
  setRequest: (value: React.SetStateAction<DataTableRequest | undefined>) => void
  header: Array<Header>
}

const DataTableHeader: React.FC<Props> = ({ request, setRequest, header }) => {
  return (
    <TableHeader>
      <TableRow>
        {header &&
          header.map((data, index) => {
            if (!data.label || !data.order) return <TableCell key={index}>{data.label}</TableCell>
            else {
              if (getSorted(request, data.order!) == 'other')
                return (
                  <TableCell
                    key={index}
                    sortIconAfter={true}
                    sortIconRotated={false}
                    aria-sort={'ascending'}
                    sortIcon={<FontIcon>unfold_more</FontIcon>}
                    onClick={() => (data.order ? handleOrder(request, data.order, setRequest) : () => {})}>
                    {data.label}
                  </TableCell>
                )
              else
                return (
                  <TableCell
                    key={index}
                    sortIconAfter={true}
                    sortIconRotated={getSorted(request, data.order!) == 'ascending' ? true : false}
                    aria-sort={getSorted(request, data.order!)}
                    onClick={() => (data.order ? handleOrder(request, data.order, setRequest) : () => {})}>
                    {data.label}
                  </TableCell>
                )
            }
          })}
      </TableRow>
    </TableHeader>
  )
}

export default DataTableHeader
