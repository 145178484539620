import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, FontIcon, useToggle, TabsManager, Tabs, TabPanels, TabPanel, ListItem } from 'react-md'

import { Cell, PageTitle, ButtonLog, ButtonSuccess, ButtonEdit, Actions } from 'components/core'
import { handleSubmit, Input, InputDecimal } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleDownloadCsv, handleGet, handleSave } from './actions'

import Migrate from './components/migrate'
import Import from './components/import'
import Client from './components/client'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [loadClients, setLoadClients] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleFareModal, enableFareModal, disableFareModal] = useToggle(false)
  const [visibleMigrateModal, enableMigrateModal, disableMigrateModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Tarifa'

    const load = async () => {
      const res = await handleGet(id)
      if (res) form.reset(res)
    }

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadClients(true)

    return () => {}
  }, [activeTab])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Tarifa' />
          <TabsManager onActiveIndexChange={val => setActiveTab(val)} tabs={['Tarifa', 'Clientes']} tabsId='coverage-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <Card fullWidth>
                  <CardContent>
                    <FormContext {...form}>
                      <Grid>
                        <Input name='name' label='Nome *' desktopSize={6} tabletSize={4} />
                        <InputDecimal name='adValorem' label='AdValorem *' desktopSize={3} tabletSize={4} />
                      </Grid>
                    </FormContext>
                  </CardContent>
                  <CardActions>
                    <ButtonSuccess
                      text='Salvar'
                      icon={<FontIcon>done</FontIcon>}
                      disabled={!form.formState.dirty}
                      onClick={async () =>
                        await handleSubmit(form, validationSchema, async () => {
                          if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                        })
                      }
                    />
                    <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                    <ButtonLog entity='ClientFare' id={id} />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel>{loadClients && <Client clientFareId={id} />}</TabPanel>
            </TabPanels>
          </TabsManager>
        </Cell>
      </Grid>

      <Import clientFareId={id} visibleModal={visibleFareModal} disableModal={disableFareModal} />

      <Migrate clientFareId={id} visibleModal={visibleMigrateModal} disableModal={disableMigrateModal} />

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>upload</FontIcon>} onClick={async () => enableFareModal()}>
          Importar
        </ListItem>
        <ListItem leftAddon={<FontIcon>sync</FontIcon>} onClick={async () => enableMigrateModal()}>
          Migrar Clientes
        </ListItem>
        <ListItem leftAddon={<FontIcon>download</FontIcon>} onClick={async () => handleDownloadCsv({ clientFareId: id }, 'Tarifa.csv')}>
          Download
        </ListItem>
      </Actions>
    </>
  )
}

export default Component
