import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { PartnerIntegrationOptions } from 'types/quote/partner-integration'
import { handleSubmit, Input, Select } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Novo Parceiro'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Parceiro' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Input name='name' label='Nome *' desktopSize={6} tabletSize={4} />
                <Select name='integration' label='Integração *' options={PartnerIntegrationOptions} desktopSize={6} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  let res = await handleSave(form.getValues())
                  if (res?.success) {
                    form.reset(defaultValues, { dirty: false })
                    window.open(`/quote/partner/${res.data}/edit`, '_self')
                  }
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
