import React from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { handleSubmit, Select } from 'components/form'
import { ServiceOptions } from 'types/quote/service'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  orderId: string
  disableModal: () => void
  loadOrder: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ orderId, disableModal, loadOrder }) => {
  const form = useForm({ defaultValues })

  return (
    <Modal
      title={'Alteração de Serviço'}
      visible={true}
      width={500}
      confirmTitle={'Salvar'}
      confirmAction={async () =>
        await handleSubmit(form, validationSchema, async () => {
          if (await handleSave({ orderId, ...form.getValues() })) {
            form.reset(defaultValues, { dirty: false })
            disableModal()
            loadOrder()
          }
        })
      }
      confirmDisabled={!form.formState.dirty}
      closeTitle={'Fechar'}
      closeAction={async () => disableModal()}>
      <FormContext {...form}>
        <Grid>
          <Select name='service' label='Serviço *' options={ServiceOptions} />
        </Grid>
      </FormContext>
    </Modal>
  )
}

export default Component
