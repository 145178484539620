import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Grid } from '@react-md/utils'
import { Card, CardActions, CardContent, FontIcon, TabPanel, TabPanels, TabsManager } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { addressDefaultValues, addressValidationSchema } from 'components/address/config'

import { issuerDefaultValues, issuerValidationSchema } from './components/general-information/issuer/config'
import { bankDataDefaultValues, bankDataValidationSchema } from './components/bank-data/config'
import { takerDefaultValues, takerValidationSchema } from './components/billing/taker/config'
import { otherRulesDefaultValues, otherRulesValidationSchema } from './components/other-rules/config'
import { handleSave, handleValidateForms } from './actions'
import ClientMenu from './components/client-menu'
import Issuer from './components/general-information/issuer'
import Address from './components/general-information/address'
import BankData from './components/bank-data'
import Taker from './components/billing/taker'
import TakerAddress from './components/billing/address'
import OtherRules from './components/other-rules'

const Component: React.FC = () => {
  const issuerForm = useForm({ defaultValues: issuerDefaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })
  const bankDataForm = useForm({ defaultValues: bankDataDefaultValues })
  const takerForm = useForm({ defaultValues: takerDefaultValues })
  const takerAddressForm = useForm({ defaultValues: addressDefaultValues })
  const otherRulesForm = useForm({ defaultValues: otherRulesDefaultValues })
  const [tabIndex, setTabIndex] = useState<number>(0)

  useEffect(() => {
    document.title = 'Novo Cliente'

    return () => {}
  }, [])

  const formIsDirty = (): boolean => {
    return (
      !issuerForm.formState.dirty &&
      !addressForm.formState.dirty &&
      !bankDataForm.formState.dirty &&
      !takerForm.formState.dirty &&
      !takerAddressForm.formState.dirty &&
      !otherRulesForm.formState.dirty
    )
  }

  const formsIsValid = async (): Promise<boolean> => {
    return await handleValidateForms(
      { form: issuerForm, validationSchema: issuerValidationSchema },
      { form: addressForm, validationSchema: addressValidationSchema },
      { form: bankDataForm, validationSchema: bankDataValidationSchema },
      { form: takerForm, validationSchema: takerValidationSchema },
      { form: takerAddressForm, validationSchema: addressValidationSchema },
      { form: otherRulesForm, validationSchema: otherRulesValidationSchema },
    )
  }

  const getClientObj = () => {
    return {
      ...issuerForm.getValues(),
      address: addressForm.getValues(),
      bankData: bankDataForm.getValues(),
      invoicing: {
        ...takerForm.getValues(),
        takerAddress: takerAddressForm.getValues(),
      },
      otherRules: otherRulesForm.getValues(),
    }
  }

  const resetForms = () => {
    issuerForm.reset(issuerDefaultValues, { dirty: false })
    addressForm.reset(addressDefaultValues, { dirty: false })
    bankDataForm.reset(bankDataDefaultValues, { dirty: false })
    takerForm.reset(takerDefaultValues, { dirty: false })
    takerAddressForm.reset(addressDefaultValues, { dirty: false })
    otherRulesForm.reset(otherRulesDefaultValues, { dirty: false })
  }

  const copyBusinessAddressToTakerAddress = () => {
    let address = addressForm.getValues()
    takerAddressForm.clearError()
    takerAddressForm.setValue('zipCode', address.zipCode)
    takerAddressForm.setValue('neighborhood', address.neighborhood)
    takerAddressForm.setValue('street', address.street)
    takerAddressForm.setValue('number', address.number)
    takerAddressForm.setValue('complement', address.complement)
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Cliente' />
      </Cell>
      <Cell>
        <div
          style={{
            display: 'flex',
            width: '100',
          }}>
          <Card style={{ marginRight: 10 }}>
            <CardContent>
              <ClientMenu setTabIndex={setTabIndex} />
            </CardContent>
          </Card>
          <Card fullWidth>
            <CardContent>
              <TabsManager activeIndex={tabIndex} tabs={[]} onActiveIndexChange={() => {}} tabsId={'tab'}>
                <TabPanels persistent={true}>
                  <TabPanel>
                    <Issuer form={issuerForm} />
                    <Address form={addressForm} />
                  </TabPanel>
                  <TabPanel>
                    <BankData form={bankDataForm} />
                  </TabPanel>
                  <TabPanel>
                    <Taker form={takerForm} />
                    <TakerAddress form={takerAddressForm} copyAddress={copyBusinessAddressToTakerAddress} />
                  </TabPanel>
                  <TabPanel>
                    <OtherRules form={otherRulesForm} />
                  </TabPanel>
                </TabPanels>
              </TabsManager>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Continuar Cadastro'
                icon={<FontIcon>done</FontIcon>}
                disabled={formIsDirty()}
                onClick={async () => {
                  if (await formsIsValid()) {
                    let res = await handleSave(getClientObj())
                    if (res?.success) {
                      resetForms()
                      window.open(`/sale/client/${res.data}/edit`, '_self')
                    }
                  }
                }}
              />
            </CardActions>
          </Card>
        </div>
      </Cell>
    </Grid>
  )
}

export default Component
