import Yup from 'utils/yup'

export const takerDefaultValues = {
  takerName: null,
  takerDocument: null,
  takerIe: null,
  issueCte: false,
  activity: null,
}

export const takerValidationSchema = Yup.object().shape({
  takerName: Yup.string().nullable().required().max(60, 'O nome deve ter no máximo 60 caracteres'),
  takerDocument: Yup.string().nullable().required().max(14, 'O cpf/cnpj deve ter no máximo 60 caracteres'),
  takerIe: Yup.string().nullable().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
  issueCte: Yup.bool().nullable().required(),
  activity: Yup.string().nullable().required(),
})
