import Yup from 'utils/yup'

export const defaultValues = {
  description: null,
  code: null,
  value: null,
}

export const validationSchema = Yup.object().shape({
  description: Yup.string().nullable().required().max(60, 'O nome deve ter no máximo 60 caracteres'),
  code: Yup.string().nullable().required().max(10, 'O código deve ter no máximo 10 caracteres'),
  value: Yup.number().nullable().required(),
})
