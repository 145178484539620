import Yup from 'utils/yup'

export const defaultValues = {
  carrier: null,
}

export const validationSchema = Yup.object().shape({
  carrier: Yup.object({
    id: Yup.string().nullable().required(),
  }),
})
