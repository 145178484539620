import React, { useEffect } from 'react'
import { format } from 'date-fns'

import { getFullAddressDescription } from 'components/address/types'

interface ComponentProps {
  packingList: any
  handlePrint: () => void
}

const Component = React.forwardRef<HTMLDivElement, ComponentProps>((props, ref) => {
  let { packingList, handlePrint } = props

  useEffect(() => {
    if (packingList) handlePrint()
  }, [packingList])

  return (
    <>
      {packingList && (
        <div ref={ref}>
          <style type='text/css' media='print'>
            {'@page { margin: 10px 10px 10px 10px !important; }'}
          </style>
          <div style={{ pageBreakInside: 'avoid' }}>
            <div style={{ textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
              Romaneio de Entrega Nº {packingList.number} - {packingList?.orders?.length} Pedido(s) - Data de Impressão{' '}
              {format(new Date(), 'dd-MM-yyyy HH:mm:ss')}
            </div>

            <div style={{ textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
              <h1>Código: {packingList.code}</h1>
            </div>

            {packingList.orders.map((order: any, index: number) => (
              <div key={index} style={{ padding: '10px', width: '100%', border: '1px solid', pageBreakInside: 'avoid' }}>
                <div>
                  <b>Destinatário:</b> {order.recipientName}
                </div>
                <div>
                  <b>Endereço:</b> {getFullAddressDescription(order.address)}
                </div>
                <div>
                  <b>Número da Nota Fiscal:</b> {order.fiscalNoteNumber}
                </div>
                <div>
                  <b>Número do Pedido:</b> {order.orderNumber}
                </div>
                <div>
                  <b>Volumes:</b> {order.volumes}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
})

export default Component
