import React, { useEffect, useState } from 'react'
import { FormContext, FormContextValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Card, CardContent } from 'react-md'

import { AutocompleteResult } from 'types/core/autocomplete'
import { CalloutType } from 'components/core/callout'
import { DeleteButton } from 'components/data-table'
import { Cell, Callout } from 'components/core'

import { defaultValuesAddBusinessGroup } from 'pages/system/profile/new/config'
import { BusinessGroupAutoComplete } from 'components/auto-complete'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  const formBusinessGroup = useForm({ defaultValues: defaultValuesAddBusinessGroup })
  const [businessGroups, setBusinessGroups] = useState<Array<AutocompleteResult>>([])

  useEffect(() => {
    form.clearError('businessGroups')
    form.setValue(
      'businessGroups',
      businessGroups.map(x => x.id),
    )

    return () => {}
  }, [businessGroups])

  return (
    <Card fullWidth>
      <CardContent>
        <Callout
          type={CalloutType.Info}
          text='Para incluir os grupos empresariais no perfil basta realizar a pesquisa e selecionar o grupo empresarial desejado'
        />
        <FormContext {...formBusinessGroup}>
          <Grid>
            <BusinessGroupAutoComplete
              name='businessGroup'
              label='Grupo Empresarial *'
              onSelect={(item: AutocompleteResult) => {
                if (businessGroups.some(x => x.id === item.id)) toast.warn('Grupo já adicionado')
                else setBusinessGroups([...businessGroups, item].sort())
                formBusinessGroup.reset()
              }}
              form={formBusinessGroup}
              desktopSize={10}
              tabletSize={7}
            />
            {businessGroups && (
              <Cell desktopSize={10} tabletSize={7}>
                <TableContainer>
                  <Table fullWidth>
                    <TableBody>
                      {businessGroups.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <DeleteButton
                              id='delete-business-group-button'
                              action={() => setBusinessGroups(businessGroups.filter(x => x.id !== item.id))}
                            />
                          </TableCell>
                          <TableCell grow>{item.label}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Cell>
            )}
          </Grid>
        </FormContext>
      </CardContent>
    </Card>
  )
}

export default Component
