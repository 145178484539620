import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/order'
const Api = {
  getByIdClient: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/client`)),
  getByIdFm: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/fm`)),
  searchClient: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/client`, request)),
  searchFm: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/fm`, request)),
  searchTrackingClient: (request: DataTableRequest): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/tracking/search/client`, request)),
  searchTrackingFm: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/tracking/search/fm`, request)),
  changeService: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/service`, request)),
  updateClient: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/client`, request)),
  updateFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/fm`, request)),
  updateDeliveryInformation: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/delivery-information`, request)),
}

export default Api
