import React from 'react'
import { Grid } from '@react-md/utils'
import { FontIcon, Text } from 'react-md'

import { ButtonSuccess, Cell } from 'components/core'
import { TextLabel } from 'components/label'

interface ComponentProps {
  order: any
}

const Component: React.FC<ComponentProps> = ({ order }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações de Entrega
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <TextLabel name='name' label='Nome do Recebedor' value={order.deliveryInformation?.name} desktopSize={6} tabletSize={8} />
          <TextLabel name='document' label='Documento do Recebedor' value={order.deliveryInformation?.document} desktopSize={3} tabletSize={4} />
          <Cell desktopSize={3} tabletSize={2}>
            <br />
            <ButtonSuccess
              text='Abrir Mapa'
              icon={<FontIcon>map</FontIcon>}
              disabled={!order.deliveryInformation?.latitude || !order.deliveryInformation?.longitude}
              onClick={async () => {
                window.open(`https://www.google.com.br/maps/place/${order.deliveryInformation.latitude},${order.deliveryInformation.longitude}`)
              }}
            />
          </Cell>
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
