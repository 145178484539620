import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, FontIcon, TabsManager, Tabs, TabPanels, TabPanel } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave, handleGet } from './actions'

import BusinessGroups from './components/business-groups'
import Permissions from './components/permissions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [permissions, setPermissions] = useState<Array<any>>([])
  const [businessGroups, setBusinessGroups] = useState<Array<any>>([])
  const [showBusinessGroups, setShowBusinessGroups] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Edição de  Perfil'
    form.register({ name: 'permissions' })
    form.register({ name: 'businessGroups' })

    return () => {}
  }, [form.register])

  useEffect(() => {
    loadProfile()

    return () => {}
  }, [id])

  const loadProfile = async () => {
    let data = await handleGet(id)
    if (data) {
      form.reset(data)
      setPermissions(data.permissions.sort((a: any, b: any) => a.permission > b.permission))
      setBusinessGroups(data.businessGroups.sort((a: any, b: any) => a.name > b.name))
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Perfil' />
          <TabsManager tabs={showBusinessGroups ? ['Perfil', 'Permissões', 'Grupos Empresariais'] : ['Perfil', 'Permissões']} tabsId='profile-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <Card fullWidth>
                  <CardContent>
                    <FormContext {...form}>
                      <Grid>
                        <Input name='name' label='Nome *' desktopSize={12} tabletSize={8} />
                      </Grid>
                    </FormContext>
                  </CardContent>
                  <CardActions align='end'>
                    <ButtonSuccess
                      text='Salvar'
                      icon={<FontIcon>done</FontIcon>}
                      disabled={!form.formState.dirty}
                      onClick={async () => {
                        await handleSubmit(form, validationSchema, async () => {
                          if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                        })
                      }}
                    />
                    <ButtonLog entity='Profile' id={id} />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel>
                <Permissions
                  id={id}
                  permissionList={permissions}
                  loadProfile={loadProfile}
                  showBusinessGroups={(show: boolean) => setShowBusinessGroups(show)}
                />
              </TabPanel>
              {showBusinessGroups && (
                <TabPanel>
                  <BusinessGroups id={id} businessGroupList={businessGroups} loadProfile={loadProfile} />
                </TabPanel>
              )}
            </TabPanels>
          </TabsManager>
        </Cell>
      </Grid>
    </>
  )
}

export default Component
