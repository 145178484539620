import React from 'react'
import { LinearProgress } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

const Loading: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker()
  return !promiseInProgress ? null : <LinearProgress id='linear-loading' />
}

export default Loading
