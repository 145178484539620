import React, { useEffect } from 'react'
import Bounce from 'react-reveal/Bounce'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardContent, FontIcon } from 'react-md'

import { Cell, ButtonCustom, Callout } from 'components/core'
import { handleSubmit, Input } from 'components/form'
import { CalloutType } from 'components/core/callout'
import { history } from 'utils/history'
import Logo from 'components/core/logo'

import { defaultValues, validationSchema } from './config'
import { handleSend } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Esqueci Minha Senha'

    return () => {}
  }, [])

  return (
    <Bounce bottom>
      <Grid>
        <Cell tabletSize={6} tabletStart={2} desktopSize={4} desktopStart={5}>
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Logo />
                <Callout
                  text='Para realizar a recuperação da senha informe os campos abaixo e clique em enviar código ou caso já possua um código de recuperação clique em já tenho um código'
                  type={CalloutType.Info}
                />
                <Input name='email' label='Email *' rightChildren={<FontIcon>person</FontIcon>} />
                <Input name='login' label='Usuário *' rightChildren={<FontIcon>account_box</FontIcon>} />
                <ButtonCustom
                  text='Enviar Código'
                  icon={<FontIcon>send</FontIcon>}
                  disabled={!form.formState.dirty}
                  whiteThemeBackgroundColor={'#e57373'}
                  darkThemeBackgroundColor={'#c62828'}
                  onClick={async () => await handleSubmit(form, validationSchema, async () => await handleSend(form.getValues()))}
                  style={{ width: '100%' }}
                />
                <ButtonCustom
                  text='Voltar'
                  icon={<FontIcon>arrow_back</FontIcon>}
                  whiteThemeBackgroundColor={'#e0e0e0'}
                  darkThemeBackgroundColor={'#424242'}
                  onClick={async () => history.push('/')}
                  style={{ width: '100%' }}
                />
                <ButtonCustom
                  text='Já Tenho um Código'
                  icon={<FontIcon>qr_code</FontIcon>}
                  whiteThemeBackgroundColor={'#e57373'}
                  darkThemeBackgroundColor={'#c62828'}
                  onClick={async () => history.push('/forgot-password/update-password')}
                  style={{ width: '100%' }}
                />
              </FormContext>
            </CardContent>
          </Card>
        </Cell>
      </Grid>
    </Bounce>
  )
}

export default Component
