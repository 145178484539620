import { handleValidate } from 'components/form/form-submit'
import { toast } from 'react-toastify'

import api from 'services/sale/client'

import { ValidationMessage } from 'types/core/api'
import { FormList } from 'types/core/form'

export const handleValidateForms = async (pickupForm: FormList, addressForm: FormList): Promise<boolean> => {
  let success = true

  if (!(await handleValidate(pickupForm.form, pickupForm.validationSchema))) success = false
  if (!(await handleValidate(addressForm.form, addressForm.validationSchema))) success = false

  if (!success) toast.warn(ValidationMessage)

  return success
}

export const handleAddAddress = async (values: any) => {
  let res = await api.addPickupAddress(values)

  return res?.data?.success
}
