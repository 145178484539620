import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getPickupFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions.pickup.pickupPackingList || permissions.pickup.fm.pickupRequest) {
    menu['/pickup'] = getMenu('/pickup', null, 'Coleta', 'forklift', null)

    if (permissions.pickup.pickupPackingList) {
      menu['/pickup/pickup-packing-list'] = getMenu('/pickup/pickup-packing-list', '/pickup', 'Romaneio', 'flag_circle', null)
      menu['/pickup/pickup-packing-list/new'] = getMenu(
        '/pickup/pickup-packing-list/new',
        '/pickup/pickup-packing-list',
        'Novo',
        'add',
        '/pickup/pickup-packing-list/new',
      )
      menu['/pickup/pickup-packing-list/search'] = getMenu(
        '/pickup/pickup-packing-list/search',
        '/pickup/pickup-packing-list',
        'Pesquisa',
        'search',
        '/pickup/pickup-packing-list/search',
      )
    }

    if (permissions.pickup.fm.pickupRequest) {
      menu['/pickup/pickup-request'] = getMenu('/pickup/pickup-request', '/pickup', 'Solicitação', 'front_hand', null)
      menu['/pickup/pickup-request/new'] = getMenu(
        '/pickup/pickup-request/new',
        '/pickup/pickup-request',
        'Nova',
        'add',
        '/pickup/pickup-request/new',
      )
      menu['/pickup/pickup-request/search'] = getMenu(
        '/pickup/pickup-request/search',
        '/pickup/pickup-request',
        'Pesquisa',
        'search',
        '/pickup/pickup-request/search',
      )
    }
  }
}
