import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  zipCodeDestination: null,
  totalValue: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  zipCodeDestination: Yup.number().nullable().required(),
  totalValue: Yup.number().nullable().max(10000, 'O valor total não deve ultrapassar R$10.000,00 reais'),
})
