import React, { useEffect, useState } from 'react'
import { FormContext, FormContextValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Card, CardContent, FormMessage } from 'react-md'

import { PermissionEnum, PermissionOptions } from 'types/system/permission'
import { AddButton, DeleteButton } from 'components/data-table'
import { handleSubmit, Select } from 'components/form'
import { CalloutType } from 'components/core/callout'
import { Cell, Callout } from 'components/core'

import { defaultValuesAddPermission, validationSchemaAddPermission } from 'pages/system/profile/new/config'
import { getPermissionDescription } from 'pages/system/profile/new/actions'

interface ComponentProps {
  form: FormContextValues<any>
  showBusinessGroups: (show: boolean) => void
}

const Component: React.FC<ComponentProps> = ({ form, showBusinessGroups }) => {
  const formPermission = useForm({ defaultValues: defaultValuesAddPermission })
  const [permissions, setPermissions] = useState<Array<string>>([])

  useEffect(() => {
    form.clearError('permissions')
    form.setValue('permissions', permissions)

    if (permissions.includes(PermissionEnum.Sales) || permissions.includes(PermissionEnum.Client)) showBusinessGroups(true)
    else showBusinessGroups(false)

    return () => {}
  }, [permissions])

  const AddPermission = async () => {
    await handleSubmit(formPermission, validationSchemaAddPermission, () => {
      let values = formPermission.getValues()
      if (values.permission !== '0') {
        if (permissions.includes(values.permission)) toast.warn('Permissão já adicionada')
        else if (IsMixedPermissions(values.permission)) toast.warn('Não é permitido combinar permissões cliente e fm')
        else setPermissions([...permissions, values.permission].sort())
        formPermission.reset()
      } else {
        formPermission.setError('permission', 'required', 'Campo obrigatório')
      }
    })
  }

  const IsMixedPermissions = (permission: string): boolean => {
    let userPermissions = [...permissions, permission]

    if (userPermissions.some(x => x == PermissionEnum.Client)) return userPermissions.some(x => x != PermissionEnum.Client)

    return false
  }

  const RemovePermission = (permission: string) => setPermissions(permissions.filter(x => x !== permission))

  return (
    <Card fullWidth>
      <CardContent>
        <Callout
          type={CalloutType.Info}
          text='Para incluir as permissões no perfil basta selecionar a permissão desejada abaixo e clique no botão adicionar permissão'
        />
        <FormContext {...formPermission}>
          <Grid>
            <Select name='permission' label='Permissão *' options={PermissionOptions} desktopSize={10} tabletSize={7} />
            <Cell desktopSize={2} tabletSize={1}>
              <br />
              <AddButton id='add-permission' tooltip='Adicionar Permissão' action={async () => await AddPermission()} />
            </Cell>
            {permissions && (
              <Cell desktopSize={10} tabletSize={7}>
                <TableContainer>
                  <Table fullWidth>
                    <TableBody>
                      {permissions.map((permission: string, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <DeleteButton id='delete-profile-button' action={() => RemovePermission(permission)} />
                          </TableCell>
                          <TableCell grow>{getPermissionDescription(PermissionOptions, permission)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Cell>
            )}
            {form.errors.permissions && (
              <Cell>
                <FormMessage id={'message-error-permissions'} error style={{ paddingLeft: 0 }}>
                  Nenhuma permissão informada
                </FormMessage>
              </Cell>
            )}
          </Grid>
        </FormContext>
      </CardContent>
    </Card>
  )
}

export default Component
