import React, { useContext, useEffect, useState } from 'react'
import { Card, CardActions, CardContent, Chip, FontIcon, FormMessage } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'
import { usePromiseTracker } from 'react-promise-tracker'

import { DepartmentEnum, DepartmentOption, DepartmentOptions } from 'types/sale/department'
import { ContactContext } from 'contexts/client/contact/contact-context'
import { handleSubmit, Input, InputPhone } from 'components/form'
import ButtonError from 'components/core/button-error'
import { ButtonSuccess, Cell } from 'components/core'

import { handleAddDepartment, handleRemoveDepartment, handleUpdateDetail } from './actions'
import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  clientId: string
  contactId: string | undefined
  contactData: any
  onClose: () => void
}

const Component: React.FC<ComponentProps> = ({ clientId, contactId, contactData, onClose }) => {
  const { promiseInProgress } = usePromiseTracker()
  const context = useContext(ContactContext)
  const form = useForm({ defaultValues })
  const email = form.watch('email')
  const [departments, setDepartments] = useState<Array<any>>([])

  useEffect(() => {
    if (contactData) {
      form.reset(
        {
          phone1: contactData.phone1,
          phone2: contactData.phone2,
          email: contactData.email,
        },
        { dirty: false },
      )
      setDepartments(contactData.departments)
    }

    return () => {}
  }, [contactData])

  const addOrRemoveDepartment = async (department: DepartmentEnum | null) => {
    if (contactId) {
      let departmentData = departments.filter(x => x.department === department)

      if (departmentData.length > 0) {
        if (await handleRemoveDepartment(clientId, contactId, contactData.id, departmentData[0].id)) {
          setDepartments(departments.filter(x => x.id != departmentData[0].id))
        }
      } else {
        if (await handleAddDepartment({ clientId, contactId, detailId: contactData.id, department })) {
          setDepartments([...departments, department])
        }
      }
      await context.loadContacts()
    }
  }

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    setDepartments([])
    onClose()
  }

  return (
    <Card fullWidth style={{ marginBottom: 5 }}>
      <CardContent>
        <FormContext {...form}>
          <InputPhone name='phone1' label='Telefone 1 *' desktopSize={6} tabletSize={4} />
          <InputPhone name='phone2' label='Telefone 2' desktopSize={6} tabletSize={4} />
          <Input name='email' label='E-mail' maxLength={60} desktopSize={12} tabletSize={8} />
          <Cell>
            {DepartmentOptions.map((optionData: DepartmentOption, index) => (
              <Chip
                key={index}
                disabled={promiseInProgress}
                selected={departments?.some((x: any) => x.department === optionData.value)}
                selectedThemed
                onClick={async () => await addOrRemoveDepartment(optionData.value)}
                style={{ marginRight: 3 }}>
                {optionData.label}
              </Chip>
            ))}
            {departments.some((x: any) => x.department === DepartmentEnum.LogisticsAndShipping) && email && (
              <FormMessage style={{ marginTop: 15 }} messageStyle={{ fontStyle: 'italic' }} id={'add-detail-logistics-and-shipping'}>
                * Logística e Expedição: O e-mail informado receberá as notificações em caso de pedidos inválidos cadastrados na plataforma e
                proximidade de coleta
              </FormMessage>
            )}
          </Cell>
        </FormContext>
      </CardContent>
      <CardActions>
        <ButtonSuccess
          text='Salvar'
          icon={<FontIcon>edit</FontIcon>}
          disabled={!form.formState.dirty}
          onClick={async () => {
            await handleSubmit(form, validationSchema, async () => {
              if (await handleUpdateDetail({ clientId, contactId, id: contactData.id, ...form.getValues(), departments })) {
                await context.loadContacts()
                clear()
                onClose()
              }
            })
          }}
        />
        <ButtonError text='Fechar' icon={<FontIcon>close</FontIcon>} onClick={async () => clear()} />
      </CardActions>
    </Card>
  )
}

export default Component
