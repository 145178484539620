import React, { useEffect, useState } from 'react'
import { Button, FontIcon, Tooltipped } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import { DataTableRequest } from 'components/data-table/types'
import { useAuth } from 'contexts/auth/auth-context'
import { Theme } from 'contexts/auth/types'

interface Props {
  request: DataTableRequest | undefined
  setRequest: (value: React.SetStateAction<DataTableRequest | undefined>) => void
  rowsPerPage: number
}

const NavigateBefore = ({ request, setRequest, rowsPerPage }: Props) => {
  const { promiseInProgress } = usePromiseTracker()
  const [disabled, setDisabled] = useState<boolean>(false)
  const { theme } = useAuth()

  useEffect(() => {
    setDisabled((request?.pagination.page ?? 0) === 0)

    return () => {}
  }, [request?.pagination.page])

  return (
    <Tooltipped id='navigate_before' tooltip='Anterior' position='above'>
      <Button
        themeType='contained'
        theme={'clear'}
        buttonType='icon'
        disabled={disabled}
        style={
          disabled || promiseInProgress
            ? {}
            : theme === Theme.Dark
            ? { margin: 2, backgroundColor: '#424242', color: '#FFFFFF' }
            : { margin: 2, backgroundColor: '#E0E0E0', color: '#212121' }
        }
        onClick={() => {
          if (request)
            setRequest({
              ...request,
              pagination: {
                page: (request?.pagination.page ?? 1) - 1,
                length: rowsPerPage,
              },
            })
        }}>
        <FontIcon>navigate_before</FontIcon>
      </Button>
    </Tooltipped>
  )
}

export default NavigateBefore
