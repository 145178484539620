import api from 'services/quote/client-fare'

export const handleSearch = async (request: any) => {
  let res = await api.searchClient(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/sale/client/${id}/edit`)
}
