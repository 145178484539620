export interface PermissionOption {
  value: PermissionEnum
  label: string
}

export enum PermissionEnum {
  None = '0',
  Administrator = '2',
  Expedition = '3',
  Devolution = '4',
  Sales = '5',
  Sac = '6',
  ExternalExpedition = '7',
  Pickup = '8',
  Client = '50',
}

export enum PermissionDescriptionEnum {
  System = 'System',
  Administrator = 'Administrator',
  Expedition = 'Expedition',
  Devolution = 'Devolution',
  Sales = 'Sales',
  Sac = 'Sac',
  ExternalExpedition = 'ExternalExpedition',
  Pickup = 'Pickup',
  Client = 'Client',
}

export const PermissionOptions: Array<PermissionOption> = [
  { value: PermissionEnum.None, label: 'Selecione a permissão' },
  { value: PermissionEnum.Administrator, label: 'Administrador' },
  { value: PermissionEnum.Pickup, label: 'Coleta' },
  { value: PermissionEnum.Devolution, label: 'Devolução' },
  { value: PermissionEnum.Expedition, label: 'Expedição' },
  { value: PermissionEnum.ExternalExpedition, label: 'Expedição Externa' },
  { value: PermissionEnum.Sac, label: 'Sac' },
  { value: PermissionEnum.Sales, label: 'Vendas' },
  { value: PermissionEnum.Client, label: 'Cliente' },
]
