import pickupAddressApi from 'services/pickup/pickup-address'
import clienteApi from 'services/sale/client'

export const handleListAddresses = async (clientId: string) => {
  let res = await pickupAddressApi.listFm(clientId)

  return res?.data?.data ?? []
}

export const handleRemoveAddress = async (clientId: string, id: string) => {
  let res = await clienteApi.removePickupAddress(clientId, id)

  return res?.data?.success
}
