import api from 'services/system/profile'

export const handleSave = async (values: any) => {
  let res = await api.create(values)

  return res?.data?.success
}

export const getPermissionDescription = (permissionsArray: Array<any>, permission: string): string => {
  return permissionsArray.filter(x => x.value === permission.toString())[0]?.label
}
