import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, useToggle } from '@react-md/utils'
import { Card, CardActions, CardContent, FontIcon, ListItem, TabPanel, TabPanels, Tabs, TabsManager } from 'react-md'
import { toast } from 'react-toastify'

import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import { Actions, ButtonEdit, ButtonSuccess, Cell, PageTitle } from 'components/core'
import { handlePrintProtocol } from 'pages/order/protocol/print/actions'
import PrintProtocol from 'pages/order/protocol/print'
import { FiscalDocumentEnum } from 'types/order/fiscal-document'
import { ValidationMessage } from 'types/core/api'

import { taxInformationDefaultValues, taxInformationValidationSchema } from './components/tax-information/config'
import { recipientDefaultValues, recipientValidationSchema } from './components/recipient/config'
import { handleGet, handleSave, handleValidateForms } from './actions'

import OperationalInformation from './components/operational-information'
import Recipient from './components/recipient'
import RecipientAddress from './components/recipient-address'
import TaxInformation from './components/tax-information'
import FinancialInformation from './components/financial-information'
import DeliveryInformation from './components/delivery-information'
import Tracking from './components/tracking'
import ChangeService from './components/change-service'
import { useReactToPrint } from 'react-to-print'
import { StatusEnum } from 'types/order/status'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [visibleChangeServiceModal, enableChangeServiceModal, disableChangeServiceModal] = useToggle(false)
  const recipientForm = useForm({ defaultValues: recipientDefaultValues })
  const recipientAddressForm = useForm({ defaultValues: addressDefaultValues })
  const taxInformationForm = useForm({ defaultValues: taxInformationDefaultValues })
  const [order, setOrder] = useState<any>()
  const [loadTrackings, setLoadTrackings] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [printProtocol, setPrintProtocol] = useState<any>()
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const componentRef = useRef(null)

  useEffect(() => {
    document.title = 'Edição de Pedido'

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadTrackings(true)

    return () => {}
  }, [activeTab])

  const load = async () => {
    const res = await handleGet(id)
    if (res) {
      setOrder(res)
      recipientForm.reset({
        name: res.recipient.name ? res.recipient.name : null,
        document: res.recipient.document ? res.recipient.document : null,
        ie: res.recipient.ie ? res.recipient.ie : null,
        email: res.recipient.email ? res.recipient.email : null,
        phone: res.recipient.phone ? res.recipient.phone : null,
      })
      recipientAddressForm.reset(res.recipient.address)
      taxInformationForm.reset({
        orderNumber: res.orderNumber ? res.orderNumber : null,
        fiscalNoteNumber: res.fiscalNoteNumber ? res.fiscalNoteNumber : null,
        totalValue: res.totalValue ? res.totalValue : null,
      })
    }
  }

  const print = async () => {
    const res = await handlePrintProtocol(id)
    if (res) setPrintProtocol(res)
  }

  const handlePrintHTML = useReactToPrint({ content: () => componentRef.current, documentTitle: 'Protocolo' })

  const formIsDirty = (): boolean => {
    return !recipientForm.formState.dirty && !recipientAddressForm.formState.dirty && !taxInformationForm.formState.dirty
  }

  const formsIsValid = async (): Promise<boolean> => {
    if (order.fiscalDocument === FiscalDocumentEnum.FiscalNote && !formFiscalNoteIsValid()) return false
    if (order.fiscalDocument === FiscalDocumentEnum.Declaration && !formDeclarationIsValid()) return false

    return await handleValidateForms(
      { form: recipientForm, validationSchema: recipientValidationSchema },
      { form: recipientAddressForm, validationSchema: addressValidationSchema },
      { form: taxInformationForm, validationSchema: taxInformationValidationSchema },
    )
  }

  const formFiscalNoteIsValid = (): boolean => {
    let taxValues = taxInformationForm.getValues()

    taxInformationForm.clearError('fiscalNoteNumber')
    taxInformationForm.clearError('totalValue')

    if (!taxValues.fiscalNoteNumber) taxInformationForm.setError('fiscalNoteNumber', 'required', 'Campo obrigatório')
    if (!taxValues.totalValue || taxValues.totalValue == 0) taxInformationForm.setError('totalValue', 'required', 'Campo obrigatório')

    if (Object.keys(taxInformationForm.errors).length) {
      toast.warn(ValidationMessage)
      return false
    }

    return true
  }

  const formDeclarationIsValid = (): boolean => {
    let taxValues = taxInformationForm.getValues()

    taxInformationForm.clearError('orderNumber')
    taxInformationForm.clearError('totalValue')

    if (!taxValues.orderNumber) taxInformationForm.setError('orderNumber', 'required', 'Campo obrigatório')
    if (!taxValues.totalValue || taxValues.totalValue == 0) taxInformationForm.setError('totalValue', 'required', 'Campo obrigatório')

    if (Object.keys(taxInformationForm.errors).length) {
      toast.warn(ValidationMessage)
      return false
    }

    return true
  }

  const getOrderObj = () => {
    return {
      volumeId: id,
      ...taxInformationForm.getValues(),
      recipient: {
        ...recipientForm.getValues(),
        address: recipientAddressForm.getValues(),
      },
    }
  }

  const resetForms = () => {
    recipientForm.reset(recipientForm.getValues(), { dirty: false })
    recipientAddressForm.reset(recipientAddressForm.getValues(), { dirty: false })
    taxInformationForm.reset(taxInformationForm.getValues(), { dirty: false })
  }

  return (
    <>
      <Grid>
        <Cell>
          {order && (
            <>
              <PageTitle title='Edição de Pedido' />
              <TabsManager
                tabs={['Pedido', 'Trackings']}
                activeIndex={activeTab}
                onActiveIndexChange={activeIndexNumber => setActiveTab(activeIndexNumber)}
                tabsId={'tab-order'}>
                <Tabs />
                <TabPanels persistent={true}>
                  <TabPanel>
                    <Card fullWidth>
                      <CardContent>
                        <OperationalInformation order={order} />
                        <Recipient form={recipientForm} />
                        <RecipientAddress form={recipientAddressForm} />
                        <TaxInformation order={order} form={taxInformationForm} />
                        <FinancialInformation order={order} />
                        <DeliveryInformation order={order} />
                      </CardContent>
                      <CardActions align='end'>
                        <ButtonSuccess
                          text='Salvar'
                          icon={<FontIcon>done</FontIcon>}
                          disabled={formIsDirty()}
                          onClick={async () => {
                            if (await formsIsValid()) if (await handleSave(getOrderObj())) resetForms()
                          }}
                        />
                        <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                      </CardActions>
                    </Card>
                  </TabPanel>
                  <TabPanel>{loadTrackings && <Tracking volumeId={id} />}</TabPanel>
                </TabPanels>
              </TabsManager>
            </>
          )}
        </Cell>
      </Grid>

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>published_with_changes</FontIcon>} onClick={() => enableChangeServiceModal()}>
          Alterar Serviço
        </ListItem>
        <ListItem leftAddon={<FontIcon>print</FontIcon>} disabled={order?.status !== StatusEnum.Delivered} onClick={() => print()}>
          Imprimir Protocolo
        </ListItem>
      </Actions>

      {visibleChangeServiceModal && <ChangeService orderId={order.orderId} disableModal={disableChangeServiceModal} loadOrder={load} />}

      {printProtocol && (
        <div style={{ display: 'none' }}>
          <PrintProtocol protocol={printProtocol} handlePrint={handlePrintHTML} ref={componentRef} />
        </div>
      )}
    </>
  )
}

export default Component
