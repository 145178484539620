export const getServiceDescription = (status: string): string => {
  return ServiceOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface ServiceList {
  value: ServiceEnum | null
  label: string
}

export enum ServiceEnum {
  Standard = 'Standard',
  Express = 'Express',
  MedioRodo = 'MedioRodo',
}

export const ServiceOptions: Array<ServiceList> = [
  { value: null, label: '' },
  { value: ServiceEnum.Express, label: 'Expresso' },
  { value: ServiceEnum.Standard, label: 'Standard' },
  { value: ServiceEnum.MedioRodo, label: 'Medio Rodo' },
]
