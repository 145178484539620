import React, { useState } from 'react'
import { Card, CardActions, CardContent, Chip, FileInput, FontIcon, Grid, Text } from 'react-md'

import { ButtonEdit, ButtonSuccess, Cell, Modal } from 'components/core'
import ButtonError from 'components/core/button-error'

import { handleGetFareCsv, handleImport } from './actions'

interface ComponentProps {
  clientFareId: string
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ clientFareId, visibleModal, disableModal }) => {
  const [fileName, setFileName] = useState<string | null>()
  const [file, setFile] = useState<string | ArrayBuffer | null>()

  const getBase64 = (e: any) => {
    var file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      if (reader && reader.result)
        setFile(btoa(encodeURIComponent(reader.result.toString()).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)))))
    }
  }

  const clear = () => {
    setFileName(null)
    setFile(null)
  }

  return (
    <Modal title={'Importação'} visible={visibleModal} closeTitle={'Fechar'} closeAction={async () => disableModal()}>
      <Grid>
        <Cell>
          <Card fullWidth>
            <CardContent>
              <Grid>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Tabela de Tarifa
                  </Text>
                </Cell>
                <Cell>
                  <Chip style={{ width: '100%' }}>{fileName ? `Arquivo de Tarifa: ${fileName}` : 'Nenhum arquivo de tarifa selecionado'}</Chip>
                </Cell>
              </Grid>
            </CardContent>
            <CardActions>
              {!fileName && (
                <FileInput
                  id='import-input-fare'
                  theme={'secondary'}
                  themeType={'contained'}
                  buttonType={'text'}
                  accept={'.csv'}
                  icon={<FontIcon>description</FontIcon>}
                  disabled={fileName ? true : false}
                  onChange={event => {
                    if (event?.currentTarget?.files?.[0]) {
                      setFileName(event.currentTarget.files[0].name)
                      getBase64(event)
                    }
                  }}>
                  {'Selecionar Arquivo'}
                </FileInput>
              )}

              {fileName && (
                <>
                  <ButtonSuccess
                    text='Importar'
                    icon={<FontIcon>check</FontIcon>}
                    onClick={async () => {
                      await handleImport(clientFareId, fileName ?? '', file)
                      clear()
                      disableModal()
                    }}
                  />
                  <ButtonError text='Apagar' icon={<FontIcon>cancel</FontIcon>} onClick={async () => clear()} />
                </>
              )}

              <ButtonEdit text='Download do Modelo' icon={<FontIcon>download</FontIcon>} onClick={async () => await handleGetFareCsv('Tarifa.csv')} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>
    </Modal>
  )
}

export default Component
